<template>
  <div v-if="fcsAccount" class="fcs-account-linker">
    <div v-if="!tournamentId" class="linker-title">
      {{ $t('profile.fcs_title') }}
    </div>
    <template v-if="fcsAccount.isAccountLinked">
      <div class="linker-status">
        <div class="status-icon">
          <icon name="check-circle" />
        </div>
        <i18n tag="div" path="account.binded" class="status-text">
          <b slot="name">{{ fcsAccount.nick }}</b>
        </i18n>
      </div>
    </template>
    <div v-if="!fcsAccount.isAccountLinked" class="fcs-terms">
      <BaseCheckbox
        :value="agreementApproved"
        :disabled="agreementApproved"
        @input="onCheckboxClick"
      >
        <i18n path="terms.accept_fcs_text">
          <TermLink slot="agreement" code="resf_agreement">
            {{ $t('terms.accept_fcs_agreement') }}
          </TermLink>
        </i18n>
      </BaseCheckbox>
    </div>
    <InfoBox
      v-if="!canSyncFksAccount && !forceAllowEdit"
      variant="warning"
    >
      <i18n path="profile.edit_warnings_tournament_text">
        <BaseLink slot="link" :to="{ name: 'my-profile' }">
          {{ $t('profile.edit_warnings_tournament_link') }}
        </BaseLink>
      </i18n>
    </InfoBox>
    <div
      v-if="tournamentSettings.needFksAccount"
      class="linker-controls"
    >
      <pvp-btn
        v-if="!fcsAccount.isAccountLinked"
        :block="appIsMobile"
        :is-loading="loadingState.link"
        :disabled="linkButtonDisabled"
        variant="primary"
        @click="link"
      >
        {{ $t('account.bind') }}
      </pvp-btn>
      <pvp-btn
        v-else
        :block="appIsMobile"
        :is-loading="loadingState.link"
        :disabled="unlinkButtonDisabled"
        variant="secondary"
        @click="unlink"
      >
        {{ $t('account.unbind') }}
      </pvp-btn>

      <div class="linker-title indent">
        {{ $t('profile.fcs_pd_title') }}
      </div>

      <pvp-btn
        class="indent"
        :block="appIsMobile"
        :is-loading="loadingState.pd"
        variant="primary"
        @click="openPdPopup"
      >
        {{ fcsButtonText }}
      </pvp-btn>
    </div>

    <InfoBox v-if="error" variant="error">{{ error }}</InfoBox>
    <pvp-modal v-model="isModalOpen" :width="580">
      <template slot="modal-title">{{
        $t('terms.agreements_user_single')
      }}</template>
      <loader v-if="loadingState.loader" />
      <InfoBox v-if="agreementApproved" variant="success">
        {{ $t('terms.success_single') }}
      </InfoBox>
      <pvp-btn slot="modal-footer" @click="isModalOpen = false">
        {{ $t('global.close') }}
      </pvp-btn>
    </pvp-modal>
  </div>
</template>

<script>
import storage from '@utils/storage';
import Icon from '@components/v2/utils/Icon.vue';
import BaseCheckbox from '@components/BaseComponents/Form/BaseCheckbox.vue';
import TermLink from '@components/BaseComponents/TermLink.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'FcsAccountLinker',
  components: {
    Icon,
    BaseCheckbox,
    TermLink,
    BaseLink,
    InfoBox,
  },
  props: {
    forceAllowEdit: {
      type: Boolean,
      default: false,
    },
    tournamentId: {
      type: Number,
      default: null,
    },
    isFksPdCompleted: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isModalOpen: false,
    loadingState: {
      link: false,
      terms: true,
      pd: false,
    },

    // State
    error: null,
    agreementApproved: false,

    // Data
    fcsAccountName: null,
  }),
  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('application', ['appIsMobile']),
    ...mapGetters('profile', [
      'getCurrentProfileDetails',
      'getCurrentProfileSettings',
    ]),

    tournamentSettings() {
      const { needFksAccount = true, needFksTerms = true } =
        this.getTournament(this.tournamentId) || {};

      return {
        needFksAccount,
        needFksTerms,
        needTermsOnly: needFksTerms && !needFksAccount,
      };
    },

    fcsAccount() {
      return this.getCurrentProfileDetails.fcs;
    },

    canSyncFksAccount() {
      return this.getCurrentProfileSettings.canSyncFksAccount;
    },

    linkButtonDisabled() {
      return (
        !this.agreementApproved ||
        this.loadingState.link ||
        !this.canSyncFksAccount
      );
    },

    unlinkButtonDisabled() {
      return this.loadingState.link || !this.canSyncFksAccount;
    },

    fcsButtonText() {
      return this.isFksPdCompleted
        ? this.$t('profile.fcs_edit_pd')
        : this.$t('profile.fcs_fill_pd');
    },
  },
  watch: {
    fcsAccount({ isAccountLinked } = {}) {
      if (isAccountLinked) {
        this.$emit('done');
      }
    },
  },

  mounted() {
    this.checkFckAgreement();

    const error = storage.getItem('fcs.link.error');

    if (error) {
      this.setError(error);
    }

    storage.remove('fcs.link.error');
  },
  methods: {
    ...mapActions('profile', ['fetchCurrentProfile']),
    setError(error = null) {
      this.error = error;
    },

    checkFckAgreement() {
      this.agreementApproved =
        this.fcsAccount?.isTermsAccepted || false;
    },

    unlink() {
      this.loadingState.link = true;

      api
        .post('/profile/resf/unlink')
        .catch(({ error }) => {
          this.setError(error);
        })
        .finally(() => {
          this.fetchCurrentProfile().finally(() => {
            this.loadingState.link = false;
            this.checkFckAgreement();
          });
        });
    },

    link() {
      this.loadingState.link = true;
      const { path } = this.$router.currentRoute;
      api
        .get('/profile/resf/link', {
          params: {
            extra: path,
          },
        })
        .then(({ url }) => {
          window.location = `${url}?section=fcs`;
        })
        .catch(({ error }) => {
          this.setError(error);
        });
    },

    async openPdPopup() {
      // TODO вынести в экшн стора
      this.loadingState.pd = true;
      try {
        const newWindow = window.open();
        const { url } = await api.get('/profile/resf/pd-link');
        newWindow.location = url;
      } catch (err) {
        this.setError(err);
      } finally {
        this.loadingState.pd = false;
      }
    },

    acceptTerms() {
      this.loadingState.terms = true;
      api
        .post('/profile/term/accept', { code: 'resf_agreement' })
        .then(({ success }) => {
          if (success) {
            this.agreementApproved = true;
            if (this.tournamentSettings?.needTermsOnly) {
              this.$emit('done');
            }
          }
        })
        .finally(() => {
          this.loadingState.terms = true;
        });
    },

    onCheckboxClick(isChecked) {
      if (isChecked) {
        this.isModalOpen = true;
        this.acceptTerms();
      } else {
        this.agreementApproved = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fcs-account-linker {
  &.centered {
    text-align: center;
  }

  .linker-title {
    font-size: 19px;
    font-weight: bold;
  }

  .linker-status {
    margin-top: 10px;
    display: flex;

    .status-icon {
      color: $dark-pastel-green;
    }

    .status-text {
      margin-left: 8px;
      color: rgba(white, 0.7);
    }
  }

  .linker-controls {
    margin-top: 20px;
  }

  * + .pvp-info-box {
    margin-top: 20px;
  }

  .fcs-terms {
    margin-top: 14px;
  }

  .indent {
    margin-top: 20px;
  }
}
</style>
