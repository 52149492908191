export const state = {
  dictionary: {},
  jobFairList: [],
};

export const getters = {
  getJobfairProfile: (state) => (id) =>
    state.jobFairList?.find((profile) => profile.id === id),
};

export const mutations = {
  SET_DICTIONARY(state, dictionary) {
    state.dictionary = dictionary;
  },
  SET_JOBFAIR_LIST(state, data) {
    state.jobFairList = data;
  },
};

export const actions = {
  fetchJobFairDictionary({ commit }) {
    return api
      .get('/declaration/dictionary')
      .then(({ dictionary }) => {
        commit('SET_DICTIONARY', dictionary);
      });
  },
  fetchJobfairList({ commit }, params) {
    return api
      .get('/declaration/list', { params })
      .then(({ list, pagen }) => {
        commit('SET_JOBFAIR_LIST', list);
        return pagen;
      });
  },
};
