import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { fetchTerm, fetchAllTerms } from '../api/term';

export const useTermStore = defineStore('terms', () => {
  const fetchedTerms = ref([]);
  const currentTerm = ref(null);
  const termModalOpened = ref(false);

  const terms = computed(() => fetchedTerms.value.reduce((acc, term) => ({ ...acc, [term.id]: term }), {}));
  const termCodes = computed(() => fetchedTerms.value.reduce((acc, term) => ({ ...acc, [term.code]: term }), {}));

  const getTerm = computed(() => (id) => terms?.[id] || termCodes?.[id]);

  const fetchTerms = async ({ code, version, params }) => {
    const res = await fetchTerm({ code, version, params });
    if (Array.isArray(res)) {
      fetchedTerms.value = res;
      return res.map((term) => term.id);
    }
    currentTerm.value = res;
    return res.id;
  };

  const fetchAllTermsData = async () => {
    const res = await fetchAllTerms();
    fetchedTerms.value = res;
  }

  const openTermModal = async ({ code, version }) => {
    currentTerm.value = null;
    termModalOpened.value = true;
    const termId = await fetchTerms({ code, version });
    currentTerm.value = getTerm(termId);
  };

  const closeTermModal = () => {
    termModalOpened.value = false;
  };

  return {
    terms,
    termCodes,
    currentTerm,
    termModalOpened,
    getTerm,
    fetchTerms,
    openTermModal,
    closeTermModal,
    fetchAllTermsData,
  };
});
