<template>
  <div v-if="counts" class="steps">
    <ul class="steps-list">
      <li
        v-for="index in stepsCount"
        :key="index"
        :data-count="index"
        :class="{ active: step >= index }"
        class="step"
      />
    </ul>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Steps',
  props: {
    step: {
      type: Number,
      required: true,
    },
    counts: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    stepsCount() {
      return _.range(1, this.counts + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.steps-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0 0 15px;
}

.step {
  border-bottom: 2px solid $slate-grey-two;

  &:not(:first-child) {
    flex-grow: 1;
  }

  &:after {
    content: attr(data-count);
    display: flex;
    align-items: center;
    margin-bottom: -16px;
    border-radius: 50%;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: $slate-grey-two;
    margin-left: auto;
  }

  &.active {
    border-bottom-color: $azure;

    &:after {
      background-color: $azure;
    }
  }
}
</style>
