import { discharge } from '@utils/discharge';
import { i18n } from '@src/localization/config';

export function createTournamentView(state, id) {
  return {
    get tournament() {
      return state?.tournaments?.tournaments?.[id];
    },

    get baseTournament() {
      return {
        id: this.tournament?.id,
        name: this.tournament?.name,
        isPremium: this.tournament?.premium,
        isTrusted: this.tournament?.isTrusted,
        gameCode:
          state?.application?.games?.[this.tournament?.idGame]?.code,
        link: {
          name: 'tournament',
          params: {
            id: this.tournament?.urlCode || this.tournament?.id,
          },
        },

        get isExist() {
          return Boolean(this.id);
        },
      };
    },

    get prizeInfo() {
      const prizesList = this.tournament?.prize || [];
      const prizeTooltip = state?.application?.prizeTypes;
      const getIcon = (type) =>
        ({
          1: 'ruble',
          2: 'pvp-swords-round',
          6: 'mail-bonus',
          7: 'wf-credit',
        }[type] || 'gift');

      return {
        get prizes() {
          return prizesList?.map(({ type, count, bonusAmount }) => ({
            count: discharge(type === 'bonus' ? bonusAmount : count),
            icon: getIcon(type),
            tooltip:
              prizeTooltip[type] || i18n.t('global.valuablePrizes'),
          }));
        },
        get hasPrizes() {
          return Boolean(this.prizes?.length);
        },
      };
    },

    get statusCode() {
      return state?.tournaments?.statuses?.[this.tournament?.idStatus]
        ?.code;
    },

    get systemCode() {
      return state?.tournaments?.systems?.[this.tournament?.idSystem]
        ?.code;
    },

    get format() {
      const numTeamMembers = this.tournament?.numTeamMembers;
      return numTeamMembers
        ? `${numTeamMembers}x${numTeamMembers}`
        : '';
    },

    get countDown() {
      const now = Math.trunc(Date.now() / 1000);
      if (this.tournament?.tsStartReg > now) {
        return {
          ts: this.tournament?.tsStartReg,
          text: i18n.t('tournaments.timeleftTo_registrationStart'),
        };
      }
      return {
        ts: this.tournament?.tsStartRun,
        text:
          this.tournament?.tsStartRun &&
          i18n.t('tournaments.timeleftTo_tournamentStart'),
      };
    },

    get participantsCountStatistic() {
      const {
        numActiveParticipants = 0,
        participantsCount = 0,
        numLimitParticipants = 0,
      } = this.tournament || {};
      return `${
        participantsCount || numActiveParticipants
      } / ${numLimitParticipants}`;
    },

    get hasAntiCheat() {
      return (
        this.tournament?.hasAntiCheat || this.tournament?.antiCheat
      );
    },

    get userInfo() {
      const userInfo =
        state?.tournaments?.currentUserTournaments?.[
          this.tournament?.id
        ] || {};
      const {
        participationInfo = {},
        team = {},
        ...other
      } = userInfo;
      const { participationInfo: { ...abilities } = {}, ...info } =
        participationInfo;
      return {
        ...info,
        ...other,
        ...abilities,
        team: { ...team, ...abilities?.team },
        canRegister:
          info?.canRegister !== false &&
          this.statusCode === 'regopen',
        isParticipant: Boolean(info?.isParticipant),
      };
    },

    get countdownDate() {
      switch (this.statusCode) {
        case 'published':
          return this.tournament?.tsStartReg;
        case 'regopen':
          return this.tournament?.tsEndReg;
        case 'confirmation':
        case 'preparation':
          return this.tournament?.tsStartRun;
        case 'executing':
          return this.tournament?.tsEndRun;
        default:
          return null;
      }
    },

    get participantsCount() {
      return this.tournament?.participantsCount;
    },

    get isHub() {
      return this.tournament?.idSystem === 9;
    }
  };
}
