<template>
  <div :class="['hero', name]" :style="style" v-on="$listeners">
    <div v-if="disabled" class="disabled">
      <Icon name="lock" />
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: '100',
    },
  },
  computed: {
    style() {
      return {
        fontSize: `${this.width / 300}em`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  @import './hero.scss';
  position: relative;
  background-image: url(~@components/GameComponents/HearthstoneHero/hero.jpeg);
}

.disabled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(black, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    flex-shrink: 0;
    width: 4em;
    height: 4em;
    color: rgba(white, 0.5);
  }
}
</style>
