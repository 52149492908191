<template>
  <div class="details"></div>
</template>

<script>
export default {
  name: 'WysiwygViewer',
};
</script>

<style lang="scss" scoped>
.details {
  overflow: auto;

  ::v-deep {
    pre {
      font: inherit;
    }

    img {
      max-width: 100%;
      width: 100%;
    }

    .table-wrapper {
      @include max-tablet() {
        min-width: 500px;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      margin-left: auto;
      margin-right: auto;
    }

    th,
    td {
      padding: 0.2em 0.5em;
      border: 1px solid rgba(white, 0.5);
    }

    td {
      overflow: auto;
    }

    h2,
    h3,
    ul,
    ol,
    div,
    table,
    * + p {
      margin-top: 1em;
    }

    p:empty {
      height: 1em;
    }

    .infobox {
      padding: 12px;
      background-color: rgba($dark, 0.75);
      border-left: 4px solid $azure;
      border-radius: 5px;
      color: rgba(white, 0.7);
      text-align: left;
    }

    a {
      display: inline-block;
      max-width: 100%;
    }

    .align {
      &-left {
        text-align: left;
      }

      &-center {
        text-align: center;
      }

      &-right {
        text-align: right;
      }

      &-justify {
        text-align: justify;
      }
    }

    iframe {
      width: 100%;
      border: none;
      height: calc((9 / 16) * 400px);

      @include min-desktop() {
        height: calc((9 / 16) * 800px);
      }
    }
  }
}
</style>
