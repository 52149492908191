<template>
  <div class="terms-accept-form">
    <BaseCheckbox v-model="termsAccepted">
      {{ $t('organizations.request_accept') }}
      <template v-for="(term, index) in terms">
        <TermLink :key="term.id" class="multiline" :code="term.id">
          {{ term.title }}
          <template v-if="termsCount !== index">,</template>
        </TermLink>
      </template>
    </BaseCheckbox>
    <div class="controls">
      <pvp-btn
        :disabled="acceptButtonDisabled"
        :is-loading="acceptButtonLoading"
        @click="acceptTerms"
        >{{ $t('profile.terms_continue') }}
      </pvp-btn>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@components/BaseComponents/Form/BaseCheckbox.vue';
import TermLink from '@components/BaseComponents/TermLink.vue';

export default {
  name: 'TermsAcceptForm',
  components: { BaseCheckbox, TermLink },
  props: {
    terms: {
      type: Array,
      required: true,
    },
    tournamentId: {
      type: [Number, String],
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingState: {
      terms: false,
    },

    termsAccepted: false,
  }),
  computed: {
    acceptButtonLoading() {
      return this.isLoading || this.loadingState.terms;
    },

    acceptButtonDisabled() {
      return this.acceptButtonLoading || !this.termsAccepted;
    },

    termsCount() {
      const termsLength = this.terms?.length || 1;
      return termsLength - 1;
    },

    termsIds() {
      return this.terms?.map(({ id }) => id);
    },
  },
  methods: {
    acceptTerms() {
      this.loadingState.terms = true;
      const params = {
        id: this.termsIds,
      };

      if (this.tournamentId) {
        params.tournamentId = this.tournamentId;
      }

      api
        .post('/profile/term/accept', params)
        .then((data) => {
          if (data?.success) {
            this.$emit('accepted');
          }
        })
        .finally(() => {
          this.loadingState.terms = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-accept-form {
  .controls {
    text-align: center;
    margin-top: 30px;
  }
}

.multiline {
  display: inline-block;
  white-space: normal;
  word-wrap: break-word;
}
</style>
