// Avatars
import UserAvatar from '@components/BaseComponents/UserAvatar.vue';
import TeamAvatar from '@components/BaseComponents/TeamAvatar.vue';
import OrganizationAvatar from '@components/BaseComponents/OrganizationAvatar.vue';

// Icons
import GameIcon from '@components/BaseComponents/GameIcon.vue';

// Tags
import GameTag from '@components/BaseComponents/GameTag.vue';
import CountryTag from '@components/BaseComponents/Country/CountryTag.vue';

// Boxes
import Box from '@components/BaseComponents/Box.vue';

// Mixed
import Loader from '@components/BaseComponents/Loader.vue';
import Tooltip from '@components/BaseComponents/Tooltip.vue';
import Button from '@components/BaseComponents/Button.vue';
import Pagination from '@components/BaseComponents/Pagination.vue';
import Datepicker from '@components/BaseComponents/Datepicker/Datepicker.vue';
import Modal from '@components/BaseComponents/Modal.vue';
import HeadLine from '@components/BaseComponents/HeadLine.vue';
import TabsMenu from '@components/BaseComponents/TabsMenu.vue';
import TimeLeft from '@components/BaseComponents/TimeLeft.vue';

// Layout
import MainLayout from '@layouts/MainLayout.vue';

// Slider
import 'hooper/dist/hooper.css';

Vue.component('main-layout', MainLayout);

Vue.component('box', Box);

Vue.component('pvp-pagination', Pagination);
Vue.component('pvp-tooltip', Tooltip);
Vue.component('pvp-modal', Modal);

Vue.component('pvp-datepicker', Datepicker);

Vue.component('user-avatar', UserAvatar);
Vue.component('team-avatar', TeamAvatar);
Vue.component('organization-avatar', OrganizationAvatar);

Vue.component('game-icon', GameIcon);

Vue.component('game-tag', GameTag);
Vue.component('country-tag', CountryTag);

Vue.component('loader', Loader);

Vue.component('pvp-btn', Button);

Vue.component('headline', HeadLine);
Vue.component('tabs-menu', TabsMenu);

Vue.component('time-left', TimeLeft);
Vue.component('wysiwyg-editor', () =>
  import(
    /* webpackChunkName: "wysiwyg" */ '@components/Wysiwyg/WysiwygEditor.vue'
  ),
);
