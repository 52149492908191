<template>
  <div class="pd-personal-solo">
    <InfoBox variant="warning">
      {{ $t('tournaments.pd-solo-required-info') }}
    </InfoBox>
    <InfoBox v-if="error" variant="error">{{ error }}</InfoBox>
  </div>
</template>

<script>
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'PersonalDataSoloRequired',

  components: { InfoBox },

  props: {
    error: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.pd-personal-solo {
  display: inline-block;
  text-align: center;
}
.pd-fill-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
