export const state = {
  hubId: 0,
  matchId: 0,
  status: '',
  startTime: 0,
  lobby: null,
  isMatchmacking: false,
  isSearching: false,
  isCheckin: false,
  isMatch: false,
};

export const getters = {
  getHubId: (state) => state.hubId,
  getMatchId: (state) => state.matchId,
  getStatus: (state) => state.status,
  getLobby: (state) => state.lobby,
  getMatchmakingStartTime: (state) => state.startTime,
  getMatchmakingHubId: (state) => state.hubId,
  getIsMatchmacking: (state) => state.isMatchmacking,
  getIsSearching: (state) => state.isSearching,
  getIsCheckin: (state) => state.isCheckin,
  getIsMatch: (state) => state.isMatch,
};

export const mutations = {
  SET_HUB_ID(state, value) {
    state.hubId = value;
  },
  SET_MATCH_ID(state, value) {
    state.matchId = value;
  },
  SET_STATUS(state, value) {
    state.status = value;
  },
  SET_IS_MATCHMACKING(state, value) {
    state.isMatchmacking = value;
  },
  SET_IS_SERCHING(state, value) {
    state.isSearching = value;
  },
  SET_IS_CHECKIN(state, value) {
    state.isCheckin = value;
  },
  SET_IS_MATCH(state, value) {
    state.isMatch = value;
  },
  SET_START_TIME(state, value) {
    state.startTime = value;
  },
  SET_LOBBY(state, value) {
    state.lobby = value;
  },
};

export const actions = {
  async fetchMatchmakingState({ commit }) {
    const response = await api.get('hub/participant/state');
    const status = response.data.state;
    const isMatchmaking = status !== 'notParticipating';
    const isSerching = status === 'matchmaking';
    const isMatch = status === 'match';
    const isCheckin =
      status === 'lobby' &&
      response.data.details.lobby.status === 'checkin';
    const matchmakingStartTime =
      response.data.details?.matchmakingRegisteredAtTs || 0;
    const hubId = response.data.hubId || 0;

    commit('SET_HUB_ID', hubId);
    commit('SET_START_TIME', matchmakingStartTime);
    commit('SET_IS_MATCH', isMatch);
    commit('SET_IS_SERCHING', isSerching);
    commit('SET_IS_MATCHMACKING', isMatchmaking);
    commit('SET_IS_CHECKIN', isCheckin);
    commit('SET_STATUS', status);

    if (isCheckin) {
      commit('SET_LOBBY', response.data.details.lobby);
    }
    if (isMatch) {
      commit('SET_MATCH_ID', response.data.details.match.id);
    }
  },
};
