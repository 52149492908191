<template>
  <pvp-modal
    :value="showAdditionalRegistration"
    width="500"
    @input="closeModal"
  >
    <div slot="modal-title">{{ $t('profile.creation') }}</div>
    <create-profile-form @registered="onRegistered" />
  </pvp-modal>
</template>

<script>
import CreateProfileForm from '@components/ProfileComponents/ProfileEdit/CreateProfileForm.vue';
import { pushEvents } from '@utils/metricEvents.js';

export default {
  name: 'ProfileCreateModalVkplay',
  components: { CreateProfileForm },
  computed: {
    ...mapState('profile', ['showAdditionalRegistration']),
    ...mapState('tournamentRegistration', ['tournamentId']),
  },
  methods: {
    ...mapActions('profile', ['fetchAuth']),

    async onRegistered() {
      // ======================================================
      // METRICS Пользователь зарегистрировался на PVP
      const tmrParams = {
        category: 'pvp',
        action: 'account_registration',
      };
      pushEvents('pvp_account_registration', tmrParams);
      // ======================================================

      if (window.location.href.includes('registration')) {
        window.location.reload(); // убрать когда будет переписан флоу авторизации
        return;
      }

      await this.fetchAuth();
      window.__GEM.renderHeader();
      this.$store.commit(
        'profile/SET_SHOW_ADDITIONAL_REGISTRATION',
        false,
      );

      this.$emit('registered', this.tournamentId);
    },
    closeModal(closeData) {
      this.$store.commit(
        'profile/SET_SHOW_ADDITIONAL_REGISTRATION',
        closeData,
      );
    },
  },
};
</script>
