<template>
  <BaseForm
    v-if="accountsList.length"
    class="multiaccount"
    @submit="fetchAccount"
  >
    <h3>{{ $t('account.choose_primary') }}</h3>
    <p class="description">
      {{ $t('account.mainDescription', { game: game.name }) }}
    </p>
    <div class="shards">
      <div
        v-for="(shard, key) in accountsList"
        :key="key"
        class="shard"
      >
        <div class="flex">
          <template v-if="shard.accounts.length > 1">
            <BaseRadio
              v-model="defaultAccount"
              :value="shard.code"
              checked
            />
            <BaseSelect
              v-model="accounts[shard.code]"
              value-key="hash"
              label-key="nickname"
              :required="true"
              :options="shard.accounts"
              list-position="top"
            />
          </template>
          <BaseRadio
            v-else
            v-model="defaultAccount"
            :value="shard.code"
          >
            {{ shard.accounts[0].nickname }}
          </BaseRadio>
        </div>
        <div v-if="game.code !== 'bt2'" class="server">
          {{ $t('global.server') }}: {{ shard.label }}
        </div>
      </div>
    </div>
    <div class="btn">
      <pvp-btn class="submit" type="submit">
        {{ $t('games.bind_single') }}
      </pvp-btn>
    </div>
  </BaseForm>
</template>

<script>
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import BaseRadio from '@components/BaseComponents/Form/Radio.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
export default {
  name: 'MultiAccount',
  components: {
    BaseForm,
    BaseRadio,
    BaseSelect,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    list: {},
    accounts: {},
    defaultAccount: '',
  }),
  computed: {
    ...mapGetters('application', [
      'getLaShards',
      'getWfShards',
      'getGame',
    ]),
    ...mapGetters('profile', ['getLinkedGameAccount']),

    game() {
      return this.getGame(this.id);
    },
    shards() {
      switch (this.game.code) {
        case 'wf':
          return this.getWfShards;
        case 'lar':
          return this.getLaShards;
        default:
          return [];
      }
    },

    gameAccount() {
      return this.getLinkedGameAccount(this.id);
    },
    accountsList() {
      return Object.values(this.list);
    },
  },
  created() {
    this.$emit('error', '');
    this.fetchPersonages();
  },
  methods: {
    fetchPersonages() {
      this.$emit('loading', true);
      api
        .get('/profile/gameaccounts/personage', {
          params: {
            game: this.id,
          },
        })
        .then((data) => {
          if (data.personages) {
            const hashList = Object.entries(data.personages);

            if (
              hashList.length === 1 &&
              typeof hashList[0]?.[1] === 'string'
            ) {
              this.defaultAccount = 0;
              this.accounts = {
                0: hashList[0]?.[0],
              };
              return this.fetchAccount();
            }

            const { accounts, list } = hashList.reduce(
              ({ accounts, list }, [hash, data]) => {
                const { shard_id: shardId, nickname } = data;
                if (data.isDefault) {
                  this.defaultAccount = shardId;
                }

                if (data.selected) {
                  accounts = _.merge({}, accounts, {
                    [shardId]: hash,
                  });
                }

                return {
                  accounts,
                  list: _.merge({}, list, {
                    [shardId]: {
                      ...this.shards?.find(
                        ({ code }) => code === shardId,
                      ),
                      accounts: (
                        list[shardId]?.accounts || []
                      ).concat({
                        hash,
                        nickname,
                      }),
                    },
                  }),
                };
              },
              { accounts: {}, list: {} },
            );

            if (!this.defaultAccount) {
              this.defaultAccount = hashList[0]?.[1]?.shard_id;
            }

            Object.keys(list).forEach((shardId) => {
              if (accounts[shardId] === undefined) {
                accounts[shardId] =
                  list[shardId]?.accounts?.[0]?.hash;
              }
            });

            this.accounts = accounts;
            this.list = list;
          }
          return true;
        })
        .catch(({ error }) => {
          this.$emit('error', error || this.$t('errors.unknown'));
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },
    fetchAccount() {
      this.$emit('loading', true);
      return api
        .post('/profile/gameaccounts/link', {
          game: this.id,
          personages: Object.values(this.accounts),
        })
        .then(this.fetchDefaultAccount)
        .catch((data) => {
          this.$emit(
            'error',
            data?.error || this.$t('errors.unknown'),
          );
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },
    fetchDefaultAccount() {
      return api
        .post('/profile/gameaccounts/default', {
          game: this.id,
          hash:
            this.game.code === 'bt2'
              ? this.accounts.live
              : this.accounts[this.defaultAccount],
        })
        .then(() => this.$emit('success'))
        .catch((data) => {
          this.$emit(
            'error',
            data?.error || this.$t('errors.unknown'),
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.multiaccount {
  text-align: left;

  &-title {
    font-size: 15px;
    line-height: 1;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .icon {
      margin-left: 6px;
      color: rgba(white, 0.6);
    }
  }
}

.game {
  &-nick {
    display: block;
    font-size: 14px;
    margin-top: 12px;
  }

  &-server {
    color: rgba(white, 0.4);

    &:first-letter {
      text-transform: lowercase;
    }
  }
}

.full {
  margin: 30px 0 20px;

  .description {
    margin-top: 10px;
  }
}

.description {
  font-size: 14px;
  color: rgba(white, 0.5);
}

.shards {
  padding-bottom: 100px;
}

.shard {
  margin-top: 12px;
}

.flex {
  display: flex;
  align-items: center;
}

.select {
  flex-grow: 1;

  ::v-deep {
    .list {
      max-height: 120px;
    }
  }
}

.server {
  margin-left: 2em;
  font-size: 14px;
  color: rgba(white, 0.5);
}

.btn {
  text-align: center;
}
</style>
