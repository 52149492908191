import { getTournamentCard } from '@viewModels/tournament/getTournamentCard';
import { getTournamentSlide } from '@viewModels/tournament/getTournamentSlide';
import { getTournamentTable } from '@viewModels/tournament/getTournamentTable';
import { getTournamentPage } from '@viewModels/tournament/getTournamentPage';

export const state = {
  tournaments: {},
  tournamentsRules: {},
  tournamentsContacts: {},
  brackets: {},
  statuses: {},
  systems: {},
  availableSystems: {},
  settings: {},
  pickBanSystems: [],

  teamsInTournamentStatuses: {},
  currentUserTournaments: {},
};

export const getters = {
  getTournamentCard,
  getTournamentSlide,
  getTournamentTable,
  getTournamentPage,

  getTournament: (state) => (id) => {
    const tournament = state.tournaments[id];

    if (!tournament) {
      return undefined;
    }

    const status = state.statuses[tournament.idStatus];
    const system = state.systems[tournament.idSystem];

    let countdownDate;

    switch (_.get(status, 'code')) {
      case 'published':
        countdownDate = tournament.tsStartReg;
        break;
      case 'regopen':
        countdownDate = tournament.tsEndReg;
        break;
      case 'confirmation':
      case 'preparation':
        countdownDate = tournament.tsStartRun;
        break;
      case 'executing':
        countdownDate = tournament.tsEndRun;
        break;
      default:
        countdownDate = null;
    }

    return {
      ...tournament,
      currentUserStatus: state.currentUserTournaments[id],
      countdownDate,
      status,
      system,
    };
  },

  getSystems: (state) => state.systems,

  getTournamentRules: (state) => (tournamentId) =>
    state.tournamentsRules[tournamentId],

  getTournamentContacts: (state) => (tournamentId) =>
    state.tournamentsContacts[tournamentId],

  getTournamentSettings: (state) => (gameId) =>
    state.settings?.[gameId],

  getTournamentBracket: (state) => (tournamentId) =>
    state.brackets?.[tournamentId],

  getIsHub: (state) => (tournamentId) =>
    state.tournaments[tournamentId].idSystem === 9,
};

export const mutations = {
  UPDATE_TOURNAMENT(state, tournament) {
    state.tournaments = {
      ...state.tournaments,
      [tournament.id]: {
        ...state?.tournaments?.[tournament.id],
        ...tournament,
      },
    };
  },

  UPDATE_TOURNAMENTS(state, tournaments) {
    const mergedTournaments = tournaments.reduce(
      (obj, tournament) => {
        obj[tournament.id] = _.merge(
          {},
          state.tournaments[tournament.id],
          tournament,
        );
        return obj;
      },
      {},
    );
    state.tournaments = {
      ...state.tournaments,
      ...mergedTournaments,
    };
  },

  SET_STATUSES(state, statuses) {
    state.statuses = _.keyBy(statuses, 'id');
  },
  SET_SYSTEMS(state, systems) {
    state.systems = _.keyBy(systems, 'id');
  },

  SET_AVAILABLE_SYSTEMS(state, availableSystems) {
    state.availableSystems = _.keyBy(availableSystems, 'id');
  },

  SET_CURRENT_USER_TOURNAMENTS(state, tournaments) {
    state.currentUserTournaments = _.keyBy(
      tournaments,
      'tournamentId',
    );
  },

  SET_TEAMS_IN_TOURNAMENTS_STATUSES(state, newValue) {
    state.teamsInTournamentStatuses = newValue;
  },

  UPDATE_TOURNAMENTS_RULES(state, rules) {
    state.tournamentsRules = {
      ...state.tournamentsRules,
      ...rules,
    };
  },

  SET_TOURNAMENTS_CONTACTS(state, contacts) {
    state.tournamentsContacts = {
      ...state.tournamentsContacts,
      ...contacts,
    };
  },

  SET_TOURNAMENTS_SETTINGS(state, settings) {
    state.settings = settings;
  },

  UPDATE_TOURNAMENT_BRACKET(state, { id, rounds }) {
    state.brackets = {
      ...state.brackets,
      [id]: rounds,
    };
  },

  SET_TOURNAMENTS_PICKBANSYSTEMS(state, systems) {
    state.pickBanSystems = systems;
  },
};

export const actions = {
  fetchTournaments(
    { dispatch },
    {
      page = 1,
      limit = 12,
      type = 'future',
      gameId = null,
      isInRecommendedList,
      tournamentTimeStart = 'asc',
      organization = null,
    } = {},
  ) {
    return api
      .get('/tournament/info_list', {
        params: {
          type,
          limit,
          page,
          gamesList: gameId,
          organization,
          sort: {
            isInRecommendedList,
            tournamentTimeStart,
          },
        },
      })
      .then((data) =>
        dispatch('storeTournaments', data.list).then(
          (tournaments) => ({
            tournaments: tournaments.map(({ id }) => id),
            pagination: {
              page,
              perPage: limit,
              total: data.totalItems,
              totalPages: data.pagesCount,
            },
          }),
        ),
      );
  },

  fetchProfileTournaments(
    { dispatch },
    {
      hash,
      limit = 10,
      page = 1,
      type = 'all',
      gameId = null,
      organization = null,
      sort = {
        field: 'date',
        order: 'desc',
      },
    } = {},
  ) {
    return api
      .get(`/profile/${hash}/tournament/participation`, {
        params: {
          pageSize: Number(limit),
          pageNum: Number(page),
          search: {
            type,
            gamesIds: [gameId],
            organization,
            sort: {
              field: sort.field,
              order: sort.order,
            },
          },
        },
      })
      .then(async (data) => {
        const tournaments = await dispatch(
          'storeTournaments',
          _.get(data, 'items.items', []),
        );

        return {
          tournaments: tournaments.map((tournament) => tournament.id),
          pagination: {
            total: data.totalItems,
            totalPages: data.pagesCount,
          },
        };
      });
  },

  fetchOrganizationTournaments(
    { dispatch },
    {
      limit = 10,
      page = 1,
      type = 10,
      gameId = null,
      organizationId = null,
      sort = {
        field: 'date',
        order: 'desc',
      },
    },
  ) {
    return api
      .get(`organization/${organizationId}/tournaments`, {
        params: {
          pageSize: limit,
          pageNum: page - 1,
          search: {
            type,
            idGame: gameId,
            sort: {
              field: sort.field,
              order: sort.order,
            },
          },
        },
      })
      .then(async (data) => {
        const tournaments = await dispatch(
          'storeTournaments',
          data.items,
        );
        return {
          tournaments: tournaments.map((tournament) => tournament.id),
          pagination: {
            page: data.pagen.pageNum,
            perPage: data.pagen.pageSize,
            total: data.pagen.totalCount,
            totalPages: data.pagen.pagesCount,
          },
        };
      });
  },

  fetchTournamentPage({ dispatch }, id) {
    return api.get(`/tournament/${id}`).then(async (data) => {
      const tournament = {
        ...data.tournament,
        restrictions: data.restrictions,
        video: data.video,
      };
      await dispatch('storeTournament', tournament);

      dispatch('organizations/storeOrganization', data.organization, {
        root: true,
      });
      return {
        tournament,
        organization: data.organization,
        tabs: data.tabs,
      };
    });
  },

  storeTournament({ commit }, tournament) {
    commit('UPDATE_TOURNAMENT', tournament);
    return tournament.id;
  },

  storeTournaments({ state, commit }, tournaments) {
    commit('UPDATE_TOURNAMENTS', tournaments);
    return tournaments.map(
      (tournament) => state.tournaments[tournament.id],
    );
  },

  fetchTournamentTeams({ dispatch }, { id, perPage = 10, page = 1 }) {
    return api
      .get(`/tournament/${id}/players`, {
        params: {
          pageSize: perPage,
          pageNum: page - 1,
        },
      })
      .then(async (data) => {
        const players = data.players.map((player) => ({
          ...player,
          participationStatus: player.status,
        }));
        const teams = await dispatch('teams/storeTeams', players, {
          root: true,
        });
        return {
          teams: teams.map((team) => team.hash),
          pagination: {
            page: data.pagen.pageNum + 1,
            perPage: data.pagen.pageSize,
            total: data.pagen.totalCount,
            totalPages: data.pagen.pagesCount,
          },
        };
      });
  },

  fetchSubgroups({ commit }, { id, groups }) {
    const promises = groups.map((group) =>
      api
        .get(`/tournament/${id}/bracket/group/${group.id}/schedule`)
        .catch(() => ({ rounds: [] })),
    );

    return Promise.all(promises).then((result) => {
      result.forEach(({ rounds }) => {
        rounds.forEach((round) => {
          const matches = round.matches.map((match) => {
            match.tournamentId = id;
            match.hashWinner = [match.team1, match.team2].find(
              (team) => team?.id === match.idWinner,
            )?.hash;
            match.team1.points = match.pointsTeam1;
            match.team2.points = match.pointsTeam2;
            return match;
          });
          commit('matches/UPDATE_MATCHES', matches, { root: true });
        });
      });
      return commit('UPDATE_TOURNAMENT_BRACKET', {
        id,
        rounds: { groups, detail: result },
      });
    });
  },

  fetchTournamentBracket({ dispatch, commit, getters }, id) {
    const tournament = getters.getTournament(id);
    if (tournament?.isBracketFormed === false) {
      return false;
    }
    return api.get(`/tournament/${id}/bracket`).then((data) => {
      if (data.error) {
        return data;
      }

      if (tournament?.system?.code === 'subgroups') {
        const { groups } = data;

        const mapTeam = ({ team }) => ({
          name: team.name,
          hash: team.hash,
          image: team.imgLogo,
        });

        const teams = groups.reduce(
          (result, { teams }) => result.concat(teams.map(mapTeam)),
          [],
        );
        dispatch('teams/storeTeams', teams, { root: true });

        return dispatch('fetchSubgroups', { id, groups });
      }

      let { rounds } = data;
      const { teams = [], matches = [] } = data;

      if (_.isArray(rounds?.winners)) {
        rounds = rounds?.winners.concat(rounds.losers);
      }

      if (teams.length === 0) {
        rounds.forEach((round) => {
          round.matches.forEach((item) => {
            const match = {
              ...item,
              tournamentId: id,
              team1: {
                ...item?.team1,
                points: item.pointsTeam1,
              },
              team2: {
                ...item?.team2,
                points: item.pointsTeam2,
              },
              hashWinner: [item.team1, item.team2].find(
                (team) => team?.id === item.idWinner,
              )?.hash,
            };

            matches.push(match);
            if (match?.team1?.hash) {
              teams.push(match.team1);
            }
            if (match?.team2?.hash) {
              teams.push(match.team2);
            }
          });
        });
      }

      commit('UPDATE_TOURNAMENT_BRACKET', {
        id,
        rounds: data.rounds,
      });
      commit('matches/UPDATE_MATCHES', matches, { root: true });
      return dispatch('teams/storeTeams', teams, { root: true });
    });
  },

  fetchTournamentRules({ commit }, id) {
    return api.get(`/tournament/${id}/rules`).then(({ rules }) => {
      commit('UPDATE_TOURNAMENTS_RULES', {
        [id]: rules,
      });
    });
  },

  fetchTournamentContacts({ commit }, id) {
    return api
      .get(`/tournament/${id}/contacts`)
      .then(({ contacts }) => {
        commit('SET_TOURNAMENTS_CONTACTS', {
          [id]: contacts,
        });
      });
  },

  fetchTournamentTable({ dispatch }, { page = 1, limit = 12, id }) {
    return api
      .get(`/tournament/${id}/table`, {
        params: {
          pageSize: limit,
          pageNum: page - 1,
        },
      })
      .then((data) => {
        data.items.map((item) => {
          const team = {
            name: item.name,
            hash: item.hash,
            image: item.image,
          };
          return dispatch('teams/storeTeam', team, { root: true });
        });

        return {
          rating: {
            ...data.rating,
            places: data.items,
          },
          pagination: {
            page: data.pagen.pageNum,
            perPage: data.pagen.pageSize,
            total: data.pagen.totalCount,
            totalPages: data.pagen.pagesCount,
          },
        };
      });
  },

  fetchTournamentPlayersTable(
    { dispatch },
    { page = 1, limit = 12, id },
  ) {
    return api
      .get(`/tournament/${id}/killstatistic`, {
        params: {
          pageSize: limit,
          pageNum: page - 1,
        },
      })
      .then((data) => {
        data.items.map((item) => {
          const team = {
            name: item.nick,
            hash: item.hash,
            image: item.image,
          };
          return dispatch('teams/storeTeam', team, { root: true });
        });

        return {
          rating: {
            ...data.rating,
            places: data.items,
          },
          pagination: {
            page: data.pagen.pageNum,
            perPage: data.pagen.pageSize,
            total: data.pagen.totalCount,
            totalPages: data.pagen.pagesCount,
          },
        };
      });
  },

  storeCurrentUserTournaments({ commit }, tournaments) {
    commit('SET_CURRENT_USER_TOURNAMENTS', tournaments);
  },

  fetchTnSettings({ commit }) {
    return api
      .get('catalogue/systems')
      .then(({ defaultSystems, gameSystems, pickBanSystems }) => {
        const games = Object.keys(defaultSystems).reduce(
          (result, gameId) => {
            result[gameId] = {
              defaultSettings: defaultSystems[gameId],
              systems: Object.keys(gameSystems[gameId]),
              systemSettings: gameSystems[gameId],
            };
            return result;
          },
          {},
        );
        commit('SET_TOURNAMENTS_SETTINGS', games);
        commit('SET_TOURNAMENTS_PICKBANSYSTEMS', pickBanSystems);
      });
  },

  fetchRecommendedTournaments({ dispatch }) {
    return api
      .get('/tournament/complex')
      .then(({ recommends }) =>
        recommends?.map(({ item }) =>
          dispatch('storeTournament', item),
        ),
      )
      .then((ids = []) => Promise.all(ids));
  },
};
