import { fetchTime } from '../api/time';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTimeStore = defineStore('time', () => {
  const initialTs = Math.floor(Date.now() / 1000);
  const serverTime = ref(initialTs);
  const diff = ref(0);

  async function fetchServerTime() {
    const response = await fetchTime();

    const diff =
      Math.floor(Date.now() / 1000) -
      new Date(response.time * 1000).getTime() / 1000;

    serverTime.value = response.time;
    diff.value = diff;
  }

  function getTimeWithDiff(ts) {
    return ts + diff.value;
  }

  return { serverTime, diff, fetchServerTime, getTimeWithDiff };
});
