export const state = {
  organizations: {},
  organizationMembers: {},
  inviteRoles: [],
};

export const getters = {
  getOrganizations: (state) => state.organizations,

  getOrganizationsList: (state) => Object.values(state.organizations),

  getOrganization: (state) => (id) => {
    const organization = state.organizations[id];

    if (!organization) {
      return undefined;
    }
    return organization;
  },

  getOrganizationMembers: (state) => (id) =>
    state.organizationMembers[id],
};

export const mutations = {
  UPDATE_ORGANIZATIONS(state, organizations) {
    const merged = organizations.map((org) =>
      _.merge({}, state.organizations[org.id], org),
    );
    state.organizations = {
      ...state.organizations,
      ..._.keyBy(merged, 'id'),
    };
  },

  UPDATE_ORGANIZATION_MEMBERS(state, members) {
    state.organizationMembers = {
      ...state.organizationMembers,
      ...members,
    };
  },

  DELETE_ORGANIZATION(state, id) {
    Vue.delete(state.organizations, id);
  },

  SET_INVITE_ROLES(state, roles) {
    state.inviteRoles = roles;
  },
};

export const actions = {
  storeOrganization({ dispatch }, organization) {
    return dispatch('storeOrganizations', [organization]).then(
      _.head,
    );
  },

  storeOrganizations({ commit, state }, organizations) {
    organizations = organizations.map((organization) => {
      const cachedOrganization = state.organizations[organization.id];

      return {
        ...cachedOrganization,
        ...organization,
        id: Number(organization.id),
        name: organization.name,
        image: organization.imgLogo,
        bgImage: organization.imgProfile,
      };
    });

    commit('UPDATE_ORGANIZATIONS', organizations);

    return organizations.map(
      (organization) => state.organizations[organization.id],
    );
  },

  fetchOrganization({ dispatch }, id) {
    return api
      .get(`/organization/${id}`)
      .then(({ organization, ...abilities }) => {
        dispatch('storeOrganization', { ...organization, abilities });
      });
  },

  async fetchOrganizationMembers({ commit, dispatch }, { id }) {
    return api.get(`/organization/${id}/players`).then((data) => {
      dispatch('users/storeUsers', data.items, { root: true }).then(
        (membersHash) => {
          commit('UPDATE_ORGANIZATION_MEMBERS', {
            [id]: membersHash,
          });
        },
      );
    });
  },

  fetchProfileOrganizations(
    { dispatch },
    { limit = 10, page = 1 } = {},
  ) {
    return api
      .get('/profile/organization', {
        params: {
          pageSize: limit,
          pageNum: page - 1,
        },
      })
      .then(async (data) => {
        const organizations = await dispatch(
          'storeOrganizations',
          data.items,
        );

        return {
          organizations,
          pagination: {
            page: data.pagen.pageNum,
            perPage: data.pagen.pageSize,
            total: data.pagen.totalCount,
            totalPages: data.pagen.pagesCount,
          },
        };
      });
  },
};
