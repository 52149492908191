<template>
  <img
    v-if="!error && src"
    class="team-avatar"
    :src="src"
    @error="onError"
  />
  <Icon v-else class="team-avatar" name="team" :inline="inline" />
</template>

<script>
import { isNull, isString } from 'lodash';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    src: {
      required: true,
      validator: (value) => {
        return (
          isNull(value) || isString(value) || value === undefined
        );
      },
    },
    inline: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    error: false,
  }),

  methods: {
    onError() {
      this.error = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.team-avatar {
  border-radius: 4px;

  &.icon {
    background: #161516;
    color: #f3f3f3;
  }
}
</style>
