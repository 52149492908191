const teamMemberRole = ({ isCaptain, isSpare }) => {
  if (isCaptain) {
    return 'captain';
  }

  if (isSpare) {
    return 'spare';
  }

  return 'player';
};

export const state = {
  users: {},
  usersTeams: {},
  usersMatches: {},
  userRequiredFields: [],
};

export const getters = {
  getUser: (state) => (hash) => state.users[hash],

  getUserMatchStatus: (state) => (id) => state.usersMatches[id],

  getUserProfile: (state) => (id) => state.users[id],

  getUserTeams: (state, getters, rootState) => (userHash) => {
    const { teams } = rootState.teams;

    const userTeams = state.usersTeams[userHash];

    return userTeams
      ? userTeams.map((relation) => ({
          ...teams[relation.teamHash],
          role: relation.role,
        }))
      : undefined;
  },
};

export const mutations = {
  UPDATE_USERS(state, users) {
    state.users = {
      ...state.users,
      ..._.keyBy(users, 'hash'),
    };
  },

  UPDATE_USERS_TEAMS(state, usersTeams) {
    state.usersTeams = {
      ...state.usersTeams,
      ...usersTeams,
    };
  },

  UPDATE_USERS_MATCHES(state, usersMatches) {
    state.usersMatches = {
      ...state.usersMatches,
      ...usersMatches,
    };
  },

  SET_REQUIRED_FIELDS(state, data) {
    state.userRequiredFields = data;
  },
};

export const actions = {
  fetchUserProfile({ dispatch }, { hash, complex = false }) {
    const promises = [api.get(`/profile/${hash}`)];

    if (complex) {
      promises.push(api.get(`/profile/${hash}/complex`));
    }

    return Promise.all(promises).then(([profile, complex]) => {
      // TODO: удалить после того, как будет переписан api

      const teamsMapper = (team) => ({
        ...team,
        hash: team.teamHash,
        name: team.teamName,
        size: team.numMembers,
      });

      const currentTeams = _.get(complex, 'teams_current', []);

      dispatch('teams/storeTeams', currentTeams.map(teamsMapper), {
        root: true,
      }).then((teams) =>
        dispatch('storeUserTeams', {
          userHash: profile.hash,
          teamsRelations: teams.map((team) => ({
            teamHash: team.hash,
            canLeave: team.canLeave,
            role: teamMemberRole(team),
          })),
        }),
      );

      return dispatch('storeUser', {
        ...profile,
        complex,
      });
    });
  },

  fetchUserMatches(
    { dispatch, commit },
    { hash, page = 1, limit = 8 },
  ) {
    const mapTournament = (match) => ({
      id: match.tournamentId,
      name: match.tournamentName,
      urlCode: match.tournamentUrlCode,
      idGame: match.gameId,
      idSystem: match.isFfaSystem ? 6 : undefined,
    });

    const mapTeam = (team) => ({
      ...team,
      points: team?.scores,
    });

    const mapMatch = (match) => {
      if (match.isFfaSystem) {
        return {
          ...match,
          id: match.matchId,
          name: match.ffaResult.matchName,
          round: { name: match.ffaResult.roundName },
          results: { place: match.ffaResult.place },
        };
      }

      return {
        ...match,
        id: match.matchId,
        hashWinner: match.teams[0].winnerHash,
        team1: mapTeam(match.teams[0]),
        team2: mapTeam(match.teams[1]),
      };
    };

    return api
      .post('/profile/matches/list', {
        playerHash: hash,
        pageNumber: page,
        itemsPerPage: limit,
      })
      .then(async (data) => {
        const { matches, tournaments, teams } = data.items.reduce(
          (acc, match) => {
            const matchData = mapMatch(match);
            acc.tournaments.push(mapTournament(match));
            acc.matches.push(matchData);
            if (!match.isFfaSystem) {
              acc.teams.push(matchData.team1, matchData.team2);
            }
            return acc;
          },
          {
            tournaments: [],
            matches: [],
            teams: [],
          },
        );

        await dispatch('tournaments/storeTournaments', tournaments, {
          root: true,
        });
        await dispatch('teams/storeTeams', teams, { root: true });
        commit('matches/UPDATE_MATCHES', matches, { root: true });

        return {
          matches,
          pagination: {
            total: data.totalItems,
            totalPages: data.pagesCount,
          },
        };
      });
  },

  storeUser({ dispatch }, user) {
    return dispatch('storeUsers', [user]).then(_.head);
  },

  storeUsers({ commit, state }, users) {
    users = users.map((user) => {
      const cachedUser = state.users[user.hash];

      return {
        ...cachedUser,
        ...user,
        name: user.nick || user.tnNick || user.name,
        image: user.image || user.avatarUrl,
      };
    });

    commit('UPDATE_USERS', users);

    return users.map((user) => user.hash);
  },

  storeUserTeams({ commit }, { userHash, teamsRelations }) {
    commit('UPDATE_USERS_TEAMS', {
      [userHash]: teamsRelations,
    });
  },
};
