/* eslint-disable */

import storage from '@utils/storage';
import { i18n } from '@/src/localization/config';

const STORAGE_LAST_TOURNAMENT =
  'pvp.tournamentRegistration.tournamentId';

export const state = {
  tournamentId: null,
  modalIsOpened: false,
  settings: null,
  requirementsError: null,
  isStartRegistration: false,

  // Hearthstone
  hearthstonePick: [],
  needFksPDFields: false,
};

export const getters = {
  isRegisteringToTournament: (state) => (id) =>
    id === state.tournamentId && state.modalIsOpened,
  isNeedFksPDFields: (state) => state.needFksPDFields,
  isStartRegistration: (state) => state.isStartRegistration,
};

export const mutations = {
  SET_TOURNAMENT_ID(state, id) {
    state.tournamentId = id;

    storage.setItem(STORAGE_LAST_TOURNAMENT, state.tournamentId);
  },

  SET_MODAL_IS_OPENED(state, value) {
    state.modalIsOpened = value;
  },

  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },

  SET_HS_PICK(state, pick) {
    state.hearthstonePick = pick;
  },

  SET_REQUIREMENTS_ERROR(state, error) {
    state.requirementsError = error;
  },

  SET_NEED_FCS_PD_FIELDS(state, bool) {
    state.needFksPDFields = bool;
  },

  SET_IS_START_REGISTRATION(state, bool) {
    state.isStartRegistration = bool;
  },
};

export const actions = {
  async startRegistration({ commit, dispatch, rootGetters }, id) {
    commit('SET_TOURNAMENT_ID', id);
    commit('SET_IS_START_REGISTRATION', true);
    dispatch('profile/showAdditionalRegistrationModal', null, {
      root: true,
    });

    if (!rootGetters['profile/isLoggedIn']) {
      return;
    }

    commit('SET_REQUIREMENTS_ERROR', null);

    const promises = [dispatch('fetchRegistrationData', id)];

    if (rootGetters['profile/isLoggedIn']) {
      promises.push(
        dispatch(
          'users/fetchUserProfile',
          {
            hash: rootGetters['profile/getCurrentProfileHash'],
            complex: true,
          },
          { root: true },
        ),
        dispatch('profile/fetchCurrentProfile', null, { root: true }),
      );
    }

    await Promise.all(promises).finally(() => {
      commit('SET_IS_START_REGISTRATION', false);
      commit('SET_MODAL_IS_OPENED', true);
    });
  },

  fetchRegistrationData(
    { dispatch, commit, state, rootGetters },
    id,
  ) {
    return Promise.all([
      dispatch('tournaments/fetchTournamentPage', id, { root: true }),
      api.get(`/tournament/${id}/me`),
    ]).then(([{ tournament }, register]) => {
      const {
        registerInfo = {},
        wfGameProfileInfo,
        needFksPDFields,
      } = register;

      commit('SET_NEED_FCS_PD_FIELDS', needFksPDFields);

      const {
        wfShardId,
        wfMinRank,
        hsLoadDeck,
        pickBanSystem,
        wfCheckCompendium,
        terms = [],
        errorsProfileFields = [],
        requiredProfileFields = [],
      } = registerInfo;

      if (requiredProfileFields.includes('idRegion')) {
        requiredProfileFields.push('idCountry');
      }

      const profileDetails =
        rootGetters['profile/getCurrentProfileDetails'];

      commit('SET_SETTINGS', {
        terms,

        teamSize: tournament.numTeamMembers,
        gameId: tournament.idGame,

        hearthstone: {
          hsLoadDeck,
          pickBanSystem,
          classesQuantity: registerInfo.hsPickCnt,
        },

        fcsRequired:
          tournament.needFksAccount ||
          (tournament.needFksTerms &&
            !profileDetails?.fcs?.isTermsAccepted),

        requiredProfileFields,

        warface: {
          wfMinRank,
          profile: wfGameProfileInfo,
          shardId: wfShardId,
        },
      });

      if (tournament.restrictions) {
        const { login } = rootGetters['profile/getCurrentProfile'];
        const { onlyOk = false, onlyVk = false } =
          tournament.restrictions;

        if (onlyOk && !login.includes('@ok')) {
          commit('SET_REQUIREMENTS_ERROR', {
            text: i18n.t('registration.errors_ok'),
          });
        }

        if (onlyVk && !login.includes('@vk')) {
          commit('SET_REQUIREMENTS_ERROR', {
            text: i18n.t('registration.errors_vk'),
          });
        }
      }

      if (wfCheckCompendium && !wfGameProfileInfo?.compendium) {
        commit('SET_REQUIREMENTS_ERROR', {
          code: 'wf_compendium',
          text: i18n.t('restrictions.withCompendium'),
        });
      }

      const needPremium = errorsProfileFields.some(
        (item) => item.field === 'premium',
      );

      if (needPremium) {
        commit('SET_REQUIREMENTS_ERROR', {
          code: 'mrg_premium',
          text: i18n.t('premium.banner'),
        });
      }

      return register;
    });
  },

  reopenLastRegistration({ state, dispatch, rootGetters }) {
    if (
      state.modalIsOpened ||
      state.tournamentId ||
      rootGetters['profile/mustAcceptTerms']
    ) {
      return;
    }

    const tournamentId = storage.getItem(STORAGE_LAST_TOURNAMENT);

    if (tournamentId) {
      dispatch('startRegistration', tournamentId);
    }
  },

  setModalState({ state, commit }, modalIsOpened) {
    if (state.modalIsOpened === modalIsOpened) {
      return false;
    }

    commit('SET_MODAL_IS_OPENED', modalIsOpened);

    if (!modalIsOpened) {
      commit('SET_TOURNAMENT_ID', null);
    }
  },

  setHSPick({ commit }, pick) {
    commit('SET_HS_PICK', pick);
  },

  fetchTournamentRegister({ state, dispatch }, { hash }) {
    return api
      .post(`/tournament/${state.tournamentId}/registration`, {
        hash,
      })
      .then(async (result) => {
        await dispatch(
          'profile/fetchProfileTournamentsStatus',
          {},
          { root: true },
        );

        storage.remove(STORAGE_LAST_TOURNAMENT);

        return result;
      })
      .catch(({ error }) => {
        throw error;
      });
  },
};
