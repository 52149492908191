const images = (() => {
  if (process.env.NODE_ENV === 'test') {
    return [];
  }
  const files = require.context('../public/img/games', true);
  return [...new Set(files?.keys()?.map((key) => key.split('/')[1]))];
})();
const baseUrl = process.env.VUE_APP_BASE_URL.replace(/\/$/, '');

export const getGameImg = (gameCode, size) =>
  images.includes(gameCode)
    ? `${baseUrl}/img/games/${gameCode}/${size}.jpg`
    : `${baseUrl}/img/games/default/${size}.jpg`;
