<template>
  <div :class="classes">
    <div
      ref="slider"
      class="tabs-menu__wrapper"
      :class="{ 'align-left': alignLeft }"
    >
      <component
        :is="tag"
        v-for="(item, index) in list"
        :key="index"
        class="button"
        :class="checkActive(item, index)"
        @click="onTabChange(item, index)"
      >
        <slot name="tab" :tab="item">
          {{ item.title }}
        </slot>
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabsMenu',
  props: {
    list: {
      type: Array,
      required: true,
    },
    value: {
      type: [Number, String],
      default: -1,
    },
    tag: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    alignLeft: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    hammer: null,
  }),
  computed: {
    ...mapGetters('application', ['getLocaleRouteName']),
    classes() {
      return [
        'tabs-menu',
        {
          [`tabs-menu-${this.variant}`]: !!this.variant,
        },
      ];
    },
  },
  beforeDestroy() {
    this.hammer.destroy();
  },
  methods: {
    checkActive(item, index) {
      const active =
        this.value === index ||
        (item.link &&
          this.getLocaleRouteName(item.link.name) ===
            this.$route.name);
      return { active };
    },
    onTabChange(item, index) {
      if (item.link !== undefined) {
        this.$router.push(item.link).catch(() => ({}));
      }
      this.$emit('input', index, item.title);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-menu {
  text-align: center;

  &__wrapper {
    white-space: nowrap;
    overflow: auto;

    &:active {
      cursor: grabbing;
    }
  }

  &-primary {
    .button {
      padding: 12px;
      font-weight: bold;
      border-bottom: 5px solid transparent;
      color: rgba(white, 0.7);

      @media (hover: hover) {
        &:hover {
          border-color: rgba(#f5c93c, 0.5);
          color: rgba(white, 0.8);
        }
      }

      &.active {
        color: white;
        border-color: #f5c93c;
      }
    }
  }

  &-secondary {
    box-shadow: none;
    background-color: transparent;

    .tabs-menu__wrapper {
      @include min-laptop() {
        white-space: normal;
      }
    }

    .button {
      padding: 0;
      font-weight: normal;
      color: rgba(white, 0.4);

      &:not(:last-child) {
        margin-right: 15px;
      }

      @media (hover: hover) {
        &:hover {
          color: rgba(white, 0.6);
        }
      }

      &.active {
        color: white;
      }
    }
  }
}

.button {
  position: relative;
  text-decoration: none;
  display: inline-block;
  border: none;
  font-family: inherit;
  background: none;
  cursor: pointer;
  outline: none;
  font-size: 18px;

  &:focus {
    opacity: 0.8;
  }
}

.align-left {
  display: flex;
  justify-content: flex-start;
}
</style>
