<template>
  <div class="create-profile-form">
    <BaseForm @submit="save">
      <BaseInput
        v-if="canChangeEmail"
        v-model="email"
        label="Email"
        :required="true"
        :error-message.sync="errors.email"
        :placeholder="$t('players.email_placeholder')"
      />

      <BaseInput
        v-model="nickname"
        :label="$t('players.nickname_site')"
        :status="errors.nick ? 'error' : ''"
        :required="true"
        :message="errors.nick || $t('players.nickname_tooltip')"
        :placeholder="$t('players.nickname_placeholder')"
      />

      <InfoBox v-if="error.isVisible" variant="error">
        {{ error.text }}
      </InfoBox>

      <pvp-btn
        class="submit-btn"
        :is-loading="loadingState.registration"
        :disabled="loadingState.registration"
        type="submit"
      >
        {{ $t('save.single') }}
      </pvp-btn>
    </BaseForm>
  </div>
</template>

<script>
import { checkEmail } from '@utils/emails';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import { auth } from '@src/plugins/Auth';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import { useTermStore } from '@src/shared/store/useTermStore';

export default {
  name: 'CreateProfileForm',
  components: {
    BaseInput,
    BaseForm,
    InfoBox,
  },
  setup() {
    const termStore = useTermStore();
    termStore.fetchTerms({
      params: {
        type: ['forall', 'once'],
        require: 1,
      },
    });
    return { termStore };
  },
  data() {
    return {
      // State
      loadingState: {
        ...this.loadingState,
        registration: false,
      },

      // Data
      email: null,
      nickname: null,

      gender: null,

      firstName: null,
      lastName: null,

      countryId: null,
      city: null,

      birthDate: null,
      birthDateYearsRange: [1940, new Date().getFullYear()],

      termsAccepted: true,

      ids: [],

      // Errors
      errors: {},
    };
  },
  computed: {
    ...mapGetters('profile', [
      'getMailLogin',
      'getCurrentProfileSettings',
    ]),

    error() {
      const errors = Object.values(this.errors);
      return {
        isVisible: errors.length,
        text: errors.length
          ? errors.join('. ')
          : this.$t('save.error'),
      };
    },

    terms() {
      return this.termStore.terms;
    },

    canChangeEmail() {
      return this.getCurrentProfileSettings.canChangeEmail;
    },
  },
  created() {
    const login = this.getMailLogin;

    if (login && checkEmail(login)) {
      this.email = login;
      this.nickname = login.slice(0, login.indexOf('@'));
    }
  },
  methods: {
    ...mapActions('profile', ['registerProfile']),
    login() {
      auth.login();
    },

    save() {
      this.errors = {};

      this.loadingState.registration = true;

      this.registerProfile({
        nick: this.nickname,
        email: this.email,
        gender: this.gender,
        firstName: this.firstName,
        lastName: this.lastName,
        idCountry: this.countryId,
        city: this.city,
        birthdate: this.birthDate,
        terms: this.termsAccepted
          ? Object.values(this.terms).map(({ id }) => Number(id))
          : [],
      })
        .then(() => {
          this.$emit('registered');
        })
        .catch(({ error = [] }) => {
          this.errors = _.reduce(
            error,
            (acc, err, field) => ({
              ...acc,
              [field]: err.replace(/[&]quot[;]/giu, '"'),
            }),
            {},
          );
        })
        .finally(() => {
          this.loadingState.registration = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-profile-form {
  font-size: 14px;

  .form-controls {
    text-align: center;

    .button {
      @include min-tablet() {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
}

.checkbox {
  margin-top: 1em;
}

.radio-group {
  margin: 1em 0;

  .label {
    font-size: em(13px);
    color: rgba(white, 0.5);
  }

  .radio {
    display: inline-block;

    & + .radio {
      margin-left: 12px;
    }
  }
}

.submit-btn {
  margin: 30px auto 0;
  display: block;
}

.term {
  display: inline-flex;
  text-align: left;
  border-bottom: none;

  &:not(:last-of-type):after {
    content: ', ';
    color: white;
    text-align: left;
  }
}
</style>
