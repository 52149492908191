<template>
  <pvp-modal
    :value="isLocked"
    :width="560"
    class="locked"
    @input="logout"
  >
    <template #modal-title>{{ $t('profile.locked_title') }}</template>
    <div class="text">
      {{ $t('profile.locked_text') }}
    </div>
    <template #modal-footer>
      <pvp-btn @click="logout">
        {{ $t('global.close') }}
      </pvp-btn>
    </template>
  </pvp-modal>
</template>

<script>
import { auth } from '@src/plugins/Auth';

export default {
  name: 'ProfileLockedModal',
  computed: {
    ...mapState('profile', ['profile']),
    isLocked() {
      return Boolean(this.profile?.isLocked);
    },
  },
  methods: {
    logout() {
      auth.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.locked {
  text-align: center;
}
</style>
