<template>
  <div class="hub-checkin">
    <div class="hub-checkin__desktop">
      <Modal type="small">
        <CheckinWidget
          :title="title"
          :deadline="deadline"
          :participants="participants"
          :is-need-checkin="isNeedCheckin"
          :button-text="buttonText"
          @click="$emit('click')"
        />
      </Modal>
    </div>
    <div class="hub-checkin__mobile">
      <CheckinWidget
        :title="title"
        :deadline="deadline"
        :participants="participants"
        :is-need-checkin="isNeedCheckin"
        :button-text="buttonText"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>

<script>
import Modal from '@components/v2/Modal.vue';
import CheckinWidget from '@components/v2/hub/checkin/CheckinWidget.vue';

export default {
  name: 'HubCheckin',
  components: {
    Modal,
    CheckinWidget,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    deadline: {
      type: Number,
      required: true,
    },
    isNeedCheckin: {
      type: Boolean,
      required: true,
    },
    participants: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('matchmaking', ['getHubId', 'getStatus']),
  },
  beforeDestroy() {
    if (this.getStatus === 'lobby' || this.getStatus === 'match') {
      this.$router.push(`/hub/${this.getHubId}/match/new`);
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

::v-deep .window__button {
  display: none;
}

.hub-checkin__desktop {
  display: none;

  @include min-tablet() {
    display: block;
  }
}
.hub-checkin__mobile {
  display: block;
  padding: 24px 24px 40px;
  background: #2d2d30;
  border-radius: 24px 24px 0 0;
  border: 1px solid rgba(245, 245, 247, 0.12);
  position: fixed;
  z-index: 10000;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 95%;
  overflow: auto;

  @include min-tablet() {
    display: none;
  }
}
</style>
