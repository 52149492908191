<template>
  <div class="checkin-widget">
    <h2 class="checkin-widget__title">{{ title }}</h2>
    <div class="checkin-widget__timer">
      <div class="checkin-widget__spinner">
        <Icon name="ring-blue-gradient" group="color" :size="92" />
      </div>
      <div class="checkin-widget__time">
        <Timer :initial="deadline" countdown />
      </div>
    </div>
    <div v-if="isNeedCheckin" class="checkin-widget__controls">
      <Button
        :text="buttonText"
        size="big"
        type="primary"
        long
        @click="$emit('click')"
      />
    </div>
    <ul class="checkin-widget__checklist">
      <li
        v-for="participant in participants"
        :key="participant.id"
        class="checkin-widget__checklist-item"
        :class="getChecklistItemClass(participant.hasCheckedIn)"
      >
        <Icon name="check" :size="32" />
      </li>
    </ul>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import Timer from '@components/v2/Timer.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'CheckinWidget',
  components: {
    Button,
    Timer,
    Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    deadline: {
      type: Number,
      required: true,
    },
    isNeedCheckin: {
      type: Boolean,
      required: true,
    },
    participants: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.playSound();
  },
  methods: {
    getChecklistItemClass(isChecked) {
      return `checkin-widget__checklist-item--${
        isChecked ? 'checked' : 'default'
      }`;
    },
    playSound() {
      const audio = new Audio('/audio/alert.mp3');
      if (audio) {
        audio.volume = 0.3;
        audio.play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.checkin-widget__title {
  color: #f5f5f7;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 16px;
}
.checkin-widget__timer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #186cea;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 24px;
  position: relative;
}
.checkin-widget__spinner {
  width: 92px;
  height: 92px;
  animation: spin 1s infinite linear;
}
.checkin-widget__time {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.checkin-widget__controls {
  margin-bottom: 16px;
}
.checkin-widget__checklist {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;
}
.checkin-widget__checklist-item {
  list-style: none;

  &--default {
    color: #454548;
  }
  &--checked {
    color: #186cea;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
