import storage from '@utils/storage';
import cookies from '@utils/cookies';
import { auth } from '@src/plugins/Auth';
import { socket } from '@utils/socket';
import { updateUserProfile } from '@utils/updateUserProfile.js';

const STORAGE_LOGIN_ORIGIN = 'pvp.auth.loginOrigin';

const PVPGG_ORIGIN = 'pvpgg';

let profileLifetime = null;

export const state = {
  // Authentication data
  loginOrigin: storage.getItem(STORAGE_LOGIN_ORIGIN),

  // Profile data
  profile: {},
  profileDetails: {},

  linkedGamesAccounts: {},
  linkedGamesAccountsOrder: [],
  invites: {},
  showAdditionalRegistration: false,
  isWithoutGameAccountLinkModal: 'false',

  // hub
  hubUpdateKey: 0,
  updatedUserProfileMicrotime: 1,
};

export const getters = {
  getHubUpdateKey: (state) => state.hubUpdateKey,
  getIsUpdatedUserProfile: (state) =>
    state.updatedUserProfileMicrotime,
  getMailLogin: (state) => state.profile?.login,
  hasMailAccount: (state) => state.profile?.has_login,
  isLoggedIn: (state) => state.profile?.no_profile === false,
  getCurrentProfile: (state) => state.profile,
  getCurrentProfileHash: (state) => state.profile?.hash,
  getCurrentProfileSettings: (state) => state.profile?.abilities,
  getCurrentProfileDetails: (state) => state.profileDetails,

  // App
  platformTerms: (state) => state?.profileDetails?.terms || [],

  mustAcceptTerms: (state, getters) =>
    getters.hasMailAccount &&
    getters.isLoggedIn &&
    getters.platformTerms.length > 0,

  mustCreateProfile: (state, getters) =>
    getters.hasMailAccount &&
    !getters.isLoggedIn &&
    state.loginOrigin === PVPGG_ORIGIN,

  // Profile data
  getProfileLinkedGamesAccounts: (state, getters) =>
    state.linkedGamesAccountsOrder.map(getters.getLinkedGameAccount),
  getLinkedGameAccount: (state) => (id) =>
    state.linkedGamesAccounts[id] || undefined,
};

export const mutations = {
  SET_SHOW_ADDITIONAL_REGISTRATION(state, newValue) {
    state.showAdditionalRegistration = newValue;
  },
  SET_IS_WITHOUT_GAME_ACCOUNT_LINK_MODAL(state, newValue) {
    state.isWithoutGameAccountLinkModal = newValue;
  },
  SET_LOGIN_ORIGIN(state, newValue) {
    state.loginOrigin = newValue;

    storage.setItem(STORAGE_LOGIN_ORIGIN, newValue);
  },

  // hub
  SET_HUB_UPDATE_KEY(state, newValue) {
    state.hubUpdateKey = newValue;
  },

  // Profile data
  SET_PROFILE(state, profile) {
    state.profile = _.merge({}, state.profile, profile);
  },

  SET_PROFILE_DETAILS(state, details) {
    state.profileDetails = details;
  },

  SET_LINKED_GAMES_ACCOUNTS(state, accounts) {
    const accountsObject = accounts.reduce(
      (result, { gameId, ...account }) => ({
        ...result,
        [gameId]: {
          ...account,
          id: gameId,
          hasAccount: account.accountsList?.length > 0,
        },
      }),
      {},
    );

    state.linkedGamesAccountsOrder = Object.keys(accountsObject);
    state.linkedGamesAccounts = accountsObject;
  },

  SET_INVITES(state, invites) {
    state.invites = {
      ...state.invites,
      ...invites,
    };
  },

  SET_UPDATE_USER_PROFILE(state) {
    state.updatedUserProfileMicrotime += 1;
  },
};
/* eslint-disable */
export const actions = {
  fetchAuth({ state, commit, dispatch }, reload = false) {
    const loginParams = process.env.VUE_APP_IS_LOCAL
      ? {
          type: 'test',
          login: cookies.getItem('local_auth_cookie'),
        }
      : {};

    return api
      .postSimple('/auth/login', loginParams)
      .then((data) => {
        if (state.profile.token && !data.token) {
          return auth.logout({ accountChanged: true });
        }

        if (
          !state.profile.token &&
          state.loginOrigin !== PVPGG_ORIGIN
        ) {
          // before edit MAILRU_ORIGIN
          // remove MAILRU_ORIGIN, its checks browser storage, user auth on pvpgg or not
          commit('SET_LOGIN_ORIGIN', PVPGG_ORIGIN);
        }

        if (
          state.profile.no_profile === false &&
          state.profile.token
        ) {
          commit('SET_LOGIN_ORIGIN', null);
        }

        const {
          token,
          profile,
          profile_fields,
          canChangeEmail,
          canEditProfile,
          canSendConfirmToken,
          transferStatus,
          tokenLifetime,
          terms,
          ...oth
        } = data;

        const needUpdateProfile =
          state.profile.token !== token &&
          state.profile.login !== oth.login;

        if (terms?.length) {
          commit(
            'terms/SET_TERMS_FORALL',
            terms.map(({ id }) => id),
            { root: true },
          );
          terms.forEach((term) => {
            commit('terms/SET_TERM', term, { root: true });
          });
        }

        commit('SET_PROFILE', {
          ...oth,
          ...profile,
          token,
          transferStatus,
          abilities: {
            canChangeEmail,
            canEditProfile,
            canSendConfirmToken,
          },
        });

        clearTimeout(profileLifetime);

        if (token && transferStatus === 1) {
          profileLifetime = setTimeout(
            () => dispatch('fetchAuth'),
            (tokenLifetime - 10) * 1000,
          );
          if (needUpdateProfile) {
            Promise.all([
              dispatch('fetchCurrentProfile'),
              dispatch('fetchProfileGames'),
              dispatch('fetchProfileTournamentsStatus'),
            ]).then(() => {
              dispatch(
                'tournamentRegistration/reopenLastRegistration',
                {},
                { root: true },
              );
            });
          }

          socket.init(data.token);
        }

        updateUserProfile({
          data,
          profile,
          profileFields: profile_fields,
          reload,
        });

        return true;
      })
      .catch((error) => {
        const errorData = error?.response?.data;
        if (
          error?.response?.status === 401 &&
          errorData?.errorCode === 416
        ) {
          const params = { param: 'JSONP_call' };
          api.checkSdc(errorData?.sdcUrl, params).then(() => {
            dispatch('fetchAuth', true);
          });
        }
      });
  },

  setLoginOrigin({ commit }, origin) {
    commit('SET_LOGIN_ORIGIN', origin);
  },

  registerProfile(context, { terms, ...fields }) {
    return api.post('/profile/register', {
      fields,
      terms,
    });
  },

  fetchProfileUpdate({ commit }, fields = {}) {
    return api.post('/profile/update', { fields }).then((result) => {
      commit('SET_PROFILE', result.profile);
      return result;
    });
  },

  fetchCurrentProfile({ commit }) {
    return api
      .get('/profile/me')
      .then(
        ({
          canChangeEmail,
          invitingOrganizations,
          canEditProfile,
          canSendConfirmToken,
          canSyncFksAccount,
          profile,
          fksDetails,
          notificationCounters,
          terms,
        }) => {
          commit('SET_INVITES', {
            organizations: invitingOrganizations,
          });
          commit('notifications/SET_COUNTS', notificationCounters, {
            root: true,
          });
          if (notificationCounters?.unread === 0) {
            commit('notifications/MARK_NOTIFICATION_AS_READ', 'all', {
              root: true,
            });
          }

          commit('SET_PROFILE', {
            ...profile,
            abilities: {
              canChangeEmail,
              canEditProfile,
              canSendConfirmToken,
              canSyncFksAccount,
            },
          });

          commit('SET_PROFILE_DETAILS', {
            fcs: fksDetails,
            terms,
          });
        },
      );
  },

  fetchProfileTournamentsStatus({ dispatch }) {
    return api.get('/profile/tournament/my').then((tournaments) => {
      if (_.isArray(tournaments)) {
        dispatch(
          'tournaments/storeCurrentUserTournaments',
          tournaments,
          { root: true },
        );

        const teams = tournaments.reduce((teams, tnStatus) => {
          const participationInfo =
            tnStatus?.participationInfo?.participationInfo;

          if (participationInfo) {
            const { team, closestMatch } = participationInfo;

            if (team) {
              teams.push(team);
            }

            const enemyTeam = closestMatch?.team;

            if (enemyTeam) {
              teams.push(enemyTeam);
            }
          }

          return teams;
        }, []);

        dispatch('teams/storeTeams', teams, { root: true });
      }
    });
  },

  // Games data
  fetchProfileGames({ commit }) {
    return api.get('/profile/gameaccounts/list').then(({ list }) => {
      commit('SET_LINKED_GAMES_ACCOUNTS', list);
    });
  },

  fetchProfileTerms(context, params = {}) {
    return api.get('/profile/term/accepted', { params });
  },

  updateCurrentProfile({ commit, getters }, data) {
    commit('SET_PROFILE', {
      ...getters.getCurrentProfile,
      ...data,
    });
  },

  subscribeToProfileChannel({ state, commit, dispatch }) {
    api.get('/profile/channels').then(({ channels }) => {
      channels.forEach(({ name }) => {
        socket.subscribe(name, ({ data }) => {
          switch (data.event.type) {
            case 'hub.participant.stateChanged': {
              commit('SET_HUB_UPDATE_KEY', data.event.data.microtime);
              break;
            }
            case 'profileUpdated': {
              commit('SET_UPDATE_USER_PROFILE');
              dispatch('fetchCurrentProfile');
              break;
            }
            case 'player.notification': {
              dispatch(
                'notifications/addNotification',
                data.event.data,
                { root: true },
              );
              break;
            }

            case 'bracket.formed': {
              const id = data.event.data.tournamentId;
              dispatch('tournaments/fetchTournamentPage', id, {
                root: true,
              });
              dispatch(
                'tournamentSettings/fetchTournamentWorkflow',
                id,
                { root: true },
              );
              dispatch(
                'tournamentSettings/fetchTournamentAbilities',
                id,
                { root: true },
              );
              break;
            }

            case 'match.checkin':
              commit(
                'matches/SET_MATCH_CHECKIN_MODAL_ID',
                data.event.data.idMatch,
                { root: true },
              );
              break;

            case 'round.published':
            case 'round.dropped': {
              const id = data.event.data.tournamentId;
              dispatch(
                'tournamentSettings/fetchTournamentAbilities',
                id,
                { root: true },
              ).then(() => {
                dispatch('tournaments/fetchTournamentBracket', id, {
                  root: true,
                });
              });
              break;
            }

            case 'tournament.participant.confirmed':
            case 'registration.close': {
              const id = data.event.data.tournamentId;
              dispatch(
                'tournamentSettings/fetchTournamentAbilities',
                id,
                { root: true },
              );
              dispatch(
                'tournamentSettings/fetchTournamentWorkflow',
                id,
                { root: true },
              );
              dispatch('tournaments/fetchTournamentPage', id, {
                root: true,
              });
              break;
            }
            default:
          }
        });
      });
    });
  },

  showAdditionalRegistrationModal({ commit, getters }) {
    if (!getters.isLoggedIn && !getters.hasMailAccount) {
      return auth.login();
    }
    if (getters.mustCreateProfile) {
      commit('SET_SHOW_ADDITIONAL_REGISTRATION', true);
      return;
    }
  },
};
