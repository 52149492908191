import { createTournamentView } from '@viewModels/tournament/createTournamentView';
import { getGameImg } from '@src/gamesImages';

export function getTournamentSlide(state, getters, rootState) {
  return (id) => {
    const {
      userInfo,
      prizeInfo,
      countDown,
      tournament,
      baseTournament,
    } = createTournamentView(rootState, id);

    return {
      ...prizeInfo,
      ...baseTournament,
      countDown,
      userInfo,

      get bgImage() {
        return (
          tournament?.imgFeature ||
          getGameImg(this.gameCode || 'oth', 'wide')
        );
      },
    };
  };
}
