import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { dateFormats } from '@src/localization/dateFormats';

const supportedLanguages = {
  ru: {
    title: 'Русский',
    code: 'ru_RU',
  },
  en: {
    title: 'English',
    code: 'en_US',
  },
};

const dateTimeFormats = Object.keys(supportedLanguages).reduce(
  (result, key) => {
    result[key] = dateFormats;
    return result;
  },
  {},
);

VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
  if (this.locale !== 'ru') {
    // proceed to the default implementation
  }

  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (!teen && endsWithOne) {
    return 1;
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
};

Vue.use(VueI18n);

VueI18n.prototype.supportedLanguages = supportedLanguages;

const i18n = new VueI18n({
  fallbackLocale: 'ru',
  silentFallbackWarn: process.env.NODE_ENV === 'production',
  dateTimeFormats,
});

const unixDateTimeFormat = function (ts, name) {
  if (ts) {
    return i18n.d(new Date(ts * 1000), name);
  }
  return '';
};

VueI18n.prototype.dt = unixDateTimeFormat;
Vue.prototype.$dt = unixDateTimeFormat;

const translatePattern = (code) => {
  if (code?.translation?.pattern) {
    const [module, param] = Object.entries(
      code.translation.pattern,
    )?.[0];
    return i18n.t(
      `["${module}"]["${param}"]`,
      code?.translation?.patternParams || {},
    );
  }
  return code;
};

VueI18n.prototype.tp = translatePattern;
Vue.prototype.$tp = translatePattern;

export { i18n };
