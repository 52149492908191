import { i18n } from '@src/localization/config';

const secIn = {
  minutes: 60,
  hours: 3600,
  days: 86400,
};

export const formatDuration = (tsStart, tsEnd) => {
  if (_.isNull(tsStart) || _.isNull(tsEnd) || tsStart > tsEnd) {
    return {
      seconds: 0,
      minutes: 0,
      hours: 0,
      days: 0,
      asArray: ['~'],
    };
  }

  let diff = tsEnd - tsStart;
  const formatted = ['days', 'hours', 'minutes'].reduce(
    (result, item) => {
      const seconds = diff % secIn[item];
      const itemValue = (diff - seconds) / secIn[item];
      diff = seconds;
      result.seconds = seconds;
      if (itemValue) {
        result.asArray.push(
          i18n.tc(`date.${item}_pluralization`, itemValue),
        );
      }
      result[item] = itemValue;
      return result;
    },
    { asArray: [] },
  );

  formatted.asArray = formatted.asArray.length
    ? formatted.asArray
    : ['~'];
  return formatted;
};

export const leadingZero = (num = 0) => _.padStart(num, 2, 0);

export const isValidDate = (date) => {
  if (_.isNull(date) || _.isBoolean(date)) {
    return false;
  }
  return _.isNaN(new Date(date).getTime()) === false;
};
