import { render, staticRenderFns } from "./CheckinNotification.vue?vue&type=template&id=5730c046&scoped=true"
import script from "./CheckinNotification.vue?vue&type=script&lang=js"
export * from "./CheckinNotification.vue?vue&type=script&lang=js"
import style0 from "./CheckinNotification.vue?vue&type=style&index=0&id=5730c046&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5730c046",
  null
  
)

export default component.exports