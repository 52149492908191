<template>
  <label class="base-checkbox checkbox">
    <input
      class="input"
      type="checkbox"
      v-bind="$attrs"
      :disabled="disabled"
      :checked="checked"
      @change="onChange"
    />
    <span class="checkbox-icon">
      <Icon name="check" :size="16" />
    </span>
    <span class="label"><slot /></span>
  </label>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    Icon,
  },
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: true,
    },
    trueValue: {
      type: Boolean,
      default: true,
    },
    falseValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked() {
      if (this.isArr) {
        return this.value.some((item) =>
          _.isEqual(item, this.trueValue),
        );
      }
      return _.isEqual(this.trueValue, this.value);
    },
    isArr() {
      return _.isArray(this.value);
    },
  },
  methods: {
    onChange(event) {
      if (this.disabled) {
        return false;
      }

      let result = '';
      if (this.isArr) {
        const hasValue = this.value.some((item) =>
          _.isEqual(item, this.trueValue),
        );
        if (hasValue) {
          result = this.value.filter(
            (item) => !_.isEqual(item, this.trueValue),
          );
        } else {
          result = [...this.value, this.trueValue];
        }
      } else {
        result = this.checked ? this.falseValue : this.trueValue;
      }

      this.$emit('input', result);
      event.target.checked = this.checked;

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables.scss';
@import '../../../assets/scss/_mixins.scss';

.base-checkbox {
  display: flex;
  margin: 0;
  font-size: $pvp-control-font-size;

  .input {
    opacity: 0;
    position: fixed;
    top: -100px;
    left: -100px;
    z-index: -5;

    &:focus {
      & + .checkbox-icon {
        border: 1px solid rgba(white, 0.6);
      }
    }

    &:checked {
      & + .checkbox-icon {
        background-color: $azure;

        .icon {
          display: inline-block;
        }
      }
    }

    &:disabled {
      & + .checkbox-icon {
        background-color: rgba($azure, 0.6);

        .icon {
          color: rgba(white, 0.6);
        }
      }

      & ~ .label {
        color: rgba(white, 0.5);
      }
    }
  }

  .checkbox-icon {
    flex-shrink: 0;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid rgba(white, 0.3);
    line-height: 50%;
    text-align: center;
    position: relative;
    margin-top: 3px;
    margin-right: 8px;

    .icon {
      display: none;
      left: 20%;
      width: 60%;
      height: 100%;
      position: absolute;
    }
  }
}

.label {
  display: block;
  user-select: none;
}
</style>
