const gutter = 24;
const containerSize = {
  xs: 320,
  sm: 544,
  md: 744,
  lg: 970,
  xl: 1200,
};

const breakpoints = {
  xs: containerSize.xs + 2 * gutter,
  sm: containerSize.sm + gutter,
  md: containerSize.md + gutter,
  lg: containerSize.lg + 2 * gutter,
  xl: containerSize.xl + 2 * gutter,
};

module.exports = {
  gutter,
  breakpoints,
  containerSize,
  headerHeight: 72,
};
