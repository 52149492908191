<template>
  <img
    v-if="!error && src"
    class="user-avatar"
    :src="src"
    @error="onError"
  />
  <Icon v-else class="user-avatar" name="user" />
</template>

<script>
import { isNull, isString } from 'lodash';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    src: {
      required: true,
      validator: (value) => {
        return (
          isNull(value) || isString(value) || value === undefined
        );
      },
    },
  },

  data: () => ({
    error: false,
  }),

  methods: {
    onError() {
      this.error = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  border-radius: 100%;

  &.icon {
    background: #161516;
    color: #f3f3f3;
  }
}
</style>
