<template>
  <div v-if="country" class="country-tag">
    <country-flag v-if="!onlyName" :code="country.code" />
    <span v-if="!onlyFlag" class="name">{{ country.name }}</span>
  </div>
</template>

<script>
import CountryFlag from '@components/BaseComponents/Country/CountryFlag';

export default {
  components: { CountryFlag },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    onlyFlag: {
      type: Boolean,
      default: false,
    },
    onlyName: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('application', ['getCountry']),

    country() {
      return this.getCountry(this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.country-tag {
  display: inline-flex;
  align-items: center;
}

.name {
  margin-left: em(10px);
}
</style>
