<template>
  <BaseSelect
    v-model="regionId"
    v-bind="$attrs"
    :readonly="appIsMobile"
    :options="regions"
    label-key="name"
    value-key="id"
    :placeholder="$t('players.region_select')"
  />
</template>

<script>
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  components: {
    BaseSelect,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters('application', ['getRegions', 'appIsMobile']),
    regionId: {
      get() {
        return this.value;
      },
      set(id) {
        this.$emit('input', id);
      },
    },
    regions() {
      return this.getRegions;
    },
  },
};
</script>
