export const state = {
  teams: {},
  teamsMembers: {},
  statuses: {},
};

export const getters = {
  getTeam: (state) => (hash) => {
    const team = state.teams[hash];

    if (!team) {
      return undefined;
    }
    const status = state.statuses[team.status];
    return {
      ...team,
      status,
    };
  },

  getTeamMembers: (state, getters, rootState) => (teamHash) => {
    const { users } = rootState.users;
    const teamMembers = state.teamsMembers[teamHash];
    return teamMembers
      ? teamMembers.map((relation) => ({
          ...users[relation.userHash],
          role: relation.role,
        }))
      : undefined;
  },

  getPlayerTeamsByGameId: (state) => ({ gameId }) => {
    const userTeams = Object.values(state.teams);
    return userTeams.filter((team) => team.idGame === gameId && team.isCaptain)
  }
};

export const mutations = {
  UPDATE_TEAMS(state, teams) {
    state.teams = {
      ...state.teams,
      ..._.keyBy(teams, 'hash'),
    };
  },

  UPDATE_TEAMS_MEMBERS(state, teamsMembers) {
    state.teamsMembers = {
      ...state.teamsMembers,
      ...teamsMembers,
    };
  },

  // TODO: переписать на Vue.set
  CHANGE_TEAM_MEMBER_ROLE(state, { teamHash, userHash }) {
    const role =
      state.teamsMembers[teamHash].find(
        (member) => member.userHash === userHash,
      ).role === 'spare'
        ? 'player'
        : 'spare';
    state.teamsMembers[teamHash].find(
      (member) => member.userHash === userHash,
    ).role = role;
    state.teamsMembers = {
      ...state.teamsMembers,
      [teamHash]: state.teamsMembers[teamHash],
    };
  },

  // TODO: переписать на Vue.set
  CHANGE_CAPTAIN_ROLE(
    state,
    { teamHash, userHash, actualCaptainHash },
  ) {
    state.teamsMembers[teamHash].find(
      (member) => member.userHash === actualCaptainHash,
    ).role = 'player';
    state.teamsMembers[teamHash].find(
      (member) => member.userHash === userHash,
    ).role = 'captain';
    state.teamsMembers = {
      ...state.teamsMembers,
      [teamHash]: state.teamsMembers[teamHash],
    };
  },

  DELETE_TEAM_MEMBER(state, { teamHash, userHash }) {
    state.teamsMembers = {
      ...state.teamsMembers,
      [teamHash]: state.teamsMembers[teamHash].filter(
        (member) => member.userHash !== userHash,
      ),
    };
  },

  SET_STATUSES(state, statuses) {
    state.statuses = _.keyBy(statuses, 'id');
  },
};

export const actions = {
  storeTeam({ dispatch }, team) {
    return dispatch('storeTeams', [team]).then(_.head);
  },

  storeTeams({ commit, state }, teams) {
    teams = teams.map((team) => {
      // @TODO удалить после перехода на hash
      if (team.idTeam) {
        team.id = +team.idTeam;
      }
      const cachedTeam = state.teams[team.hash];
      return {
        ...cachedTeam,
        ...team,
        name: team.name || team.teamName,
        image: team.image || team.img || team.imgLogo,
      };
    });

    commit('UPDATE_TEAMS', teams);

    return teams.map((team) => state.teams[team.hash]);
  },

  fetchTeam({ dispatch, commit }, { hash, complex = false }) {
    const promises = [api.get(`/team/${hash}`)];

    if (complex) {
      promises.push(api.get(`/team/${hash}/complex`));
    }

    return Promise.all(promises).then(([team, complex]) => {
      const players = [];

      if (team?.players?.main?.length) {
        team.players.main.forEach((user) => {
          players.push({
            ...user,
            role: user.isCaptain ? 'captain' : 'player',
          });
        });
      }

      if (team?.players?.spare?.length) {
        team.players.spare.forEach((user) => {
          players.push({ ...user, role: 'spare' });
        });
      }

      dispatch('users/storeUsers', players, { root: true }).then(
        () => {
          commit('UPDATE_TEAMS_MEMBERS', {
            [hash]: players.map(({ hash: userHash, role }) => ({
              userHash,
              role,
            })),
          });
        },
      );

      return dispatch('storeTeam', {
        ...team.team,
        canEdit: team.canEditProfile,
        canEditMembers: team.canEditRoster,
        canEditAvatar: team.canEditAvatar,
        statistic: {
          matches: team.matchesStatistic,
          tournaments: team.lastTournamentsStatistic,
        },
        complex,
      });
    });
  },

  fetchTeamRatings(_, { page = 1, limit = 8, hash }) {
    return api
      .get(`/team/${hash}/ratings`, {
        params: {
          pageSize: limit,
          pageNum: page - 1,
        },
      })
      .then(async (data) => {
        const rating = data.items;
        return {
          rating,
          pagination: {
            page: data.pagen.pageNum,
            perPage: data.pagen.pageSize,
            total: data.pagen.totalCount,
            totalPages: data.pagen.pagesCount,
          },
        };
      });
  },

  fetchTeamMatches(
    { dispatch, commit },
    { page = 1, limit = 8, hash },
  ) {
    const mapTournament = (match) => ({
      id: match.tournamentId,
      name: match.tournamentName,
      urlCode: match.tournamentUrlCode,
      idGame: match.gameId,
      idSystem: match.isFfaSystem ? 6 : undefined,
    });

    const mapTeam = (team) => ({
      ...team,
      points: team?.scores || 0,
    });

    const mapMatch = (match) => {
      if (match.isFfaSystem) {
        return {
          ...match,
          name: match.ffaResult.matchName,
          round: { name: match.ffaResult.roundName },
          results: { place: match.ffaResult.place },
          id: match.matchId,
        };
      }

      return {
        ...match,
        hashWinner: match.teams[0].winnerHash,
        team1: mapTeam(match.teams[0]),
        team2: mapTeam(match.teams[1]),
        id: match.matchId,
      };
    };

    return api
      .post('/team/matches/list', {
        teamHash: hash,
        itemsPerPage: limit,
        pageNumber: page,
      })
      .then(async (data) => {
        const { matches, tournaments, teams } = data.items.reduce(
          (acc, match) => {
            const matchData = mapMatch(match);
            acc.tournaments.push(mapTournament(match));
            acc.matches.push(matchData);
            if (!match.isFfaSystem) {
              acc.teams.push(matchData.team1, matchData.team2);
            }
            return acc;
          },
          {
            tournaments: [],
            matches: [],
            teams: [],
          },
        );

        await dispatch('tournaments/storeTournaments', tournaments, {
          root: true,
        });
        await dispatch('storeTeams', teams);
        commit('matches/UPDATE_MATCHES', matches, { root: true });

        return {
          matches,
          pagination: {
            total: data.totalItems,
            totalPages: data.pagesCount,
          },
        };
      });
  },

  fetchTeamInvitation({ dispatch, commit }, hash) {
    return api.get(`/team/invitation/${hash}`).then((result) => {
      const players = result.members.map((member) => member.player);

      return Promise.all([
        dispatch('storeTeam', result.team),
        dispatch('users/storeUsers', players, { root: true }),
      ]).then(([team]) => {
        commit('UPDATE_TEAMS_MEMBERS', {
          [team.hash]: players.map(({ hash, role }) => ({
            userHash: hash,
            role,
          })),
        });
        return team;
      });
    });
  },

  createTeam(
    { dispatch },
    { name, size, gameId, countryId, imageId },
  ) {
    return api
      .post('/team/create', {
        form: {
          name,
          numMembers: size,
          idGame: gameId,
          idCountry: countryId,
          imgLogo: imageId,
        },
      })
      .then(async (result) => {
        const team = await dispatch('storeTeam', result.team);

        return {
          team,
          invitationHash: result.inviteHash,
        };
      });
  },

  updateTeamInfo(
    { dispatch },
    { hash, name, countryId, website, imageId },
  ) {
    return api
      .post(`/team/${hash}/update`, {
        form: {
          name,
          idCountry: countryId,
          website,
          imgLogo: imageId,
        },
      })
      .then((result) => dispatch('storeTeam', result.team));
  },

  changeCaptainRole(
    { commit },
    { teamHash, userHash, actualCaptainHash },
  ) {
    return api
      .update(`/team/${teamHash}/player/captain`, {
        hash: userHash,
      })
      .then((result) => {
        commit('CHANGE_CAPTAIN_ROLE', {
          teamHash,
          userHash,
          actualCaptainHash,
        });
        if (result.success) {
          window.location.reload();
        }
      })
      .catch(({ error }) => error);
  },

  changeMemberRole({ dispatch }, { teamHash, userHash }) {
    return api
      .update(`/team/${teamHash}/player/swap`, { hash: userHash })
      .then((result) => {
        if (result.success) {
          dispatch('fetchTeam', {
            hash: teamHash,
            complex: false,
          });
        }
      })
      .catch(({ error }) => error);
  },

  deleteTeamMember({ dispatch }, { teamHash, userHash }) {
    return api
      .delete(`/team/${teamHash}/player/delete`, { hash: userHash })
      .then((result) => {
        if (result.success) {
          dispatch('fetchTeam', {
            hash: teamHash,
            complex: false,
          });
          return true;
        }
        return false;
      });
  },

  getInvitationLink(_, hash) {
    return api.get(`team/${hash}/invitation`);
  },

  renewInvitationLink(_, hash) {
    return api.get(`team/${hash}/invitation/renew`);
  },

  deleteTeam(_, hash) {
    return api.post(`team/${hash}/delete`);
  },

  leaveTeamAction({ dispatch }, { teamHash, userHash }) {
    return api
      .update(`/team/${teamHash}/player/leave`)
      .then(async (result) => {
        if (result.success) {
          await dispatch(
            'users/fetchUserProfile',
            { hash: userHash, complex: true },
            { root: true },
          );
        }
        return result;
      });
  },
};
