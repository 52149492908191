<template>
  <header class="main-header">
    <nav class="main-header__menu">
      <template v-for="(link, index) in navigation">
        <a
          v-if="link.href"
          :key="index"
          :href="link.href"
          class="main-header__link"
          target="_blank"
          @click="menuClickHandler(link.title)"
        >
          {{ link.title }}
        </a>
        <router-link
          v-else
          :key="index"
          class="main-header__link"
          :to="{ name: link.name || 'not-found' }"
          @click.native="menuClickHandler(link.title)"
        >
          {{ link.title }}
        </router-link>
      </template>
    </nav>
  </header>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';

export default {
  name: 'MainHeader',
  computed: {
    navigation() {
      return [
        {
          title: this.$t('tournaments.few-vkplay'),
          name: 'tournaments',
        },
        {
          title: this.$t('jobfair.few-vkplay'),
          name: 'jobfair',
        },
        {
          title: this.$t('ratings.few'),
          name: 'ratings',
        },
        {
          title: this.$t('news.few-vkplay'),
          name: 'news',
        },
        {
          title: this.$t('video.few'),
          name: 'videos',
        },
        {
          title: this.$t('minigames.tournamentsPageTitle'),
          name: 'minigames',
        },
      ];
    },
  },

  methods: {
    menuClickHandler(menuItemTitle) {
      // ======================================================
      // METRICS Турниры. Клик по элементу шапки
      const tmrParams = {
        category: 'pvp',
        action: 'header_click',
        label: menuItemTitle,
        url: window.location.href,
      };
      pushEvents('pvp_header_click', tmrParams);
      // ======================================================
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.main-header {
  background-color: #000000;
  box-shadow: 0 2px 12px 0 rgba(black, 0.31),
    inset 0 -1px 0 0 rgba(white, 0.1);
  color: var(--ph-menu-item-color);
}

.main-header__menu {
  white-space: nowrap;
  overflow: auto;
  max-width: 1380px;
  width: 100%;
  margin: 0 auto;
  padding: 0 6px;

  @include min-tablet() {
    padding: 0 14px;
  }
}

.main-header__link {
  display: inline-block;
  padding: 8px 16px;
  color: var(--ph-menu-item-color);
  font-size: 16px;
  font-weight: 700;

  &:hover {
    text-decoration: none;
  }

  &:hover,
  &.router-link-active {
    color: var(--ph-text-color-lightest);
  }
}
</style>
