function filterTimeNumber(number) {
  const absNumber = Math.abs(number);
  return absNumber < 10 ? '0' + absNumber : absNumber;
}

export function getSeconds(secondsAmount) {
  const currentSeconds = Math.trunc(secondsAmount) % 60;
  return filterTimeNumber(currentSeconds);
}

export function getMinutes(secondsAmount) {
  const currentMinutes = Math.trunc(secondsAmount / 60) % 60;
  return filterTimeNumber(currentMinutes);
}

export function getHours(secondsAmount) {
  const currentHours = Math.trunc(secondsAmount / 60 / 60) % 24;
  return filterTimeNumber(currentHours);
}

export function getDays(secondsAmount) {
  const currentDays = Math.trunc(secondsAmount / 60 / 60 / 24);
  return filterTimeNumber(currentDays);
}

export function getRelativeDayString(amount, locale) {
  const relatimveTimeFormat = new Intl.RelativeTimeFormat(locale, {
    numeric: 'auto',
    style: 'long',
    localeMatcher: 'best fit',
  });

  return relatimveTimeFormat.format(amount, 'day');
}

export function getDateString(
  timestamp,
  locale,
  options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
) {
  const dateTimeFormat = new Intl.DateTimeFormat(locale, options);
  return dateTimeFormat.format(timestamp * 1000);
}
