<template>
  <div class="gems-footer" />
</template>

<script>
export default {
  name: 'GemsFooter',
  created() {
    this.init();
  },
  methods: {
    init() {
      window.__GEMS.elemFooter = '.gems-footer';
      const scriptFooter = document.createElement('script');
      scriptFooter.type = 'text/javascript';
      scriptFooter.async = true;
      scriptFooter.src =
        'https://vkplay.ru/hotbox/gem_static/leela/footer.js';
      document.querySelector('body').append(scriptFooter);
    },
  },
};
</script>
