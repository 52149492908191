<template>
  <div class="team-invitation-form">
    <template v-if="showTeamInfo">
      <div class="team-name">
        {{ $t('teams.single') }}
        <b>
          <BaseLink
            target="_blank"
            :to="{ name: 'team', params: { hash: team.hash } }"
            >{{ team.name }}
          </BaseLink>
        </b>
      </div>

      <div v-if="imageData?.fileUrl" class="team-image">
        <img :src="imageData.fileUrl" />
      </div>

      <div class="helper-text">{{ $t('teams.invite_hint') }}</div>
      <div
        v-if="expirationTime"
        class="helper-text"
        @click="renewHash"
      >
        {{ $t('teams.invite_timeout') }}
        <time-left :to="expirationTime" @timeout="renewHash" />
      </div>
    </template>

    <BaseInput
      ref="input"
      class="copy-input"
      :value="invitationLink"
      :label="$t('teams.invite_linkTitle')"
      :status="copied ? 'success' : ''"
      :message="copied ? $t('teams.invite_copied') : ''"
      :readonly="true"
      @click="onInputClick"
    >
      <template #after-input>
        <pvp-btn
          slot="append"
          variant="secondary"
          :block="appIsMobile"
          @click="copy"
          >{{ $t('global.copy') }}
        </pvp-btn>
      </template>
    </BaseInput>
  </div>
</template>

<script>
import { copyToClipboard } from '@utils/copyToClipboard';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import { fetchTeam } from '@src/shared/api/team';

export default {
  name: 'TeamInvitationForm',
  components: {
    BaseInput,
    BaseLink,
  },
  props: {
    invitationHash: {
      type: String,
      required: true,
    },
    invitationExpiration: {
      type: [String, Number],
      default: null,
    },
    hash: {
      type: String,
      required: true,
    },
    showTeamInfo: {
      type: Boolean,
      default: true,
    },
    imageData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      invitationLink: null,

      copied: false,
      team: null,
    };
  },
  computed: {
    ...mapGetters('application', ['appIsMobile']),

    expirationTime() {
      const time = this.invitationExpiration;
      return typeof time === 'string'
        ? new Date(time).getTime() / 1000
        : time;
    },
  },
  async mounted() {
    const path = this.$router.resolve({
      name: 'team-invitation',
      params: {
        hash: this.hash,
        invitationHash: this.invitationHash,
      },
    })?.href;
    this.invitationLink = `${window.location.origin}${path}`;

    const { team } = await fetchTeam(this.hash);
    this.team = team;
  },
  methods: {
    renewHash() {
      this.$emit('renew');
    },

    copy() {
      copyToClipboard(this.invitationLink);
      this.copied = true;
    },

    onInputClick(event) {
      return event?.target?.select();
    },
  },
};
</script>

<style lang="scss" scoped>
.team-invitation-form {
  font-size: 14px;

  .team-name {
    margin-bottom: 8px;
    text-align: center;
    font-size: 18px;
  }

  .team-image {
    margin-bottom: 20px;
    text-align: center;

    img {
      width: 130px;
      height: 130px;
      object-fit: cover;
      margin: 0 auto;
    }
  }

  .helper-text {
    margin-bottom: 20px;
    text-align: center;
  }
}

.copy-input ::v-deep .input-wrapper {
  @include min-tablet() {
    display: flex;
  }
  @include max-tablet() {
    .button {
      width: 100%;
    }
  }
}
</style>
