const getInput = (el) =>
  el.tagName === 'INPUT' ? el : el.querySelector('input');

const bind = (el, binding) => {
  const options = binding.value || {};
  const input = getInput(el);
  if (input) {
    import(/* webpackChunkName: "inputmask" */ 'inputmask').then(
      ({ default: Inputmask }) => {
        Inputmask(options).mask(input);
      },
    );
  }
};

const unbind = (el) => {
  const input = getInput(el);
  if (input?.inputmask) {
    input.inputmask.remove();
    if (!input.value) {
      input.inputmask = {};
    }
  }
};

export default {
  bind,
  unbind,
};
