<template>
  <div v-if="isVisible" class="gdpr">
    <div class="text">
      {{ $t('terms.gdpr') }}
      <BaseLink
        v-for="term in unAcceptedTerms"
        :key="term.id"
        class="link"
        :to="{ name: 'term', params: { id: term.code } }"
      >
        {{ term.title }}
      </BaseLink>
    </div>
    <pvp-btn @click="acceptTerms">{{ $t('global.accept') }}</pvp-btn>
  </div>
</template>

<script>
import storage from '@utils/storage';
import { initMetrics } from '@src/metrics';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'GdprModal',
  components: {
    BaseLink,
  },
  data: () => ({
    savedIds: [],
  }),
  computed: {
    ...mapState('terms', ['termsForAll']),
    ...mapGetters('terms', ['getTerm']),

    isVisible() {
      return this.termsForAll.some(
        (id) => !this.savedIds?.includes(id),
      );
    },

    unAcceptedTerms() {
      const filteredTerms = this.termsForAll.filter(
        ({ id }) => !this.savedIds?.includes(id),
      );
      return filteredTerms.map(this.getTerm);
    },
  },

  created() {
    this.savedIds = storage.getItem('gdpr_ids') || [];
    if (this.isVisible === false) {
      initMetrics(this.$router);
    }
  },

  methods: {
    acceptTerms() {
      storage.setItem('gdpr_ids', this.termsForAll);
      this.savedIds = this.termsForAll;
      initMetrics(this.$router);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.gdpr {
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  z-index: 5;
  margin: 0 auto;
  max-width: 1168px;
  border-radius: 2px;
  background-color: rgba(#121319, 0.95);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  @include min-tablet() {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
  }
  @include max-tablet() {
    padding: 12px 16px;
    text-align: center;
  }
}

.text {
  flex-grow: 1;
  font-size: 14px;
  text-align: center;
  color: rgba(white, 0.5);
  @include min-tablet() {
    padding-right: 10px;
  }

  .link:not(:last-of-type):after {
    content: ', ';
  }
}

.button {
  flex-shrink: 0;
  @include min-tablet() {
    width: 180px;
  }
  @include max-tablet() {
    margin-top: 12px;
    width: 100%;
  }
}
</style>
