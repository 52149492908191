<template>
  <div>
    <div v-if="showTitle" slot="title" class="social-links__title">
      <slot name="title">{{ $t('global.share') }}</slot>
    </div>
    <div class="social-links">
      <div class="social-link" @click.prevent.stop="share('vk')">
        <Icon name="vk" :size="32" />
      </div>
      <div class="social-link" @click.prevent.stop="share('ok')">
        <Icon name="ok" :size="32" />
      </div>
      <div v-if="hasCopyLink" class="social-link copy" @click="copy">
        <Icon name="copy" :size="32" />
        <span v-show="isCopied" class="copy-text"
          >{{ $t('global.copied') }}!</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from '@utils/copyToClipboard';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'BaseSocialLinks',
  components: {
    Icon,
  },
  props: {
    shareLink: {
      type: String,
      default: null,
    },
    shareTitle: {
      type: String,
      required: true,
    },
    shareImg: {
      type: String,
      required: false,
      default: '',
    },
    shareDescription: {
      type: String,
      required: false,
      default: '',
    },
    hasCopyLink: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isCopied: false,
  }),
  methods: {
    share(type) {
      const image = this.shareImg || '';
      const description = this.shareDescription || '';
      const mainLink =
        this.shareLink || encodeURIComponent(window.location.href);
      const imageForSharing = encodeURIComponent(image);
      const descriptionForSharing = encodeURIComponent(description);
      const titleForSharing = encodeURIComponent(this.shareTitle);
      let url;
      if (type === 'vk') {
        url = [
          ['http://vkontakte.ru/share.php?'],
          ['url', mainLink],
          ['title', titleForSharing],
          ['description', descriptionForSharing],
          ['image', imageForSharing],
          ['noparse', true],
        ]
          .map((i) => i.join('='))
          .join('&');
      } else if (type === 'ok') {
        url = [
          ['https://connect.ok.ru/offer?'],
          ['url', mainLink],
          ['title', titleForSharing],
          ['description', descriptionForSharing],
          ['imageUrl', imageForSharing],
        ]
          .map((i) => i.join('='))
          .join('&');
      }
      this.popup(url);
    },

    popup(url) {
      window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
    },

    copy() {
      copyToClipboard(window.location.href);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.social-links__title {
  margin-bottom: 12px;
  font-size: 13px;
  opacity: 0.45;
}

.social-links {
  display: flex;

  .social-link {
    cursor: pointer;
    font-size: 35px;
    color: rgba(white, 0.5);
    line-height: 1;

    &:hover {
      color: rgba(white, 1);
    }
  }

  .social-link + .social-link {
    margin-left: 10px;
  }
}

.copy {
  position: relative;

  &-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    height: 100%;
    padding: 0 12px;
    color: rgba(white, 0.6);
    background: rgba(black, 0.5);
    border-radius: 4px;
    margin-bottom: 8px;
    position: absolute;
    bottom: 100%;
    right: 0;
  }
}
</style>
