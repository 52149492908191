<template>
  <profile-transfer
    v-if="isTransferPopupOpen"
    :transfer-term="transferTerm"
  />
  <pvp-modal
    v-else-if="mustAcceptTerms"
    :value="true"
    :permanent="true"
    width="500"
  >
    <div slot="modal-title">
      {{ $t('terms.agreements_user_single') }}
    </div>

    <i18n tag="pvp-info-box" path="terms.accept_changes_text">
      <b slot="agreement">{{
        $t('terms.accept_changes_agreement')
      }}</b>
      <b slot="privacy">{{ $t('terms.accept_changes_privacy') }}</b>
    </i18n>

    <terms-accept-form
      :terms="platformTerms"
      :is-loading="loadingState.terms"
      @accepted="onTermsAccepted"
    />
  </pvp-modal>
</template>

<script>
import ProfileTransfer from '@components/ProfileComponents/ProfileTransfer.vue';
import TermsAcceptForm from './TermsAcceptForm.vue';
import {
  STATUS_TRANSFER_DEFAULT,
  STATUS_TRANSFER_DECLINE,
} from '@src/const/statusTransferAccount.js';

export default {
  name: 'PlatformTermsModal',
  components: {
    ProfileTransfer,
    TermsAcceptForm,
  },
  data: () => ({
    loadingState: {
      terms: false,
    },
  }),
  computed: {
    ...mapState('profile', ['profile']),
    ...mapGetters('profile', ['mustAcceptTerms', 'platformTerms']),

    isTransferPopupOpen() {
      return (
        this.profile?.token &&
        [STATUS_TRANSFER_DEFAULT, STATUS_TRANSFER_DECLINE].includes(
          this.profile?.transferStatus,
        )
      );
    },

    transferTerm() {
      return this.profile?.transferTerm;
    },
  },
  methods: {
    ...mapActions('profile', ['fetchCurrentProfile']),
    ...mapActions('tournamentRegistration', [
      'reopenLastRegistration',
    ]),
    onTermsAccepted() {
      this.loadingState.terms = true;

      this.fetchCurrentProfile().finally(() => {
        this.loadingState.terms = false;
        this.reopenLastRegistration();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-accept-form {
  margin-top: 20px;
}
</style>
