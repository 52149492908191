export const state = {
  banners: {},
  hasAdvBanner: false,
};

export const getters = {};

export const mutations = {
  UPDATE_BANNER(state, banner) {
    state.banners = {
      ...state.banners,
      [banner.id]: {
        ...state?.banners?.[banner.id],
        ...banner,
      },
    };
  },
  UPDATE_BANNERS(state, banners) {
    const mergedbanners = banners.reduce((obj, banner) => {
      obj[banner.id] = _.merge({}, state.banners[banner.id], banner);
      return obj;
    }, {});
    state.banners = {
      ...state.banners,
      ...mergedbanners,
    };
  },
  UPDATE_HAS_ADV_BANNER(state, payload) {
    state.hasAdvBanner = payload;
  },
};

export const actions = {
  storeBanner({ commit }, banner) {
    commit('UPDATE_banner', banner);
    return banner.id;
  },
  storeBanners({ state, commit }, banners) {
    commit('UPDATE_bannerS', banners);
    return banners.map((banner) => state.banners[banner.id]);
  },
};
