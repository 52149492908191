<template>
  <pvp-modal
    :value="termStore.termModalOpened"
    width="800"
    @input="termStore.closeTermModal"
  >
    <div slot="modal-title">
      {{ $t('terms.agreements_user_single') }}
    </div>
    <template v-if="termStore.currentTerm">
      <div class="title">{{ termStore.currentTerm.title }}</div>
      <!-- eslint-disable vue/no-v-html -->
      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
      <wysiwyg-viewer v-html="termStore.currentTerm.text" />
      <pvp-btn
        slot="modal-footer"
        variant="secondary"
        @click="termStore.closeTermModal"
        >{{ $t('global.close') }}
      </pvp-btn>
    </template>
    <loader v-else />
  </pvp-modal>
</template>

<script>
import WysiwygViewer from '@components/Wysiwyg/WysiwygViewer.vue';
import { useTermStore } from '@src/shared/store/useTermStore';

export default {
  name: 'TermsModal',
  components: { WysiwygViewer },
  setup() {
    const termStore = useTermStore();
    return { termStore };
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid rgba(white, 0.1);
  padding-bottom: 16px;
  margin-bottom: 20px;
}

.loader {
  margin: 100px auto;
}

.button {
  margin: 24px auto 0;
  display: block;
}
</style>
