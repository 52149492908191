<template>
  <pvp-btn
    variant="clear"
    class="action-link"
    @click="termStore.openTermModal({ code })"
  >
    <slot />
  </pvp-btn>
</template>

<script>
import { useTermStore } from '@src/shared/store/useTermStore';

export default {
  name: 'TermLink',

  setup() {
    const termStore = useTermStore();
    return { termStore };
  },

  props: {
    code: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>
