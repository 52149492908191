<template>
  <router-link
    :class="classes"
    :to="to"
    :target="isNewTab ? '_blank' : ''"
    @click.native="$emit('click-native')"
  >
    <Icon v-if="iconLeft" class="icon-left" :name="iconLeft" inline />
    <slot />
    <Icon
      v-if="iconRight"
      class="icon-right"
      :name="iconRight"
      inline
    />
  </router-link>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'BaseLink',
  components: {
    Icon,
  },
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    isNewTab: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      let cls = 'base-link';
      if (this.iconLeft || this.iconRight) {
        cls += ' with-icon';
      }
      return cls;
    },
  },
};
</script>

<style lang="scss">
.with-icon {
  display: inline-flex;
  align-items: center;

  .icon-left {
    margin-right: em(6px);
    flex-shrink: 0;
  }

  .icon-right {
    flex-shrink: 0;
    margin-left: em(6px);
    margin-bottom: -2px;
  }
}
</style>
