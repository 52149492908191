<template>
  <div class="premium-banner">
    <icon class="crown-icon" name="crown" />
    <div
      v-if="needPremium || !hasMailAccount"
      class="premium-banner-text"
    >
      {{ $t('premium.banner') }}
      <a
        class="premium-link"
        href="https://bundle.games.mail.ru"
        target="_blank"
        >{{ $t('global.checkout') }}</a
      >
    </div>
    <div v-else class="premium-banner-text">
      {{ $t('premium.hasPremium') }}
      <a
        class="premium-link"
        href="https://bundle.games.mail.ru"
        target="_blank"
        >{{ $t('global.more') }}</a
      >
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'PremiumBanner',
  components: {
    Icon,
  },
  props: {
    needPremium: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('profile', ['hasMailAccount']),
  },
};
</script>

<style scoped lang="scss">
.premium-banner {
  display: flex;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 6px;
  background-image: linear-gradient(88deg, #6836cd, #3633ba);
}

.crown-icon {
  font-size: 18px;
  flex-shrink: 0;
}

.premium-banner-text {
  margin-left: 8px;
  font-size: 14px;
  line-height: normal;
  color: #fff;
  @include min-tablet() {
    display: flex;
    align-items: center;
  }
}

.premium-link {
  font-size: 17px;
  color: $sky-blue;
  @include min-tablet() {
    margin-left: 18px;
    line-height: 1;
  }
  @include max-tablet() {
    display: block;
  }
}
</style>
