import Centrifuge from 'centrifuge';
import store from '@state/store';

export const socket = new (class {
  constructor() {
    this.instance = null;
    this.authToken = null;
    this.beforeInitSubscriptions = [];
  }

  init(authToken) {
    this.authToken = authToken;
    if (_.isNull(this.instance)) {
      api
        .get('/push/params')
        .then(({ params }) => this.updateSocketInstance(params));
    }
  }

  updateSocketInstance(params) {
    const { url, token, refreshEndpoint, subscribeEndpoint } =
      params || {};

    if (!token) {
      return;
    }

    const headers = {
      Authorization: `Bearer ${this.authToken}`,
    };

    this.instance = new Centrifuge(url, {
      subscribeEndpoint,
      subscribeHeaders: headers,
      onRefresh(context, callback) {
        store.dispatch('profile/fetchAuth').then(() => {
          api
            .post(
              refreshEndpoint,
              {},
              {
                headers: {
                  Authorization: `Bearer ${this.authToken}`,
                },
              },
            )
            .then((data) => {
              callback({
                data,
                status: 200,
              });
            });
        });
      },
    });

    this.instance.setToken(token);

    if (process.env.NODE_ENV === 'development') {
      this.instance
        .on('connect', (context) => {
          // eslint-disable-next-line
          console.log('Centrifuge connected', context);
        })
        .on('disconnect', async (context) => {
          // eslint-disable-next-line
          console.log('Centrifuge disconnected', context);
        })
        .on('error', (error) => {
          // eslint-disable-next-line
          console.error('Centrifuge error', error);
        });
    }

    if (this.beforeInitSubscriptions.length > 0) {
      this.beforeInitSubscriptions.forEach(({ event, callback }) =>
        this.subscribe(event, callback),
      );
      this.beforeInitSubscriptions.length = 0;
    }
    this.instance.connect();
  }

  subscribe(event, callback) {
    if (this.instance === null) {
      this.beforeInitSubscriptions.push({ event, callback });
    } else {
      this.instance.subscribe(event, callback);
    }
  }
})();
