/* eslint-disable */

const formatters = {
  ru: (input) => {
    let phone = input.replace(/[\D]/g, '');

    if (
      phone.length === 11 &&
      ['7', '8'].includes(phone.slice(0, 1))
    ) {
      phone = phone.replace(/^[87]/g, '');
    }

    if (phone.length === 10) {
      phone = phone.replace(
        /(\d{3})([\d]{3})([\d]{2})([\d]{2})/g,
        '+7 ($1) $2-$3-$4',
      );
    }

    return phone;
  },
  default: (input) => input,
};

export const formatPhone = (input, type = 'ru') => {
  if (!formatters[type]) {
    type = 'default';
  }

  return formatters[type](input);
};
