<template>
  <BaseForm
    :validate="beforeSubmit"
    class="simple"
    @submit="linkAccount"
  >
    <slot name="title" />
    <BaseInput
      v-model="nickname"
      :status="status"
      :placeholder="$t('players.nickname_game_label')"
      :maxlength="game.constraints.maxLength"
      @input="updateNickname"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        slot="after-input"
        :class="['message', status]"
        v-html="game.constraints.hint"
      ></div>
    </BaseInput>

    <slot name="error" />
    <pvp-btn type="submit">
      {{ $t('games.bind_single') }}
    </pvp-btn>
  </BaseForm>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';

export default {
  name: 'PubgAccount',
  components: {
    BaseInput,
    BaseForm,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    nickname: '',
    status: '',
  }),
  computed: {
    ...mapGetters('application', ['getGame']),
    ...mapGetters('profile', ['getLinkedGameAccount']),

    game() {
      return this.getGame(this.id);
    },
    gameAccount() {
      return this.getLinkedGameAccount(this.id);
    },
  },
  created() {
    this.nickname = this.gameAccount?.accountsList?.[0]?.login;
  },
  methods: {
    beforeSubmit() {
      const nickname = this.nickname?.trim() || '';
      if (
        nickname.length === 0 ||
        nickname.length < this.game.constraints.minLength ||
        nickname.length > this.game.constraints.maxLength
      ) {
        this.status = 'error';
        return false;
      }
      return true;
    },

    updateNickname() {
      this.status = '';
      this.$emit('error', '');
    },

    linkAccount() {
      this.$emit('loading', true);

      api
        .post('/profile/game/pubglink', {
          nickName: this.nickname,
        })
        .then((data) => {
          this.$emit('success', data);
        })
        .catch(({ error }) => {
          this.$emit('error', error);
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.simple {
  text-align: center;

  .label {
    margin-top: 12px;
  }
}

.button {
  margin-top: 12px;
}
</style>
