<template>
  <loader v-if="isLoading" />

  <InfoBox v-else-if="error.code" variant="error">
    <p v-if="error.code === 'fks_account_locked'">
      <i18n path="fksapi.programm_error">
        <a
          slot="name"
          href="https://resf.ru/membership/about/"
          target="_blank"
        >
          {{ $t('fksapi.programm') }}
        </a>
      </i18n>
    </p>
    <p v-else-if="error.code === 'discord_is_required_for_captain'">
      <i18n
        path='["bl.tournament.registration"]["error.discord_is_required_for_captain"]'
      >
        <BaseLink
          slot="link"
          :to="{ path: '/profile/edit', hash: '#contacts' }"
        >
          {{
            $t(
              '["bl.tournament.registration"]["error.discord_is_required_for_captain_link"]',
            )
          }}
        </BaseLink>
      </i18n>
    </p>
    <p v-else-if="error.text">{{ error.text }}</p>

    <template v-if="error.details.errors.length">
      {{ $t('registration.errors_list') }}
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-for="(error, index) in error.details.errors"
        :key="index"
        v-html="error"
      ></div>
    </template>

    <template v-if="error.details.players.length">
      <template v-if="error.code === 'team_members_intersected'">
        <i18n path="registration.errors_team">
          <b slot="name">
            <BaseTeamLink :hash="teamHash" :display-image="false" />
          </b>
        </i18n>
        {{ $t('registration.errors_otherTeam') }}:
      </template>
      <template v-else>
        {{ $t('registration.errors_players') }}
      </template>
      <ul>
        <li
          v-for="player in error.details.players"
          :key="player.hash"
        >
          {{ $t('players.single') }}
          <BaseLink
            :to="{ name: 'profile', params: { hash: player.hash } }"
          >
            {{ player.nick }}
          </BaseLink>
          <template v-if="error.code === 'players_banned'">
            {{ $t('registration.errors_participation') }}
            <template v-if="player.banReason">
              {{ $t('matches.complain_reason') }}: «{{
                player.banReason
              }}».
            </template>
            <template v-if="player.banUntil">
              {{
                $t('registration.errors_ban', {
                  date: $dt(player.banUntil, 'dd_MM_YYYY'),
                  time: $dt(player.banUntil, 'HH_mm'),
                })
              }}
            </template>
          </template>
          <div
            v-for="(error, index) in player.errors"
            :key="index"
            v-html="error"
          ></div>
        </li>
      </ul>
    </template>
  </InfoBox>

  <div v-else class="success">
    <icon name="check-circle" />
    <pvp-btn
      v-if="tournament"
      variant="link"
      class="tournament-link"
      @click="onTournamnetLinkClick"
    >
      {{ tournament.name }}
    </pvp-btn>
    <BaseSocialLinks
      v-if="share"
      :share-link="share.link"
      :share-title="share.title"
      class="social"
    >
      <template slot="title">{{ $t('registration.share') }}</template>
    </BaseSocialLinks>
  </div>
</template>

<script>
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import BaseSocialLinks from '@components/BaseComponents/BaseSocialLinks.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'RegistrationFinish',
  components: {
    BaseTeamLink,
    BaseSocialLinks,
    Icon,
    BaseLink,
    InfoBox,
  },
  props: {
    tournamentId: {
      type: [Number, String],
      required: true,
    },
    teamHash: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isLoading: true,
    error: {
      code: '',
      text: '',
      details: {},
    },
  }),
  computed: {
    ...mapGetters('tournaments', ['getTournament']),

    tournament() {
      return this.getTournament(this.tournamentId);
    },

    share() {
      return {
        link: `${window.location.origin}/tournament/${this.tournament?.id}`,
        title: this.$t('registration.shareTitle', {
          name: this.tournament?.name,
        }),
      };
    },
  },
  created() {
    this.fetchTournamentRegister({ hash: this.teamHash })
      .then(() => {
        this.$emit('success');
      })
      .catch(({ code, text, data } = {}) => {
        const players = Object.values(
          _.merge(
            {},
            _.keyBy(data?.players || [], 'hash'),
            _.keyBy(data?.playersErrors || [], 'hash'),
          ),
        );

        this.error = {
          code,
          text: code === 'team_members_intersected' ? '' : text,
          details: {
            players,
            errors: data.errors || [],
          },
        };
        this.$emit('error', this.error);
      })
      .finally(() => {
        this.isLoading = false;
        this.fetchProfileTournamentsStatus();
      });
  },
  methods: {
    ...mapActions('tournamentRegistration', [
      'fetchTournamentRegister',
    ]),
    ...mapActions('profile', ['fetchProfileTournamentsStatus']),
    onTournamnetLinkClick() {
      const id = this.tournament?.urlCode || this.tournament?.id;

      if (this.$route.params.id !== id) {
        this.$router.push({
          name: 'tournament',
          params: { id },
        });
      }

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.success {
  text-align: center;

  .icon {
    font-size: 30px;
    color: $dark-pastel-green;
  }

  .tournament-link {
    font-weight: bold;
    display: block;
    font-size: 16px;
    margin: 20px auto 0;
  }

  ::v-deep .social-links {
    justify-content: center;
  }
}
</style>
