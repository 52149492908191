<template>
  <form class="pvp-form" novalidate v-on="listeners">
    <slot />
  </form>
</template>

<script>
export default {
  name: 'PvpForm',
  props: {
    validate: {
      type: Function,
      default: () => true,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        submit: (event) => {
          event.preventDefault();
          if (this.validate()) {
            this.$emit('submit', event);
          }
        },
      };
    },
  },
};
</script>
