<template>
  <button class="hub-matchmaking-status" @click="handleButtonClick">
    <div class="hub-matchmaking-status__main">
      <div class="hub-matchmaking-status__title">{{ title }}</div>
      <div class="hub-matchmaking-status__time">
        <Timer v-if="startTime" :initial="startTime" />
      </div>
    </div>
    <div v-if="isSearch" class="hub-matchmaking-status__icon">
      <div class="hub-matchmaking-status__spin">
        <Icon name="ring-white-gradient" group="color" :size="32" />
      </div>
      <div class="hub-matchmaking-status__x">
        <Icon name="x" :size="16" />
      </div>
    </div>
  </button>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import Timer from '@components/v2/Timer.vue';

export default {
  name: 'HubMatchmakingStatus',
  components: { Icon, Timer },
  props: {
    title: {
      type: String,
      required: true,
    },
    startTime: {
      type: Number,
      required: true,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleButtonClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.hub-matchmaking-status {
  min-width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  border-radius: 16px;
  color: #f5f5f7;
  font-weight: 600;
  background: linear-gradient(0deg, #07f 0%, #c8e2ff 173.61%);

  @include min-tablet() {
    min-width: 200px;
    padding: 12px 16px;
  }
}
.hub-matchmaking-status__title {
  letter-spacing: 0.3px;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;

  @include min-tablet() {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 6px;
  }
  @include min-desktop() {
    font-size: 22px;
    line-height: 26px;
  }
}
.hub-matchmaking-status__time {
  min-height: 22px;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  margin-left: -2px;

  @include min-desktop() {
    font-size: 18px;
    line-height: 22px;
  }
}
.hub-matchmaking-status__icon {
  position: relative;
}
.hub-matchmaking-status__x {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hub-matchmaking-status__spin {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
