import { formatDuration } from '@utils/dates';
import { createTournamentView } from '@viewModels/tournament/createTournamentView';
import { i18n } from '@src/localization/config';

export function getTournamentTable(state, getters, rootState) {
  return (id) => {
    const {
      format,
      userInfo,
      prizeInfo,
      statusCode,
      systemCode,
      tournament,
      hasAntiCheat,
      countdownDate,
      baseTournament,
      participantsCount,
      participantsCountStatistic,
      isHub,
    } = createTournamentView(rootState, id);

    return {
      ...prizeInfo,
      ...baseTournament,
      participantsCount,
      participantsCountStatistic,
      hasAntiCheat,
      statusCode,
      userInfo,
      format,
      isHub,

      isHubMatchMakingEnabled: tournament?.isHubMatchMakingEnabled,
      tsStartRun: tournament?.tsStartRun,
      needCheckIn: tournament?.needCheckIn,
      numTeamMembers: tournament?.numTeamMembers,
      isInRecommendedList: tournament?.isInRecommendedList,
      participationResult: tournament?.participationResult,

      get needCheck() {
        return (
          userInfo.status === 'registered' && tournament?.needCheckIn
        );
      },

      get duration() {
        return formatDuration(this.tsStartRun, tournament?.tsEndRun)
          .asArray;
      },

      get timedOut() {
        return (
          countdownDate &&
          countdownDate < rootState?.application?.timestamp
        );
      },

      get stateInfo() {
        const result = {
          type: '',
          text: '',
          canConfirm: false,
          canSearchMatch: false,
          canRegister: userInfo.canRegister,
        };

        const isTournamentStopped = [
          'finished',
          'cancelled',
        ].includes(statusCode);
        const isTournamentExecuting =
          statusCode === 'executing' && this.timedOut;

        if (
          !userInfo.isParticipant ||
          isTournamentStopped ||
          isTournamentExecuting
        ) {
          return result;
        }

        switch (userInfo.status) {
          case 'registered': {
            if (userInfo.canConfirm) {
              result.text = i18n.t('participants.status_needConfirm');
              result.type = 'warning';
              result.canConfirm = Boolean(
                userInfo.canConfirm && userInfo.team?.isCaptain,
              );
            } else {
              result.text = i18n.t('participants.status_registered');
              result.type = 'success';
            }
            break;
          }
          case 'confirmed':
          case 'selected':
            if (userInfo.executing) {
              result.text = i18n.t('participants.status_executing');
              result.canSearchMatch = Boolean(
                systemCode === 'rating' && userInfo.team?.isCaptain,
              );
            } else {
              result.text = i18n.t(
                'participants.status_confirmedByUser',
              );
            }
            result.type = 'success';
            break;
          case 'excluded':
            result.text = i18n.t('participants.status_excluded');
            result.type = 'danger';
            break;
          default:
        }

        return result;
      },

      get statusInfo() {
        if (
          ['finished', 'cancelled', 'regclosed'].includes(statusCode)
        ) {
          return {
            text: {
              regclosed: i18n.t('tournaments.status_regclosed'),
              cancelled: i18n.t('tournaments.status_canceled'),
              finished: i18n.t('tournaments.status_completed'),
            }[statusCode],
          };
        }

        if (this.timedOut) {
          return {
            text:
              statusCode === 'executing' &&
              i18n.t('tournaments.status_calculatingResults'),
          };
        }

        return {
          text: {
            published: i18n.t(
              'tournaments.timeleftTo_registrationStart',
            ),
            regopen: i18n.t(
              `tournaments.timeleftTo_${
                this.needCheck
                  ? 'confirmationStart'
                  : 'registrationEnd'
              }`,
            ),
            confirmation: i18n.t(
              'tournaments.timeleftTo_confirmationEnd',
            ),
            preparation: i18n.t(
              `tournaments.timeleftTo_${
                this.needCheck ? 'confirmationEnd' : 'tournamentStart'
              }`,
            ),
            executing: i18n.t('tournaments.timeleftTo_shortEnd'),
          }[statusCode],
          to: countdownDate,
        };
      },
    };
  };
}
