import { CustomError } from '@utils/CustomError';

export const state = {
  csrf: null,
  form: {},
  abilities: {},
  workflow: {},
  // Personal Data
  fksPdEnabled: false,
  params: {
    'f[finished]': {
      id: 1,
      name: 'Выбрать',
    },
    'f[specialStatus]': {
      id: 0,
      name: 'Выбрать',
    },
    'f[teamsDefined]': {
      id: 1,
      name: 'Выбрать',
    },
    'f[rounds]': {
      id: 1,
    },
    'f[idMatch]': {
      id: 1,
    },
    c: {
      id: 3,
      name: 8,
      value: '8',
    },
    p: {
      id: 1,
      name: 1,
      value: '1',
    },
  },
  serverPools: [],
  hubSelectedByDefault: {},
};

export const getters = {
  getWorkflow: (state) => (id) => state.workflow?.[id],
  getFksPdEnabled: (state) => state.form.fksPdEnabled?.value,
  getFilterParams: (state) => state.params,
  getSelectedIndexFinished: (state) => state.selectedIndexFinished,
  getSelectedIndexStatus: (state) => state.selectedIndexStatus,
  getSelectedIndexTeamsDefined: (state) =>
    state.selectedIndexTeamsDefined,
  getSelectedIndexC: (state) => state.selectedIndexC,
  getIsHub: (state) => state.form?.idSystem?.value === 9,
};

export const mutations = {
  UPDATE_FORM(state, form) {
    state.form = {
      ...state.form,
      ...form,
    };
  },
  SET_CSRF(state, csrf) {
    state.csrf = csrf;
  },

  SET_ABILITIES(state, abilities) {
    state.abilities = abilities;
  },

  STORE_WORKFLOW(state, data) {
    state.workflow = {
      ...state.workflow,
      ...data,
    };
  },

  SET_FKS_PD_ENABLED(state, value) {
    state.fksPdEnabled = value;
  },

  SET_SERVER_POOLS(state, value) {
    state.serverPools = value;
  },

  SET_SELECT_FINISHED(state, value) {
    state.params['f[finished]'] = value;
  },

  SET_SELECT_STATUS(state, value) {
    state.params['f[specialStatus]'] = value;
  },

  SET_SELECT_TEAMS_DEFINED(state, value) {
    state.params['f[teamsDefined]'] = value;
  },

  SET_SELECT_ON_PAGE(state, value) {
    state.params['c'] = value;
  },

  SET_INPUT_ROUNDS(state, value) {
    state.params['f[rounds]'] = value;
  },

  SET_INPUT_ID_MATCH(state, value) {
    state.params['f[idMatch]'] = value;
  },

  SET_FILTER_CURRENT_PAGE(state, value) {
    state.params['p'] = value;
  },

  SET_DEFAULTS_FOR_HUB_SELECT(state, value) {
    state.hubSelectedByDefault = value;
  },
};

export const actions = {
  fetchTournamentFields({ commit }, id) {
    return api
      .get(`/tournament/${id}/fields`)
      .then(({ fields, csrf, tournament, serverPools, hub }) => {
        commit('SET_DEFAULTS_FOR_HUB_SELECT', hub?.selectedByDefault);
        commit('SET_SERVER_POOLS', serverPools);
        commit('SET_CSRF', csrf);
        const form = Object.entries(fields).reduce(
          (result, [key, data]) => {
            const value = (({ value, default: defaulValue }) => {
              if (
                _.isNull(value) ||
                (_.isArray(value) && value?.length === 0)
              ) {
                return defaulValue;
              }
              return value;
            })(data);

            result[key] = {
              ...data,
              value,
              defaultValue: value,
            };
            return result;
          },
          {},
        );

        const status = [
          'not_sended',
          'sended',
          'confirmed',
          'rejected',
        ][tournament.idPublishRequestStatus];

        form.publishing = {
          status,
          rejectReason: tournament.publishRejectReason,
        };
        commit('UPDATE_FORM', form);
      });
  },

  saveTournamentFields({ state, dispatch }, id) {
    const form = {};
    Object.entries(state.form).forEach(([name, { value, error }]) => {
      if (name) {
        form[name] = value;
        if (error) {
          dispatch('updateFormData', { name, error: '' });
        }
      }
    });
    return api
      .update(`/tournament/${id}/update`, {
        form,
        csrf: state.csrf,
      })
      .catch(({ error }) => {
        if (error?.common || _.isString(error)) {
          throw new CustomError(error?.common || error);
        }

        const errors = Object.keys(error);

        errors.forEach((name) => {
          const text = error?.[name];
          if (name !== 'common') {
            dispatch('updateFormData', {
              name,
              error: _.isArray(text) ? text.join(', ') : text,
            });
          }
        });

        throw new CustomError({
          transition: errors?.[0],
        });
      });
  },

  fetchTournamentAbilities({ commit }, id) {
    return api
      .get(`/tournament/${id}/abilities`)
      .then(({ abilities }) => {
        commit('SET_ABILITIES', abilities);
        return true;
      });
  },

  fetchTournamentWorkflow({ commit }, id) {
    return api
      .get(`/tournament/${id}/workflow/state`)
      .then((data) => {
        commit('STORE_WORKFLOW', {
          [id]: {
            ...data.workflow,
            transitions:
              data.workflow.enabledTransitions.map(
                ({ state }) => state,
              ) || [],
          },
        });
      })
      .catch(() => ({}));
  },

  updateFormData({ commit, state }, { name, ...oth }) {
    if (name) {
      commit('UPDATE_FORM', {
        [name]: { ...state.form[name], ...oth },
      });
    }
  },
};
