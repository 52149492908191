<template>
  <span class="timer">
    <template v-for="(timeValue, idx) in showOnFormat">
      <span :key="idx" class="timer__value">
        {{ timeValue }}
      </span>
      <span
        v-if="idx !== showOnFormat.length - 1"
        :key="idx + 'sep'"
        class="timer__separator"
      >
        :
      </span>
    </template>
  </span>
</template>

<script>
import {
  getSeconds,
  getMinutes,
  getHours,
  getDays,
} from '@utils/time.js';

export default {
  name: 'Timer',

  props: {
    initial: {
      type: Number,
      required: true,
    },
    countdown: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'mm:ss',
    },
  },

  data() {
    return {
      diff: 0,
      intervalId: null,
    };
  },

  computed: {
    days() {
      return getDays(this.diff);
    },
    hours() {
      return getHours(this.diff);
    },
    minutes() {
      return getMinutes(this.diff);
    },
    seconds() {
      return getSeconds(this.diff);
    },
    showOnFormat() {
      switch (this.format) {
        case 'dd:hh:mm':
          return [this.days, this.hours, this.minutes];
        case 'dd:hh:mm:ss':
          return [this.days, this.hours, this.minutes, this.seconds];
        case 'hh:mm:ss':
          return [this.hours, this.minutes, this.seconds];
        default:
          return [this.minutes, this.seconds];
      }
    },
    isTimeOut() {
      if (this.countdown) {
        return this.diff >= 0;
      }
      return false;
    },
  },

  created() {
    this.init();
  },

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    init() {
      this.initDiff();
      this.initTimer();
    },
    initDiff() {
      if (this.countdown) {
        this.initCountdownDiff();
      } else {
        this.initTimerDiff();
      }
    },
    initTimer() {
      if (this.isTimeOut) return;
      this.intervalId = setInterval(this.intervalCallback, 1000);
    },
    initTimerDiff() {
      this.diff = Math.floor(Date.now() / 1000) - this.initial;
    },
    initCountdownDiff() {
      const diff = Math.floor(Date.now() / 1000) - this.initial;
      this.diff = diff < 0 ? diff : 0;
    },

    intervalCallback() {
      if (this.isTimeOut) {
        clearInterval(this.intervalId);
        this.$emit('timeout');
        return;
      }
      const nowTimestamp = Math.floor(Date.now() / 1000);
      this.diff = nowTimestamp - this.initial;
    },
  },
};
</script>

<style scoped>
.timer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
.timer__value {
  min-width: 2ch;
}

.timer__separator {
  text-align: center;
}

.timer__value,
.timer__separator {
  display: block;
  font-size: 1em;
  line-height: 1em;
}
</style>
