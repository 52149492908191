<template>
  <span class="time-left">
    <slot name="before" />
    {{ time }}
    <slot name="after" />
  </span>
</template>

<script>
import { formatDuration } from '@utils/dates';
import { useTimeStore } from '@src/shared/store/useTimeStore';

export default {
  name: 'TimeLeft',
  props: {
    to: {
      type: [String, Number],
      required: true,
    },
    format: {
      type: String,
      default: '',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    isServerTime: {
      type: Boolean,
      default: false,
    },
    isCountdown: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const timeStore = useTimeStore();
    timeStore.fetchServerTime();
    return { timeStore };
  },
  data: () => ({
    dateTo: null,
    isStopped: false,
    counter: 0,
    interval: null,
  }),
  computed: {
    time() {
      if (!this.dateTo) {
        return '';
      }

      const currentTime = (() => {
        if (this.isCountdown) {
          return this.counter;
        }
        return this.isServerTime
          ? this.timeStore.serverTime
          : Math.floor(Date.now() / 1000);
      })();
      const { days, hours, minutes, seconds } = this.reverse
        ? formatDuration(this.dateTo, currentTime)
        : formatDuration(currentTime, this.dateTo);

      const isTimeout = days + hours + minutes + seconds <= 0;
      const getWithZero = (num) =>
        num > 9 ? num : `0${num > 0 ? num : 0}`;

      if (isTimeout && !this.reverse && !this.isStopped) {
        this.stopTimer();
      }

      if (this.format !== '') {
        const variants = {
          d: days.toString(),
          dd: getWithZero(days),
          h: hours.toString(),
          hh: getWithZero(hours),
          m: minutes.toString(),
          mm: getWithZero(minutes),
          s: seconds.toString(),
          ss: getWithZero(seconds),
        };

        return this.format.replace(
          /(\w+)/g,
          (key) => variants[key] || key,
        );
      }

      const text = [];

      if (days >= 1) {
        text.push(`${days} ${this.$t('date.days_short')}`);
      }

      if (hours >= 1) {
        text.push(`${hours} ${this.$t('date.hours_short')}`);
      }

      if (minutes >= 1) {
        text.push(`${minutes} ${this.$t('date.minutes_short')}`);
      }

      if (days < 1 && hours < 1 && seconds >= 1) {
        text.push(`${seconds} ${this.$t('date.seconds_short')}`);
      }

      return isTimeout
        ? this.$t('global.timeIsOver')
        : text.join(' ');
    },
  },
  watch: {
    to: {
      handler() {
        this.dateTo = /[^\d]/g.test(this.to) ? 0 : this.to;
        this.isStopped = false;
        this.counter = 0;
      },
      immediate: true,
    },
  },
  created() {
    if (this.isCountdown) {
      this.interval = setInterval(() => {
        this.counter += 1;
      }, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    stopTimer() {
      this.$emit('timeout');
      this.isStopped = true;
      clearInterval(this.interval);
    },
  },
};
</script>
