<template>
  <BaseLink
    v-if="tournament"
    :to="{
      name: 'tournament',
      params: { id: tournament.urlCode || tournament.id },
    }"
    :title="`${$t('tournaments.single')} ${tournament.name}`"
  >
    <slot>{{ tournament.name }}</slot>
  </BaseLink>
</template>

<script>
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  components: {
    BaseLink,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters('tournaments', ['getTournament']),

    tournament() {
      return this.getTournament(this.id);
    },
  },
};
</script>
