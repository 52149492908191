<template>
  <div class="image-cropper">
    <div
      ref="wrapper"
      class="image-cropper-box"
      :class="{ round: isRound }"
      :style="boxStyle"
    >
      <img ref="img" :style="imgStyle" class="img" :src="src" />
    </div>
    <InputRange
      v-if="max > 250"
      :value="scale"
      :min="min"
      :max="max"
      tooltip="none"
      @change="sendResult"
    >
      <button
        slot="beforerange"
        v-longpress="() => setRange(-10)"
        type="button"
        class="range-btn"
      >
        -
      </button>
      <button
        slot="afterrange"
        v-longpress="() => setRange(10)"
        type="button"
        class="range-btn"
      >
        +
      </button>
    </InputRange>
  </div>
</template>

<script>
import Hammer from 'hammerjs';
import InputRange from '@components/BaseComponents/Form/InputRange.vue';

export default {
  name: 'ImageCropper',
  components: {
    InputRange,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    isRound: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    scale: 250,
    min: 250,
    max: 250,
    maxSide: 'width',
  }),
  computed: {
    boxStyle() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
      };
    },
    imgStyle() {
      return {
        [this.maxSide]: `${this.scale}px`,
      };
    },
  },
  mounted() {
    const { img, wrapper } = this.$refs;
    img.onload = (event) => this.setMax(event.target);
    this.setHummer(img, wrapper);
  },
  beforeDestroy() {
    this.hammer.destroy();
  },
  methods: {
    setMax({ naturalHeight, naturalWidth }) {
      const isVertical = naturalHeight > naturalWidth;
      this.maxSide = isVertical ? 'width' : 'height';
      this.max = isVertical ? naturalWidth : naturalHeight;
      this.sendResult(this.scale);
    },
    setHummer(img, wrapper) {
      this.hammer = new Hammer(img);
      this.hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

      let xPos = 0;
      let yPos = 0;
      this.hammer.on('panstart', () => {
        xPos = wrapper.scrollLeft;
        yPos = wrapper.scrollTop;
      });
      this.hammer.on(
        'panleft panright panup pandown',
        ({ deltaX, deltaY }) => {
          wrapper.scrollLeft = xPos - deltaX;
          wrapper.scrollTop = yPos - deltaY;
        },
      );
      this.hammer.on('panend', () => this.sendResult(this.scale));
    },
    sendResult(scale) {
      this.scale = scale;
      const { img, wrapper } = this.$refs;
      this.$nextTick(() => {
        const scale = +(img.clientWidth / img.naturalWidth).toFixed(
          2,
        );

        this.$emit('result', {
          x: wrapper.scrollLeft,
          y: wrapper.scrollTop,
          width: this.width,
          height: this.height,
          scale,
        });
      });
    },
    setRange(value) {
      const scale = ((result) => {
        if (result >= this.max) {
          return this.max;
        }
        if (result <= this.min) {
          return this.min;
        }
        return result;
      })(this.scale + value);
      this.sendResult(scale);
    },
  },
};
</script>
<style lang="scss" scoped>
.image-cropper {
  background-color: rgba(white, 0.05);
  border-radius: 2px;
  padding: 20px;

  &-box {
    margin: 0 auto;
    overflow: hidden;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &.round {
      border-radius: 50%;
    }
  }
}

.img {
  width: auto;
  height: auto;
}

.vue-slider {
  margin-top: 10px;
}
</style>
