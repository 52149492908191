<template>
  <label class="base-radio radio">
    <input
      type="radio"
      :checked="checked"
      :disabled="disabled"
      @change="onChange"
    />
    <span class="radio-check" />
    <slot />
  </label>
</template>

<script>
export default {
  name: 'BaseRadio',
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked() {
      return this.value === this.modelValue;
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables.scss';
@import '../../../assets/scss/_mixins.scss';

.base-radio {
  position: relative;
  display: flex;
  min-height: 1.375rem;
  padding-left: 1.5rem;
  margin-bottom: 0;
  vertical-align: top;
  line-height: 22px;

  input {
    opacity: 0;
    position: fixed;
    top: -100px;
    left: -100px;
    z-index: -5;
  }

  input:checked ~ .radio-check {
    &:after {
      background-color: white;
    }

    &:before {
      background: $azure;
      border-color: transparent;
    }
  }

  input:disabled ~ .radio-check {
    cursor: not-allowed;

    &:before {
      border-color: rgba(white, 0);
      background-color: rgba(white, 0.1);
    }
  }

  .radio-label {
    font-size: 14px;
  }

  .radio-check {
    &:after,
    &:before {
      content: '';
      position: absolute;
      display: block;
      border-radius: 100%;
    }

    &:before {
      top: 3px;
      left: 0;
      width: 16px;
      height: 16px;
      background-color: rgba(black, 0.1);
      border: rgba(white, 0.3) solid 1px;
    }

    &:after {
      top: 9px;
      left: 6px;
      width: 6px;
      height: 6px;
    }
  }
}
</style>
