<template>
  <pvp-modal v-model="isOpen" width="560">
    <template v-if="match">
      <template slot="modal-title">{{
        $t('matches.checkin_confirmation_title')
      }}</template>
      <i18n
        tag="div"
        class="description"
        path="matches.checkin_confirmation_description"
      >
        <TournamentLink :id="match.idTournament" slot="tn" />
      </i18n>
      <template slot="modal-footer">
        <pvp-btn :to="{ name: 'match', params: { id: match.id } }">
          {{ $t('matches.toMatch') }}
        </pvp-btn>
      </template>
    </template>
  </pvp-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import TournamentLink from '@components/BaseComponents/TournamentLink.vue';

export default {
  name: 'MatchNotification',

  components: {
    TournamentLink,
  },
  data: () => ({
    isOpen: false,
    matchId: null,
    tnId: '',
  }),

  computed: {
    ...mapGetters('matches', ['getMatch']),
    ...mapState('matches', ['matchCheckinModalId']),
    match() {
      return this.getMatch(this.matchId);
    },
  },
  watch: {
    matchCheckinModalId(id) {
      if (this.$route.params.id === id) {
        return false;
      }
      this.matchId = id;
      return this.fetchMatch(this.matchId).then(() => {
        this.isOpen = true;
        this.playSound();
      });
    },
  },
  mounted() {
    this.tnId = this.$route.params?.tnId;
  },
  methods: {
    ...mapActions('matches', ['fetchMatch']),

    playSound() {
      const audio = new Audio('/audio/alert.mp3');
      if (audio) {
        audio.volume = 0.3;
        audio.play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  text-align: center;
}
</style>
