<template>
  <div>
    <AppShell v-if="isCorpProfile">
      <div id="app" class="app">
        <template v-if="isMainLayout">
          <router-view />

          <gdpr-modal />

          <HubCheckin
            v-if="getIsCheckin"
            title="Подтвердите участие"
            :deadline="
              timeStore.getTimeWithDiff(getLobby.checkinTimeoutTs)
            "
            :participants="getLobby.participants"
            button-text="Принять"
            :is-need-checkin="isNeedCheckin"
            @click="handleCheckinClick"
          />

          <div class="app__match-maker">
            <HubMatchmakingStatus
              v-if="getIsMatchmacking && !getIsCheckin"
              :title="getIsSearching ? 'Поиск матча' : 'Матч идет'"
              :start-time="
                timeStore.getTimeWithDiff(getMatchmakingStartTime)
              "
              :is-search="getIsSearching"
              @click="handleMatchmakingStatusClick"
            />
          </div>
        </template>

        <router-view v-else></router-view>
      </div>
    </AppShell>
    <!-- КОД НИЖЕ УБРАТЬ В МОМЕНТ РЕЛИЗА НА ВСЕ УЧЕТКИ -->
    <template v-else>
      <div id="app" class="app">
        <template v-if="isMainLayout">
          <div class="app__header">
            <GemsHeader v-if="isShowGemsComponents" />
          </div>
          <MainHeader />
          <div class="app__content">
            <router-view />
          </div>
          <div class="app__footer">
            <GemsFooter v-if="isShowGemsComponents" />
          </div>

          <gdpr-modal />

          <HubCheckin
            v-if="getIsCheckin"
            title="Подтвердите участие"
            :deadline="
              timeStore.getTimeWithDiff(getLobby.checkinTimeoutTs)
            "
            :participants="getLobby.participants"
            button-text="Принять"
            :is-need-checkin="isNeedCheckin"
            @click="handleCheckinClick"
          />

          <div class="app__match-maker">
            <HubMatchmakingStatus
              v-if="getIsMatchmacking && !getIsCheckin"
              :title="getIsSearching ? 'Поиск матча' : 'Матч идет'"
              :start-time="
                timeStore.getTimeWithDiff(getMatchmakingStartTime)
              "
              :is-search="getIsSearching"
              @click="handleMatchmakingStatusClick"
            />
          </div>
        </template>

        <router-view v-else></router-view>
      </div>
    </template>
  </div>
</template>

<script>
import GdprModal from '@components/GdprModal.vue';
import HubMatchmakingStatus from '@components/v2/hub/HubMatchmakingStatus.vue';
import HubCheckin from '@components/v2/hub/HubCheckin.vue';
import MainHeader from '@components/MainHeader.vue';
import GemsHeader from '@components/OutsideComponents/GemsHeader.vue';
import GemsFooter from '@components/OutsideComponents/GemsFooter.vue';
import { setUserLanguage } from './main';
import { mapActions, mapGetters } from 'vuex';
import { recursiveSetTimeout } from '../src/utils/recursiveSetTimeout.js';

import { useTimeStore } from '@src/shared/store/useTimeStore';
import AppShell from '@components/OutsideComponents/AppShell.vue';

export default {
  setup() {
    const timeStore = useTimeStore();
    timeStore.fetchServerTime();
    return { timeStore };
  },

  components: {
    AppShell,
    GdprModal,
    HubMatchmakingStatus,
    HubCheckin,
    MainHeader,
    GemsHeader,
    GemsFooter,
  },
  data() {
    return {
      corpEmailRegx:
        /^[^@]+@(corp\.mail.ru|corp\.vk\.com|astrum\.team|vk\.team|vkteam\.ru)$/,
      secondsSyncServerTime: 300,
      isShowGemsComponents: false,
      updateTimeInterval: null,
      gemsData: {
        auth: 1,
        client_id: 'pvp.gg',
        lang: 'ru_RU',
        langs_available: ['ru_RU', 'en_US'],
        no_headline: true,
        enable_analytics: false,
        whenLoaded: () => {
          document.querySelector('body').classList.add('vkplay');
        },
        toolbar: {
          preset: 'store',
          currentItem: 'ph-menu-pvp',
        },
      },
      isMainLayout: true,
    };
  },
  page: {
    titleTemplate(title) {
      title =
        typeof title === 'function' ? title(this.$store) : title;
      return title ? `${title} | VK Play` : 'VK Play';
    },
  },
  computed: {
    ...mapGetters('profile', [
      'isLoggedIn',
      'getCurrentProfile',
      'getHubUpdateKey',
    ]),
    ...mapGetters('matchmaking', [
      'getIsMatchmacking',
      'getIsSearching',
      'getIsCheckin',
      'getIsMatch',
      'getMatchId',
      'getMatchmakingStartTime',
      'getMatchmakingHubId',
      'getLobby',
      'getHubId',
    ]),

    isNeedCheckin() {
      return this.getLobby.participant.action === 'checkin';
    },

    // КОД НИЖЕ УБРАТЬ В МОМЕНТ РЕЛИЗА НА ВСЕ УЧЕТКИ
    profile() {
      // глобаыльный метод, переписать когда откажемся от глобальных обьектов
      return this.getCurrentProfile;
    },

    // КОД НИЖЕ УБРАТЬ В МОМЕНТ РЕЛИЗА НА ВСЕ УЧЕТКИ
    isCorpProfile() {
      if (this.profile?.email) {
        return this.corpEmailRegx.test(this.profile.email);
      }

      return false;
    },
  },

  watch: {
    getHubUpdateKey() {
      this.fetchMatchmakingState();
    },
  },

  async mounted() {
    const urlChunks = window.location.href.split('/');
    const lastChunk = urlChunks.at(-1);

    if (
      lastChunk === 'print' ||
      lastChunk === 'embedded' ||
      lastChunk === 'test'
    ) {
      this.isMainLayout = false;
    }

    this.init();
    this.fetchHSHeroes();

    this.updateTimeInterval = recursiveSetTimeout(
      this.computeServerTime,
      1000,
    );

    window.addEventListener(
      'visibilitychange',
      () => {
        if (document.hidden === false) {
          this.fetchAuth();
        }
      },
      false,
    );
  },
  beforeDestroy() {
    clearInterval(this.updateTimeInterval);
  },
  methods: {
    ...mapActions('hearthstone', ['fetchHSHeroes']),
    ...mapActions('profile', ['fetchAuth']),
    ...mapActions('matchmaking', ['fetchMatchmakingState']),

    computeServerTime() {
      this.timeStore.serverTime =
        Math.floor(Date.now() / 1000) - this.timeStore.diff;
    },
    async init() {
      this.initMatchmaking();

      if (window.navigator.userAgent.includes('com.my.mygamesapp')) {
        return;
      }

      window.__GEMS = this.gemsData;
      this.isShowGemsComponents = true;
      setUserLanguage();
    },
    async initMatchmaking() {
      if (!this.isLoggedIn) return;

      await this.fetchMatchmakingState();
      if (this.getMatchmakingHubId) {
        this.subcribeToHubUpdate();
      }
    },
    subcribeToHubUpdate() {
      socket.subscribe(
        `$hub:viewer:${this.getMatchmakingHubId}`,
        async ({ data }) => {
          switch (data.event.type) {
            case 'hub.updated': {
              this.fetchMatchmakingState();
              break;
            }
          }
        },
      );
    },

    getRoute(to) {
      const map = {
        lobby: `/hub/${this.getMatchmakingHubId}/match/new`,
        match: `/hub/${this.getMatchmakingHubId}/match/${this.getMatchId}`,
        hub: `/tournament/${this.getMatchmakingHubId}`,
      };
      return map[to];
    },
    redirectTo(to) {
      const href = this.getRoute(to);
      if (this.$route.path === href) return;
      this.$router.push(href);
    },

    handleMatchmakingStatusClick() {
      if (this.getLobby) {
        this.redirectTo('lobby');
      } else if (this.getIsMatch) {
        this.redirectTo('match');
      } else {
        this.redirectTo('hub');
      }
    },
    handleCheckinClick() {
      api.post(
        `hub/${this.getHubId}/lobby/${this.getLobby.id}/checkin`,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

// КОД НИЖЕ УБРАТЬ В МОМЕНТ РЕЛИЗА НА ВСЕ УЧЕТКИ
.app__header {
  position: sticky;
  top: 0;
  z-index: 20;

  @include min-tablet() {
    position: static;
    z-index: initial;
  }
}

// КОД НИЖЕ УБРАТЬ В МОМЕНТ РЕЛИЗА НА ВСЕ УЧЕТКИ
.app__content {
  min-height: 100%;
}

// КОД НИЖЕ УБРАТЬ В МОМЕНТ РЕЛИЗА НА ВСЕ УЧЕТКИ
.app__footer {
  margin-top: auto;
}

.app-loader {
  min-height: 100%;
  align-items: center;
}

.app__match-maker {
  position: fixed;
  left: 12px;
  bottom: 12px;
  z-index: 10000;

  @include min-tablet() {
    left: 48px;
    bottom: 40px;
  }
}
</style>

<style lang="scss">
@import './assets/scss/common/index.scss';
@import './assets/scss/common/main.scss';
@import './assets/scss/common/custom-properties/colors.scss';
</style>
