import { render, staticRenderFns } from "./ProfileLockedModal.vue?vue&type=template&id=be6cadaa&scoped=true"
import script from "./ProfileLockedModal.vue?vue&type=script&lang=js"
export * from "./ProfileLockedModal.vue?vue&type=script&lang=js"
import style0 from "./ProfileLockedModal.vue?vue&type=style&index=0&id=be6cadaa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be6cadaa",
  null
  
)

export default component.exports