<template>
  <div>
    <div id="shell"></div>
    <div ref="appShellRef" class="app-shell-ref">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppShell',
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      scriptUrl: 'https://vkplay.ru/hotbox/eva/navigation.js',
      isScriptLoaded: false,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.mountEmbeddedScript();
    },
    mountEmbeddedScript() {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = this.scriptUrl;
      scriptElement.onload = () => {
        window.EVA.init('shell', {
          preset: 'pvp',
          appNode: this.$refs.appShellRef,
          includeSearch: ['pvp'],
        });
      };

      document.querySelector('body').append(scriptElement);
    },
  },
};
</script>
