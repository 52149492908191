<template>
  <vue-slider v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
  name: 'PvpRange',
  components: {
    VueSlider,
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.$emit('input', +event.target.value);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables.scss';
@import '../../../assets/scss/_mixins.scss';

::v-deep .vue-slider {
  &-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &-rail {
    background-color: $popover-bg;
    border-radius: 15px;
    height: 4px;
  }

  &-process {
    background-color: var(--button-bg-primary);
    border-radius: 15px;
  }

  &-mark {
    z-index: 4;

    &:first-child &-step,
    &:last-child &-step {
      display: none;
    }

    &-step {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.16);
    }

    &-label {
      font-size: 14px;
      white-space: nowrap;
    }
  }

  &-dot {
    z-index: 1;

    &-handle {
      cursor: pointer;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--button-bg-primary);

      &-disabled {
        cursor: not-allowed;
        background-color: #ccc;
      }
    }

    &-tooltip {
      &:after {
        content: '';
        position: absolute;
      }

      &-wrapper {
        opacity: 0;

        &-show {
          opacity: 1;
        }
      }

      &-inner {
        font-size: 14px;
        white-space: nowrap;
        padding: 2px 5px;
        min-width: 20px;
        text-align: center;
        color: #fff;
        border-radius: 5px;
        border-color: #3498db;
        background-color: #3498db;
        box-sizing: content-box;

        &-top,
        &-left,
        &-right,
        &-botom {
          &:after {
            transform: translate(-50%, 0);
            height: 0;
            width: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px;
            border-top-color: inherit;
          }
        }

        &-top:after {
          top: 100%;
          left: 50%;
        }

        &-left:after {
          left: 100%;
          top: 50%;
        }

        &-right::after {
          right: 100%;
          top: 50%;
        }

        &-bottom::after {
          bottom: 100%;
          left: 50%;
        }
      }
    }
  }
}
</style>
