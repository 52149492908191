<template>
  <div class="pvp-calendar">
    <div class="calendar-header">
      <pvp-btn
        icon-left="angle-left"
        variant="clear"
        class="control"
        @click="prevMonth"
      />
      <BaseSelect
        v-model="month"
        class="month"
        label-key="name"
        value-key="id"
        list-position="top"
        :readonly="appIsMobile"
        :clearable="false"
        :options="months"
        @input="pickDate(null)"
      />
      <BaseSelect
        v-model="year"
        class="year"
        list-position="top"
        :readonly="appIsMobile"
        :clearable="false"
        :options="years"
        @input="pickDate(null)"
      />
      <pvp-btn
        icon-left="angle-right"
        variant="clear"
        class="control"
        @click="nextMonth"
      />
    </div>
    <div class="calendar-grid">
      <div
        v-for="day in weekDays"
        :key="day"
        class="grid-cell weekday"
      >
        {{ day }}
      </div>
      <template v-for="day in days">
        <pvp-btn
          v-if="day"
          :key="day"
          :class="{
            current: day === date,
            picker: !!day,
          }"
          class="grid-cell day-picker"
          variant="clear"
          @click="pickDate(day)"
        >
          {{ day }}
        </pvp-btn>
        <div v-else :key="day" class="grid-cell" />
      </template>
    </div>
    <template v-if="hasTime">
      <div class="time">
        <div class="input-box">
          <pvp-btn
            v-longpress="() => changeHours(1)"
            tabindex="-1"
            variant="clear"
            icon-left="angle-up"
          />
          <BaseInput
            v-model="hours"
            type="text"
            maxlength="2"
            @change="changeHours(0)"
          />
          <pvp-btn
            v-longpress="() => changeHours(-1)"
            tabindex="-1"
            variant="clear"
            icon-left="angle-down"
          />
        </div>
        <span>:</span>
        <div class="input-box">
          <pvp-btn
            v-longpress="() => changeMinutes(1)"
            tabindex="-1"
            variant="clear"
            icon-left="angle-up"
          />
          <BaseInput
            v-model="minutes"
            type="text"
            maxlength="2"
            @change="changeMinutes(0)"
          />
          <pvp-btn
            v-longpress="() => changeMinutes(-1)"
            tabindex="-1"
            variant="clear"
            icon-left="angle-down"
          />
        </div>
      </div>
      <pvp-btn
        class="done"
        @keydown.tab="$emit('close')"
        @click="emitDate"
      >
        {{ $t('global.select') }}
      </pvp-btn>
    </template>
  </div>
</template>

<script>
import { isValidDate, leadingZero } from '@utils/dates';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'PvpCalendar',
  components: {
    BaseInput,
    BaseSelect,
  },
  props: {
    value: {
      type: [String, Date, Number],
      default: null,
    },
    min: {
      type: Date,
      required: true,
    },
    max: {
      type: Date,
      required: true,
    },
    hasTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      month: null,
      year: null,
      hours: '00',
      minutes: '00',
    };
  },
  computed: {
    ...mapGetters('application', ['appIsMobile']),

    years() {
      return _.range(
        this.min.getFullYear(),
        this.max.getFullYear() + 1,
      ).reverse();
    },

    months() {
      const minMonth =
        this.min.getFullYear() === this.year
          ? this.min.getMonth()
          : 0;
      const maxMonth =
        this.max.getFullYear() === this.year
          ? this.max.getMonth()
          : 11;
      return _.range(minMonth, maxMonth + 1).map((id) => ({
        name: this.$t(`date.months_list_${id}`),
        id,
      }));
    },

    weekDays() {
      return _.range(0, 7).map((i) =>
        this.$t(`date.weekDays_short_${i}`),
      );
    },

    days() {
      return [
        ..._.range(
          1,
          new Date(this.year, this.month).getUTCDay() + 1,
        ).map(() => null),
        ..._.range(
          1,
          new Date(this.year, this.month + 1, 0).getDate() + 1,
        ),
      ];
    },
  },
  watch: {
    value: {
      handler: 'onValueUpdate',
      immediate: true,
    },
  },
  methods: {
    onValueUpdate(value) {
      const date = this.getInitialDate(value);
      this.month = date.getMonth();
      this.year = date.getFullYear();
      this.date = date.getDate();
      this.hours = leadingZero(date.getHours());
      this.minutes = leadingZero(date.getMinutes());
    },

    getInitialDate(date) {
      if (isValidDate(date)) {
        return new Date(date * 1000);
      }

      const current = new Date();

      if (current.getTime() < this.min.getTime()) {
        return this.min;
      }

      if (current.getTime() > this.max.getTime()) {
        return this.max;
      }

      return current;
    },

    prevMonth() {
      const firstYear = _.last(this.years);

      if (this.month > 0) {
        this.month -= 1;
      } else if (this.year > firstYear) {
        this.year -= 1;
        this.month = _.last(this.months).id;
      }
      this.date = null;
    },

    nextMonth() {
      const lastYear = _.head(this.years);

      if (this.month < _.last(this.months).id) {
        this.month += 1;
      } else if (this.year < lastYear) {
        this.year += 1;
        this.month = _.head(this.months).id;
      }
      this.date = null;
    },

    pickDate(date) {
      this.date = date;
      if (this.hasTime === false && Boolean(date)) {
        this.emitDate();
      }
    },

    emitDate() {
      const date = new Date(this.year, this.month, this.date);
      if (this.hasTime) {
        date.setHours(this.hours);
        date.setMinutes(this.minutes);
      }
      return this.$emit('input', Math.trunc(date.getTime() / 1000));
    },

    changeHours(value = 0) {
      const hour = Number(this.hours, 10) + value;
      this.hours =
        hour > 23 ? '00' : leadingZero(hour >= 0 ? hour : 23);
    },

    changeMinutes(value = 0) {
      const minute = Number(this.minutes, 10) + value;
      this.minutes =
        minute > 59 ? '00' : leadingZero(minute >= 0 ? minute : 59);
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-calendar {
  width: 320px;
  min-width: 290px;
  max-width: 100%;
  padding: 20px;
  box-shadow: $default-box-shadow;
  background: $dark-two;
  border-radius: $border-radius;
  user-select: none;

  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;

    .control {
      color: rgba(white, 0.5);

      &:focus,
      &:hover {
        color: rgba(white, 0.8);
      }
    }

    .month {
      width: 110px;
    }

    .year {
      width: 90px;
      margin-top: 0;
    }
  }

  .calendar-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    font-size: 12px;

    .grid-cell {
      flex: 0 0 calc(100% / 7);
      display: flex;
      align-items: center;
      justify-content: center;

      &.weekday {
        color: rgba(white, 0.4);
        font-weight: bold;
        padding-bottom: 10px;
      }

      &.day-picker {
        width: 100%;
        height: 38px;
        font-size: inherit;
        color: rgba(white, 0.7);

        &.picker {
          background-color: rgba(black, 0.1);
        }

        &:focus {
          box-shadow: 0 0 2px rgba(white, 0.5);
        }

        &.current,
        &:hover {
          color: rgba(white, 0.9);
          background-color: $dark;
        }
      }
    }
  }

  .time {
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;

    .input-box {
      margin: 0 0.5em;

      .label ::v-deep .input {
        text-align: center;
      }

      .button {
        width: 100%;
        color: rgba(white, 0.5);

        &:hover {
          color: white;
        }
      }
    }
  }

  .done {
    display: block;
    margin: 12px auto 0;
    width: 158px;
  }
}
</style>
