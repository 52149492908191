<template>
  <div ref="select" class="select">
    <span v-if="label" class="select-label" :class="{ required }">
      {{ label }}
    </span>
    <div
      v-if="readonly && appIsMobile"
      class="select-real select-wrapper"
    >
      <select
        v-model="selectedIndex"
        :required="required"
        :disabled="disabled"
        :class="[statusData, { placeholder: selectedIndex === -1 }]"
        @change="onSelectChange"
      >
        <option
          v-if="placeholder"
          class="placeholder"
          :value="-1"
          disabled
          selected
        >
          {{ placeholder }}
        </option>
        <option
          v-for="(option, key) of filteredOptions"
          :key="key"
          :value="key"
        >
          {{ labelKey && option ? option[labelKey] : option }}
        </option>
      </select>
      <Icon class="nav-icon" name="arrow-down" />
    </div>
    <div
      v-else
      v-click-outside="() => toggleList(false)"
      class="select-wrapper"
    >
      <BaseInput
        v-if="isLimit === false"
        v-model="search"
        v-bind="$attrs"
        :placeholder="placeholderInfo"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        :status="statusData"
        :class="{
          clickable: readonly,
          multiple: multiple && selected.length,
        }"
        v-on="listeners"
        @focus="toggleList(true)"
        @keydown.tab="toggleList(false)"
        @keydown.down="onArrowClick(1)"
        @keydown.up="onArrowClick(-1)"
        @keydown.enter="onEnter"
        @keyup.esc="(event) => event.target.blur()"
      >
        <span
          v-if="!search && selected.length && !multiple"
          slot="before-input"
          :class="{ disabled }"
          class="selected"
        >
          <template v-for="option of selected">
            <slot name="selected-option" :option="option">
              {{ labelKey && option ? option[labelKey] : option }}
            </slot>
          </template>
        </span>
        <div slot="after-input" class="select-right">
          <loader
            v-if="isLoading"
            slot="after-input"
            class="loading"
            :size="16"
          />
          <button
            v-else-if="
              !required && clearable && selected.length && !multiple
            "
            :disabled="disabled"
            type="button"
            class="clear"
            @click="clearSelected"
          >
            <Icon class="nav-icon" name="cross" />
          </button>
          <Icon
            v-else-if="filteredOptions.length"
            class="nav-icon"
            :name="isOpen ? 'arrow-up' : 'arrow-down'"
          />
        </div>
      </BaseInput>
      <div
        v-if="multiple && selected.length"
        :class="[statusData, { disabled }]"
        class="selected-multiple"
      >
        <template v-for="option of selected">
          <slot name="selected-option" :option="option">
            <pvp-btn
              icon-right="cross"
              :disabled="disabled"
              @click="removeItem(option)"
            >
              {{ labelKey && option ? option[labelKey] : option }}
            </pvp-btn>
          </slot>
        </template>
      </div>
      <div
        v-show="isOpen && !isLoading && !disabled"
        ref="list"
        class="list"
        :style="listStyle"
        :class="listPositionName"
      >
        <div
          v-for="(option, key) of filteredOptions"
          :key="key"
          class="list-item"
          :class="{
            active: key === position,
            disabled: option && option.disabled,
          }"
          @click="(event) => selectOption(event, option)"
        >
          <slot name="option" :option="option">
            {{ labelKey && option ? option[labelKey] : option }}
          </slot>
        </div>
      </div>
    </div>
    <div v-if="messageData" :class="statusData" class="message">
      {{ messageData }}
    </div>
  </div>
</template>

<script>
import { isArray, isNumber, isString } from 'lodash';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'PvpSelect',
  components: {
    BaseInput,
    Icon,
  },
  props: {
    value: {
      validator: (value) => {
        return isString(value) || isNumber(value) || isArray(value);
      },
      default: '',
    },
    valueKey: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    labelKey: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    listPosition: {
      type: String,
      default: 'auto',
    },
    listHeight: {
      type: Number,
      default: 200,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    selected: [],
    isOpen: false,
    search: '',
    position: -1,
    selectedIndex: -1,
    listPositionName: 'top',
    hasCustomFilter: false,
  }),
  computed: {
    ...mapGetters('application', ['appIsMobile']),

    placeholderInfo() {
      if (this.multiple) {
        return this.placeholder;
      }
      return this.selected.length ? '' : this.placeholder;
    },
    messageData() {
      return this.message || this.errorMessage;
    },
    statusData() {
      return this.errorMessage ? 'error' : this.status;
    },
    isLimit() {
      if (this.multiple) {
        if (this.limit) {
          return this.selected?.length >= this.limit;
        }
        return (
          this.selected?.length >=
          this.options?.filter((item) => !item?.disabled)?.length
        );
      }
      return false;
    },
    listStyle() {
      return {
        maxHeight: `${this.listHeight}px`,
      };
    },
    filteredOptions() {
      if (this.isLimit) {
        return [];
      }
      if (!this.hasCustomFilter && this.search) {
        const str = _.escapeRegExp(this.search);
        const reg = new RegExp(`^${str}`, 'i');

        const checkOption = (option) => {
          const data = this.labelKey
            ? option?.[this.labelKey]
            : option;
          return (
            reg.test(data) &&
            !this.selected?.some((selected) => {
              const selectedData = this.labelKey
                ? selected?.[this.labelKey]
                : selected;
              return selectedData === data;
            })
          );
        };

        return this.options.filter(checkOption) || [];
      }
      return this.options?.filter((item) => {
        if (this.multiple) {
          return !this.value?.includes(
            this.valueKey ? item?.[this.valueKey] : item,
          );
        }
        if (this.appIsMobile && this.readonly) {
          return true;
        }
        return this.valueKey
          ? item?.[this.valueKey] !== this.value
          : item !== this.value;
      });
    },
    listeners() {
      return {
        ...this.$listeners,
        input: (value) => {
          if (this.hasCustomFilter) {
            this.$emit('filter', value);
          }
        },
      };
    },
  },
  watch: {
    value: {
      handler: 'onValueChange',
      immediate: true,
    },
  },
  created() {
    this.hasCustomFilter = this.$listeners.filter !== undefined;
  },
  methods: {
    onValueChange(value) {
      if (value || value === 0) {
        if (this.valueKey) {
          if (this.multiple) {
            value = this.options.filter((option) =>
              this.value?.includes(option?.[this.valueKey]),
            );
          } else {
            value = this.options.find(
              (option) => option?.[this.valueKey] === this.value,
            );
          }
        }
        this.selected = _.isArray(value) ? value : [value];
        this.selectedIndex = this.filteredOptions.findIndex(
          (item) => {
            if (this.labelKey) {
              return item?.[this.labelKey] === value?.[this.labelKey];
            }
            return item === this.value;
          },
        );
      } else if (!value) {
        this.search = '';
        this.selectedIndex = -1;
        this.selected = [];
      }
    },
    selectOption(event, option) {
      if (option?.disabled) {
        return false;
      }
      this.search = '';
      const getResult = (val) =>
        this.valueKey ? val?.[this.valueKey] : val;
      const newVal = getResult(option);
      if (this.multiple) {
        const result = this.selected.map(getResult);
        result.push(newVal);
        this.$emit('input', result);
      } else {
        this.$emit('input', newVal);
        if (event) {
          event.target.blur();
        }
        this.toggleList(false);
      }
      return true;
    },
    toggleList(isOpen = false) {
      if (this.errorMessage) {
        this.$emit('update:error-message', '');
      }
      if (this.status) {
        this.$emit('update:status', '');
      }
      this.position = -1;
      this.isOpen = isOpen;
      this.listPositionName = this.listPosition;
      if (this.listPosition === 'auto') {
        this.$nextTick(this.calculateListPosition);
      }
    },
    calculateListPosition() {
      const { list } = this.$refs;
      if (!list) {
        return;
      }
      const { top } = list.getBoundingClientRect();

      this.listPositionName =
        top > document.body.clientHeight - top ? 'bottom' : 'top';
    },
    onArrowClick(way) {
      const size = this.filteredOptions.length - 1;
      let position = this.position + way;
      if (position > size) {
        position = -1;
      } else if (position < -1) {
        position = size;
      }
      this.position = position;

      if (this.filteredOptions?.[position]?.disabled) {
        return this.onArrowClick(way);
      }

      this.$nextTick(() => {
        const { select } = this.$refs;
        if (select) {
          const active = select.querySelector('.list-item.active');
          if (active) {
            active.scrollIntoView({
              block: 'nearest',
            });
          }
        }
      });
      return true;
    },
    onEnter(event) {
      event.preventDefault();
      const selected = this.filteredOptions[this.position];
      if (selected) {
        this.selectOption(event, selected);
      }
    },
    onSelectChange() {
      const selected = this.filteredOptions[this.selectedIndex];
      if (selected) {
        this.selectOption(null, selected);
      }
    },

    clearSelected() {
      this.search = '';
      this.selectedIndex = -1;
      this.$emit('input', this.multiple ? [] : undefined);
      this.toggleList(false);
      if (this.hasCustomFilter) {
        this.$emit('filter', '');
      }
    },

    removeItem(option) {
      const data = this.valueKey ? option?.[this.valueKey] : option;
      const result = this.value?.filter((item) => item !== data);
      this.$emit('input', result);
      this.toggleList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables.scss';
@import '../../../assets/scss/_mixins.scss';

.select {
  position: relative;

  .label + &,
  & + & {
    margin: em(16px) 0 0;
  }

  &-label {
    display: block;
    color: rgba(white, 0.5);
    font-size: em(14px);
    text-align: left;

    &.required:after {
      content: '*';
    }

    & + .select-wrapper {
      margin-top: em(6px);
      position: relative;
    }
  }

  &-real {
    width: 100%;
    display: block;
    margin-bottom: 0;

    .label {
      margin-bottom: 0;
      position: relative;
      z-index: 1;
    }

    select {
      margin: em(6px) 0 0;
      width: 100%;
      display: block;
      border-radius: 4px;
      border: 1px solid rgba(white, 0.1);
      background-color: rgba(black, 0.1);
      font-size: em(15px);
      padding: em(6px) em(12px);
      color: white;
      appearance: none;
      outline: none;

      &[disabled] {
        color: rgba(white, 0.3);
      }

      &.success {
        border-color: $dark-pastel-green;
      }

      &.error {
        border-color: $orangey-red;
      }

      &.placeholder {
        color: rgba(white, 0.3);
      }
    }
  }

  &-right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.selected {
  padding-left: em(12px);
  margin-right: em(30px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  font-size: 14px;

  &.disabled {
    color: rgba(white, 0.3);
  }

  &-multiple {
    padding-right: 2em;
    background-color: rgba(black, 0.1);
    border: 1px solid rgba(white, 0.1);
    border-radius: 4px;

    &.disabled {
      background-color: rgba(white, 0);
    }

    &.error {
      border-color: $orangey-red;
    }

    .button {
      margin: 4px;
    }
  }
}

.list {
  position: absolute;
  left: 0;
  overflow: auto;
  background-color: $dark;
  width: 100%;
  z-index: 2;
  border: 1px solid rgba(white, 0.1);

  &.top {
    top: 100%;
    border-radius: 0 0 em(4px) em(4px);
  }

  &.bottom {
    bottom: 100%;
    border-radius: em(4px) em(4px) 0 0;
  }

  &-item {
    cursor: pointer;
    padding: em(6px) em(20px);

    &:hover,
    &.active {
      background-color: rgba(white, 0.1);
    }

    &.disabled {
      color: rgba(white, 0.5);
      padding-left: 8px;
      padding-right: 8px;
      font-weight: bold;
      cursor: not-allowed;

      &:hover,
      &.active {
        background-color: rgba(white, 0);
      }
    }
  }
}

.message {
  font-size: em(13px);
  color: rgba(white, 0.4);
  margin-top: em(8px);
  line-height: 1.25;

  &.success {
    color: $dark-pastel-green;
  }

  &.error {
    color: $orangey-red;
  }
}

.nav-icon {
  position: absolute;
  right: 0.6em;
  top: 50%;
  margin-top: -0.4em;
  height: 0.8em;
  width: 0.8em;
}

.clear {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(white, 0);
  border: none;
  width: 2em;
  color: white;
  z-index: 1;

  &:disabled {
    color: rgba(white, 0.3);
  }
}

.loading {
  position: absolute;
  right: 0.6em;
  top: 50%;
  margin-top: -8px;
}

.multiple {
  ::v-deep input {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      border-color: rgba(white, 0.1);
    }
  }

  & + .selected-multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
