<template>
  <div
    ref="window"
    class="window"
    :class="windowModifyClass"
    tabindex="0"
    @keydown.tab="firstItemFocusTrap"
    @keydown.esc="windowCloseEmmiter"
  >
    <h2 v-if="isShowTitle" class="window__title">
      {{ title }}
    </h2>
    <div class="window__content" :class="contentModifyClass">
      <slot />
    </div>
    <button
      class="window__button"
      @click="windowCloseEmmiter"
      @keydown.tab="lastItemFocusTrap"
    >
      <icon name="close" :inline="false" />
    </button>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'Window',
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    isShowTitle() {
      return this.type === 'regular' && this.title;
    },
    windowModifyClass() {
      return `window--${this.type}`;
    },
    contentModifyClass() {
      return `window__content--${this.type}`;
    },
  },
  methods: {
    windowCloseEmmiter() {
      this.$emit('close');
    },
    firstItemFocusTrap(event) {
      const isFirstElementFocus =
        event.target.classList.contains('window');
      if (event.shiftKey && isFirstElementFocus)
        event.preventDefault();
    },
    lastItemFocusTrap(event) {
      if (!event.shiftKey) event.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.window {
  margin: auto;
  color: #f5f5f7;
  position: relative;
  width: 100%;
  height: 100%;
  background: #2d2d30;
  border-radius: 20px 20px 0px 0px;
  overflow: auto;

  @include min-tablet() {
    height: unset;
    overflow: unset;
    border-radius: 8px;
    border: 1px solid rgba(245, 245, 247, 0.12);
  }
}

.window--small {
  @include min-tablet() {
    width: 424px;
  }
}

.window--regular {
  @include min-tablet() {
    width: 600px;
  }
}

.window__title {
  display: none;

  @include min-tablet() {
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 12px 52px 12px 24px;
    background: #242429;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
}

.window__button {
  display: none;

  @include min-tablet() {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #5a5a5d;
    background: none;
    border: none;
    position: absolute;
    top: 6px;
    right: 6px;

    &:hover {
      color: #acacad;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.window__content--regular,
.window__content--small {
  padding: 16px 16px 44px;
}

.window__content--regular {
  @include min-tablet() {
    padding: 32px;
  }
}

.window__content--small {
  @include min-tablet() {
    padding: 24px 32px;
  }
}
</style>
