<template>
  <BaseForm
    class="battlenet"
    :validate="beforeSubmit"
    @submit="fetchAccount"
  >
    <slot name="title" />

    <div class="battlenet-regions">
      <pvp-btn
        v-for="region in regions"
        :key="region.key"
        variant="secondary"
        :class="[
          'battlenet-region',
          { active: region.url === regionUrl },
        ]"
        @click="setRegionUrl(region.url)"
      >
        {{ region.key }}
      </pvp-btn>
    </div>

    <slot name="error" />

    <pvp-btn
      type="submit"
      :disabled="regions.length === 0"
      class="battlenet-submit"
    >
      {{ $t('games.bind_single') }}
    </pvp-btn>
  </BaseForm>
</template>

<script>
import storage from '@utils/storage';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';

export default {
  name: 'BattlenetAccount',
  components: {
    BaseForm,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    regions: [],
    regionUrl: '',
  }),
  computed: {
    ...mapGetters('profile', ['getLinkedGameAccount']),

    gameAccount() {
      return this.getLinkedGameAccount(this.id);
    },
  },
  created() {
    this.checkErrors();
    this.fetchRegions();
  },
  methods: {
    checkErrors() {
      if (
        this.gameAccount?.hasAccount === false &&
        storage.getItem('gameLink.gameId')
      ) {
        const battleNet = storage.getItem('gameLink.battlenet.error');
        this.$emit(
          'error',
          battleNet || this.$t('profile.binding_errors_account'),
        );

        setTimeout(() => {
          storage.remove('gameLink.gameId');
          storage.remove('gameLink.battlenet.error');
        }, 500);
      }
    },

    fetchRegions() {
      this.$emit('loading', true);

      api
        .get('/profile/game/battlenet', {
          params: {
            id: this.id,
            extra: this.$router.currentRoute.path,
          },
        })
        .then((result) => {
          if (result.regions) {
            this.regions = _.map(result.regions, (url, key) => ({
              url,
              key,
            }));
          } else if (result.success) {
            this.$emit('success');
          } else {
            this.$emit(
              'error',
              this.$t('profile.binding_errors_battlenet'),
            );
          }
        })
        .catch(({ error }) => {
          this.$emit(
            'error',
            error || this.$t('profile.binding_errors_battlenet'),
          );
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },

    beforeSubmit() {
      if (this.regionUrl) {
        return true;
      }
      this.$emit(
        'error',
        this.$t('profile.binding_errors_needRegion'),
      );
      return false;
    },

    fetchAccount() {
      this.$emit('loading', true);
      storage.setItem('gameLink.gameId', this.id);
      window.location = this.regionUrl;
    },

    setRegionUrl(url) {
      this.$emit('error', '');
      this.regionUrl = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.battlenet {
  text-align: center;

  &-regions {
    margin-top: 12px;
    @include min-tablet() {
      display: flex;
      justify-content: center;
    }
  }

  &-region {
    margin: 4px;
    font-size: 20px;
    font-weight: bold;

    @include min-tablet() {
      height: 70px;
      width: 70px;
    }
    @include max-tablet() {
      width: 100%;
      height: 50px;
    }

    &.active {
      &,
      &:focus {
        box-shadow: 0 0 0 2px $azure;
      }
    }
  }

  &-submit {
    margin-top: 12px;
  }
}
</style>
