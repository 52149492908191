<template>
  <svg
    class="icon"
    :width="svgSize.width"
    :height="svgSize.height"
    fill="currentColor"
  >
    <use :xlink:href="`#${svg.id}`" />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, Array],
      default: 24,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: 'general',
    },
  },
  computed: {
    svg() {
      try {
        const icon = require(`@assets/sprite/svg/${
          this.group ? `${this.group}/` : ''
        }${this.name}.svg`);
        return icon.default;
      } catch {
        return {};
      }
    },
    svgSize() {
      if (this.inline)
        return {
          width: '1em',
          height: '1em',
        };
      if (this.size.length) {
        return {
          width: this.size[0],
          height: this.size[1],
        };
      }

      return {
        width: this.size,
        height: this.size,
      };
    },
  },
};
</script>
