<template>
  <BaseSelect
    v-bind="$attrs"
    label-key="name"
    value-key="id"
    :readonly="appIsMobile"
    :options="games"
    :placeholder="$t('games.chooseSelector')"
    v-on="$listeners"
  >
    <template v-slot:selected-option="{ option }">
      <game-tag :id="option.id" />
    </template>
    <template v-slot:option="{ option }">
      <game-tag :id="option.id" />
    </template>
  </BaseSelect>
</template>

<script>
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  components: {
    BaseSelect,
  },
  props: {
    gamesId: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('application', [
      'getGames',
      'getGame',
      'appIsMobile',
    ]),

    games() {
      // TODO - непонятная дичь тут была, не хочу пока ломать, переписать после рефакторинга стора.
      let result;
      if (this.gamesId?.length) {
        result = this.gamesId.reduce((result, id) => {
          const game = this.getGame(id);
          if (game) {
            result.push(game);
          }
          return result;
        }, []);
      } else {
        result = this.getGames;
      }
      return result.filter((game) => game?.canCreateTournament);
    },
  },
};
</script>
