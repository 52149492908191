<template>
  <component
    :is="tag"
    :to="to"
    :class="buttonCssClasses"
    :disabled="isDisabledButton"
    :type="tag === 'button' ? 'button' : null"
    v-bind="{ ...$attrs }"
    @click="onClickHandler"
  >
    <slot name="slotBefore" />
    <span v-if="!iconOnly">
      {{ text }}
    </span>
    <slot name="slotAfter" />
    <div v-if="loading" class="button__loader">
      <DotsPulse :theme="loaderTheme" />
    </div>
  </component>
</template>

<script>
import DotsPulse from '@components/v2/ui/DotsPulse.vue';

export default {
  name: 'Button',
  components: {
    DotsPulse,
  },
  props: {
    size: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    to: {
      type: [Object, String],
      default: null,
    },
    tag: {
      type: String,
      default: 'button',
    },
    text: {
      type: String,
      default: '',
    },
    long: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonCssClasses() {
      return [
        'button',
        'button--' + this.size,
        'button--' + this.type,
        this.disabled ? 'button--disabled' : '',
        this.loading ? 'button--loading' : '',
        this.iconOnly ? 'button--icon' : 'button--text',
        this.long ? 'button--long' : '',
      ];
    },
    loaderTheme() {
      return this.type === 'secondary' ? 'dark' : 'light';
    },
    isDisabledButton() {
      return this.loading || this.disabled;
    },
  },
  methods: {
    onClickHandler() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: var(--border-radius--small);
  border: 0;
  transition: color, background-color var(--transition-short) linear;
}

a.button {
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.button--text {
  svg {
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 8px;
    }
  }
}

.button--small {
  font-size: 12px;
  line-height: 14px;

  svg {
    // TODO убрать !important когда перепишем компонент Icon
    width: 14px !important;
    height: 14px !important;
  }

  &.button--text {
    padding: 8px 10px;
  }
  &.button--icon {
    padding: 8px;
  }
}

.button--regular {
  font-size: 14px;
  line-height: 16px;

  svg {
    // TODO убрать !important когда перепишем компонент Icon
    width: 16px !important;
    height: 16px !important;
  }

  &.button--text {
    padding: 10px 12px;
  }
  &.button--icon {
    padding: 10px;
  }
}

.button--big {
  font-size: 16px;
  line-height: 24px;

  svg {
    // TODO убрать !important когда перепишем компонент Icon
    width: 20px !important;
    height: 20px !important;
  }

  &.button--text {
    padding: 10px 16px;
  }
  &.button--icon {
    padding: 10px;
  }
}

.button--large {
  font-size: 18px;
  line-height: 24px;

  svg {
    // TODO убрать !important когда перепишем компонент Icon
    width: 24px !important;
    height: 24px !important;
  }

  &.button--text {
    padding: 16px 20px;
  }
  &.button--icon {
    padding: 16px;
  }
}

.button--long {
  width: 100%;
}

.button--loading,
.button--disabled {
  pointer-events: none;
}

.button--disabled {
  color: var(--button-color-disabled);
  background: var(--button-bg-disabled);
}

.button--primary:not(.button--disabled) {
  color: var(--main-color-white);
  background: var(--button-bg-primary);
  &:hover {
    background: var(--button-bg-primary-hover);
  }
  &:active {
    background: var(--button-bg-primary-active);
  }
}

.button--secondary:not(.button--disabled) {
  color: var(--button-color-dark);
  background: var(--button-bg-secondary);
  &:hover {
    background: var(--button-bg-secondary-hover);
  }
  &:active {
    background: var(--button-bg-secondary-active);
  }
}

.button--tertiary:not(.button--disabled) {
  color: var(--button-color-light);
  background: var(--button-bg-tertiary);
  &:hover {
    background: var(--button-bg-tertiary-hover);
  }
  &:active {
    background: var(--button-bg-tertiary-active);
  }
}

.button__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;

  .button--primary & {
    color: var(--button-color-dark);
    background: var(--button-bg-primary);
  }
  .button--secondary & {
    color: var(--button-color-dark);
    background: var(--button-bg-secondary);
  }
  .button--tertiary & {
    color: var(--button-color-light);
    background: var(--button-bg-tertiary);
  }
}
</style>
