import { createTournamentView } from '@viewModels/tournament/createTournamentView';
import { getGameImg } from '@src/gamesImages';

export function getTournamentCard(state, getters, rootState) {
  return (id) => {
    const {
      format,
      userInfo,
      prizeInfo,
      countDown,
      tournament,
      baseTournament,
    } = createTournamentView(rootState, id);

    return {
      ...prizeInfo,
      ...baseTournament,
      countDown,
      userInfo,
      format,

      get bgImage() {
        const code = this.gameCode || 'oth';
        return {
          wide: tournament?.imgRecommend || getGameImg(code, 'wide'),
          big: tournament?.imgRecommend || getGameImg(code, 'big'),
          small:
            tournament?.imgRecommend || getGameImg(code, 'small'),
        };
      },
    };
  };
}
