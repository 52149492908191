export const state = {
  terms: {},
  termsCode: {},
  termsForAll: [],
  currentTerm: null,
  termModalOpened: false,
};

export const getters = {
  getTerm: (state) => (id) =>
    state.terms?.[id] || state.termsCode?.[id],
};

export const mutations = {
  SET_TERM(state, term) {
    state.terms = {
      ...state.terms,
      [term.id]: term,
    };
    state.termsCode = {
      ...state.termsCode,
      [term.code]: term,
    };
  },

  SET_TERMS_FORALL(state, ids) {
    state.termsForAll = ids;
  },

  SET_CURRENT_TERM(state, term) {
    state.currentTerm = term;
  },

  SET_TERM_MODAL_STATE(state, termModalOpened) {
    state.termModalOpened = termModalOpened;
  },
};

export const actions = {
  fetchTerm({ commit }, { code, version, params = {} } = {}) {
    let path = code ? `/${code}` : '';
    if (code && version) {
      path = `${path}/${version}`;
    }

    return api.get(`/term${path}`, { params }).then((term) => {
      if (_.isArray(term)) {
        return term.map((item) => {
          commit('SET_TERM', item);
          return item.id;
        });
      }

      commit('SET_TERM', term);
      return term.id;
    });
  },

  openTermModal({ commit, dispatch, getters }, { code, version }) {
    commit('SET_CURRENT_TERM', undefined);
    commit('SET_TERM_MODAL_STATE', true);
    dispatch('fetchTerm', { code, version }).then((id) => {
      commit('SET_CURRENT_TERM', getters.getTerm(id));
    });
  },

  closeTermModal({ commit }) {
    commit('SET_TERM_MODAL_STATE', false);
  },
};
