<template>
  <BaseForm
    :validate="beforeSubmit"
    class="simple"
    @submit="linkAccount"
  >
    <slot name="title" />
    <BaseInput
      v-model="nickname"
      :status="status"
      :placeholder="inputPlaceholder"
      :maxlength="maxLength"
      :message="messageHint"
      @input="status = ''"
    />

    <slot name="error" />
    <pvp-btn type="submit">
      {{ $t('games.bind_single') }}
    </pvp-btn>
  </BaseForm>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';

export default {
  name: 'SimpleAccount',
  components: {
    BaseInput,
    BaseForm,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    nickname: '',
    status: '',
  }),
  computed: {
    ...mapGetters('application', ['getGame']),
    ...mapGetters('profile', ['getLinkedGameAccount']),

    game() {
      return this.getGame(this.id);
    },
    gameAccount() {
      return this.getLinkedGameAccount(this.id);
    },

    // ВНИМАНИЕ КОСТЫЛЬ!!!
    // Если игра Mobile Legends: Bang Bang то показать информацию о том что вводить следует ID

    isMLBBGame() {
      // для прода сменить ID === 72
      const mlbbGameid = 72;
      return Number(this.id) === mlbbGameid;
    },

    inputPlaceholder() {
      return this.isMLBBGame
        ? this.$t('players.enter_id_label')
        : this.$t('players.nickname_game_label');
    },

    maxLength() {
      return this.isMLBBGame ? 10 : this.game.constraints.maxLength;
    },

    messageHint() {
      return this.isMLBBGame
        ? this.$t('players.mlbb_game_hint')
        : this.game.constraints.hint;
    },
  },
  created() {
    this.nickname = this.gameAccount?.accountsList?.[0]?.login;
  },
  methods: {
    // ВНИМАНИЕ КОСТЫЛЬ!!!
    // Если игра Mobile Legends: Bang Bang то показать информацию о том что вводить следует ID
    beforeSubmit() {
      const nickname = this.nickname?.trim() || '';
      const regexpNumber = /^\d+$/;
      if (
        nickname.length === 0 ||
        nickname.length < this.game.constraints.minLength ||
        nickname.length > this.game.constraints.maxLength
      ) {
        this.status = 'error';
        return false;
      }
      if (
        this.isMLBBGame &&
        (!regexpNumber.test(nickname) ||
          nickname.length === 0 ||
          nickname.length < 7)
      ) {
        this.status = 'error';
        return false;
      }
      return true;
    },
    linkAccount() {
      this.$emit('loading', true);

      api
        .post('/profile/game/save', {
          id: this.id,
          value: this.nickname,
        })
        .then((data) => {
          this.$emit('success', data);
        })
        .catch(({ error }) => {
          this.$emit('loading', false);
          this.$emit('error', error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.simple {
  text-align: center;

  .label {
    margin-top: 12px;
  }
}

.button {
  margin-top: 12px;
}
</style>
