import { createTournamentView } from '@viewModels/tournament/createTournamentView';
import { getGameImg } from '@src/gamesImages';
import { i18n } from '@src/localization/config';

export function getTournamentPage(state, getters, rootState) {
  return (id) => {
    const {
      format,
      countDown,
      tournament,
      statusCode,
      systemCode,
      baseTournament,
      participantsCountStatistic,
    } = createTournamentView(rootState, id);
    return {
      ...baseTournament,
      participantsCountStatistic,
      statusCode,
      systemCode,
      countDown,

      canShowProfileLogo: Boolean(tournament?.canShowProfileLogo),
      canShowProfileName: Boolean(tournament?.canShowProfileName),
      backgroundLinkUrl: tournament?.backgroundLinkUrl,
      idOrganization: tournament?.idOrganization,
      maxReservePlayersCount: tournament?.maxReservePlayersCount,

      get isBranded() {
        return Boolean(tournament?.imgBackground);
      },

      get bgImage() {
        return (
          tournament?.imgBackground ||
          getGameImg(this.gameCode, 'big')
        );
      },

      get isCountDownVisible() {
        return (
          countDown?.ts &&
          tournament?.canShowTournamentStartTimer &&
          !['executing', 'finished', 'cancelled'].includes(statusCode)
        );
      },

      get logo() {
        return tournament?.imgLogo || tournament?.logo;
      },

      get announcementDate() {
        return formatDate(tournament?.tsAnnounced);
      },

      get registrationTime() {
        const { tsStartReg, tsEndReg } = tournament || {};
        return {
          start: formatDate(tsStartReg),
          end: formatDate(tsEndReg),
          progress: calculateProgress(tsStartReg, tsEndReg),
        };
      },

      get executionTime() {
        const { tsStartRun, tsEndRun } = tournament || {};
        return {
          start: formatDate(tsStartRun),
          end: formatDate(tsEndRun),
          progress: calculateProgress(tsStartRun, tsEndRun),
        };
      },

      get formatInfo() {
        const { name, code } =
          state?.systems?.[tournament?.idSystem] || {};

        return {
          text: `${name}, ${format}`,
          tooltip: i18n.t(
            `tournaments.tooltips_system_${code || 'not-found'}`,
          ),
        };
      },

      get meta() {
        const { name } = this;
        const gameName =
          rootState?.application?.gamesByCode?.[this.gameCode]?.name;
        const getPeriod = ({ start, end } = {}) =>
          `${start} - ${end}`;

        return {
          title: i18n.t('tournaments.meta_singlePage_title', {
            name,
            gameName,
          }),
          description: i18n.t(
            'tournaments.meta_singlePage_description',
            {
              name,
              gameName,
              period: getPeriod(this.executionTime),
              registrationPeriod: getPeriod(this.registrationTime),
            },
          ),
        };
      },
    };
  };
}

function formatDate(ts) {
  if (ts) {
    const isLastYear =
      new Date().getFullYear() !== new Date(ts * 1000).getFullYear();
    return i18n.dt(
      ts,
      isLastYear ? 'dd_MMMM_YYYY_HH_mm' : 'dd_MMMM_HH_mm',
    );
  }
  return '';
}

function calculateProgress(start, end) {
  const now = Math.trunc(Date.now() / 1000);
  if (now > end) {
    return 100;
  }
  if (now < start) {
    return 0;
  }

  return (((now - start) / (end - start)) * 100).toFixed(1) || 100;
}
