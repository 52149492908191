const bind = (el, binding) => {
  el.handlers = [];
  let pressTimer = null;

  const start = () => {
    binding.value();
    pressTimer = setTimeout(start, 200);
  };

  const cancel = () => {
    clearTimeout(pressTimer);
    pressTimer = null;
  };

  el.handlers.push(
    ['mousedown', start],
    ['touchstart', start],
    ['click', cancel],
    ['mouseleave', cancel],
    ['touchend', cancel],
    ['touchcancel', cancel],
  );

  el.handlers.forEach(([event, fn]) => {
    el.addEventListener(event, fn, false);
  });
};

const unbind = (el) => {
  el.handlers.forEach(([event, fn]) => {
    el.removeEventListener(event, fn, false);
  });
};

export default {
  bind,
  unbind,
};
