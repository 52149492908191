export class CustomError extends Error {
  constructor(data) {
    super(data);
    this.code = data?.errorCode;
    this.error = data?.errorMessage || data?.error;
    this.errorData = data?.errorData;
    this.transition = data?.transition;
    this.message = JSON.stringify(this.error);

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(data).stack;
    }
  }
}
