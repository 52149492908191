<template>
  <div class="game-tag" @click="$emit('click')">
    <div class="game-icon">
      <game-icon :name="game.code" />
    </div>
    <div v-if="displayName" class="game-name">
      {{ gameName }}
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameTag',
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    code: {
      type: String,
      default: '',
    },
    displayName: {
      type: Boolean,
      default: true,
    },
    fullName: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('application', ['getGame', 'getGameByCode']),

    game() {
      if (this.code) {
        return (
          this.getGameByCode(this.code) || this.getGameByCode('oth')
        );
      }
      return this.getGame(this.id) || this.getGameByCode('oth');
    },

    gameName() {
      return this.fullName ? this.game.name : this.game.shortName;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-tag {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;

  &.big {
    .game-icon {
      width: em(30px);
      height: em(30px);
      padding: 5px;
      border-radius: em(5px);
    }
  }

  &.transparent {
    .game-name {
      opacity: 0.5;
    }
  }
}

.game-icon {
  flex-shrink: 0;
  width: em(24px);
  height: em(24px);
  background: linear-gradient(180deg, #2c2d2e 0%, #0e0f0f 100%);
  box-shadow: inset 0px 0px 0px 1px rgba(245, 245, 247, 0.12);
  border-radius: em(6px);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f3f3f3;
}

.icon {
  max-height: 100%;
  max-width: 100%;
}

.game-name {
  margin-left: 6px;
  font-size: 14px;
  line-height: 18px;
}
</style>
