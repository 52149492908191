/* eslint-disable no-underscore-dangle */ // for window.__GEMS
import cookies from '@utils/cookies';

import { Fsm } from '@src/plugins/Fsm';

import store from '@state/store';
import { createPinia, PiniaVuePlugin } from 'pinia';
export const pinia = createPinia();

import App from '@src/app.vue';
import { i18n } from '@src/localization/config';
import router from '@router';

import './polyfills';
import './directives';
import './globalComponents';

Vue.use(Fsm);
Vue.use(PiniaVuePlugin);

Vue.mixin({
  filters: {
    filterFloat(value, size = 1) {
      const isInt = value % 1 === 0;
      return Number(isInt ? value : value.toFixed(size));
    },
    filterDischarge(num) {
      return num
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    },
  },
  data() {
    return {
      freeSlot: {
        hash: 'c5e113acfe0f903722bb84e3def79131',
        id: 155742,
      },
      loadingState: {
        page: true,
      },
    };
  },
  methods: {
    scrollToElement({
      element,
      force = false,
      offset = 0,
      behaviour = 'smooth',
    } = {}) {
      if (element) {
        const top = element?.getBoundingClientRect()?.top || 0;
        const elTop = window.scrollY + top;

        if (window.scrollY > elTop || force) {
          window.scrollTo({
            top: elTop + offset,
            behavior: behaviour,
          });
        }
      }
    },
  },
});

Vue.config.productionTip = false;

// ==============================================
// For pvp.vkplay header & page language sync
export const setUserLanguage = () => {
  const cookieVKLang = cookies.getItem('amc_lang');

  function setLanguage(lang) {
    const computedCookiesLang = lang === 'ru' ? 'ru' : 'en';
    const computedGemsLang = lang === 'ru' ? 'ru_RU' : 'en_US';
    const langCookieTime = 365 * 24 * 3600;

    cookies.setItem(
      'language',
      computedCookiesLang,
      langCookieTime,
      '/',
    );
    if (window.__GEMS) {
      window.__GEMS.lang = computedGemsLang;
    }
  }

  if (cookieVKLang === 'ru_RU') {
    setLanguage('ru');
  } else if (
    !cookieVKLang &&
    window.navigator.language.includes('ru')
  ) {
    setLanguage('ru');
  } else {
    setLanguage('en');
  }
};

setUserLanguage();

const { language, userLanguage } = window.navigator;
const lang =
  cookies.getItem('language') ||
  _.head((language || userLanguage).split('-'));

Promise.all([
  store.dispatch('profile/fetchAuth'),
  store.dispatch('application/fetchCatalogue', lang),
  store.dispatch('tournaments/fetchTnSettings'),
]).then(() => {
  if (store.getters['profile/isLoggedIn']) {
    store.dispatch('notifications/fetchNotifications');
    store.dispatch('profile/subscribeToProfileChannel');
  }
  new Vue({
    router,
    i18n,
    store,
    pinia,
    render: (h) => h(App),
  }).$mount('#app');
});
