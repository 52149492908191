/* eslint-disable */
const appendOneLinkScript = () => {
  if (
    process.env.NODE_ENV !== 'production' ||
    !process.env.VUE_APP_ONELINK_ID
  ) {
    return;
  }

  const scriptId = 'one-link-analytics';

  const currentScript = document.getElementById(scriptId);

  if (currentScript) {
    currentScript.remove();
  }

  let partnerScript = document.createElement('script');
  partnerScript.id = scriptId;
  partnerScript.type = 'text/javascript';
  partnerScript.async = true;

  const src = [
    'r=' + encodeURIComponent(document.referrer),
    'u=0',
    'rnd=' + Math.random(),
  ].join('&');

  partnerScript.src =
    '//1l-hit.vkplay.ru/v1/hit/' +
    process.env.VUE_APP_ONELINK_ID +
    '.js?' +
    src;
  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(partnerScript, firstScript);
};

const initOnce = () => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  // Yandex Metrika
  (function (m, e, t, r, i, k, a) {
    m[i] =
      m[i] ||
      function () {
        (m[i].a = m[i].a || []).push(arguments);
      };
    m[i].l = 1 * new Date();
    k = e.createElement(t);
    a = e.getElementsByTagName(t)[0];
    k.async = 1;
    k.src = r;
    a.parentNode.insertBefore(k, a);
  })(
    window,
    document,
    'script',
    'https://mc.yandex.ru/metrika/tag.js',
    'ym',
  );

  ym(process.env.VUE_APP_YA_METRIKA_ID, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  });
};
/* eslint-enable */

export const initMetrics = (router) => {
  initOnce();
  router.afterEach(appendOneLinkScript);
};
