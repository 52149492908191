<template>
  <BaseForm
    class="fortnite"
    :validate="beforeSend"
    @submit="fetchAccount"
  >
    <slot name="title" />
    <div class="fortnite-platforms">
      <pvp-btn
        v-for="{ name, key } in platforms"
        :key="key"
        class="fortnite-platform"
        variant="secondary"
        :class="{ active: platform === key }"
        @click="setPlatform(key)"
      >
        <icon :name="key | platformIcons" />
        <div class="platform-name">{{ name }}</div>
      </pvp-btn>
    </div>
    <div class="title">{{ $t('players.nickname_game_input') }}</div>

    <BaseInput
      v-model="nickname"
      :status="status"
      :placeholder="$t('players.nickname_game_label')"
      :maxlength="game.constraints.maxLength"
      :message="game.constraints.hint"
      @input="skipErrors"
    />
    <slot name="error" />

    <pvp-btn class="submit" type="submit">
      {{ $t('games.bind_single') }}
    </pvp-btn>
  </BaseForm>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';

export default {
  name: 'FortniteAccount',
  components: {
    BaseInput,
    Icon,
    BaseForm,
  },
  filters: {
    platformIcons(key) {
      return {
        pc: 'computer',
        ps4: 'playstation',
        xb1: 'xbox',
      }[key];
    },
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    platforms: [],
    platform: '',
    nickname: '',
    status: '',
  }),
  computed: {
    ...mapGetters('application', ['getGame']),
    ...mapGetters('profile', ['getLinkedGameAccount']),

    game() {
      return this.getGame(this.id);
    },
    gameAccount() {
      return this.getLinkedGameAccount(this.id);
    },
  },
  created() {
    this.fetchPlatforms();
  },
  methods: {
    fetchPlatforms() {
      this.$emit('loading', true);
      this.loadingState.modal = true;

      api
        .get('/profile/game/fortniteplatforms')
        .then(({ platforms }) => {
          this.platforms = _.map(platforms, (name, key) => ({
            name,
            key,
          }));
        })
        .catch(() => {
          this.emit(
            'error',
            this.$t('profile.binding_errors_platforms'),
          );
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },

    skipErrors() {
      this.status = '';
      this.$emit('error', '');
    },

    setPlatform(platfrom) {
      this.platform = platfrom;
      this.skipErrors();
    },

    beforeSend() {
      const nickname = this.nickname.trim();

      if (this.platform.trim() === '') {
        this.$emit(
          'error',
          this.$t('profile.binding_errors_needPlatform'),
        );
        return false;
      }

      if (
        nickname.length === 0 ||
        nickname.length < this.game.constraints.minLength ||
        nickname.length > this.game.constraints.maxLength
      ) {
        this.status = 'error';
        return false;
      }

      return true;
    },

    fetchAccount() {
      this.loadingState.modal = true;
      this.$emit('loading', true);

      api
        .post('/profile/game/fortnitelink', {
          platform: this.platform,
          nickName: this.nickname,
        })
        .then((data) => {
          this.$emit('success', data);
        })
        .catch(({ error }) => {
          this.$emit('error', error);
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}

.fortnite {
  text-align: center;

  &-platforms {
    margin-top: 12px;
  }

  &-platform {
    flex-direction: column;
    @include min-tablet() {
      width: 100px;
      height: 100px;
      margin-left: 6px;
      margin-right: 6px;
    }
    @include max-tablet() {
      width: 100%;
      height: 100px;
      margin-top: 8px;
    }

    .icon {
      font-size: 40px;
    }

    &.active {
      &,
      &:focus {
        box-shadow: 0 0 0 2px $azure;
      }
    }
  }
}

.label {
  margin-top: 12px;
}

.submit {
  margin-top: 12px;
}
</style>
