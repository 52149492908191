import { decode } from 'deckstrings';
import { i18n } from '@src/localization/config';

export const state = {
  cards: {},
  heroes: {},
};

export const getters = {
  getByDeckstring: (state) => (deckstring) => {
    try {
      const [code] = deckstring.match(/^[^#].*$/gim);
      const info = decode(code);
      const heroes = info.heroes.map((hero) => {
        const heroInfo = state.heroes?.[hero] || {};
        return {
          ...heroInfo,
          className: i18n.t(`pick.hs_${heroInfo.cardClass}`),
        };
      });
      const cards = info.cards.map((card) => {
        const cardInfo = state.cards?.[card[0]] || {};
        return {
          ...cardInfo,
          className: i18n.t(`pick.hs_${cardInfo.cardClass}`),
          count: card[1],
        };
      });
      cards.sort((a, b) => a.cost - b.cost);
      return {
        heroes,
        cards,
        format: info.format,
      };
    } catch (e) {
      return undefined;
    }
  },
};

export const mutations = {
  UPDATE_CARDS(state, cards) {
    state.cards = cards.reduce((acc, card) => {
      if (card.cardClass) {
        card.cardClass = card.cardClass.toLowerCase();
      }
      if (card.rarity) {
        card.rarity = card.rarity.toLowerCase();
      }

      acc[card.dbfId] = card;

      return acc;
    }, {});
  },

  UPDATE_HEROES(state, heroes) {
    state.heroes = heroes.reduce((acc, hero) => {
      if (hero.cardClass) {
        hero.cardClass = hero.cardClass.toLowerCase();
      }

      acc[hero.dbfId] = hero;

      return acc;
    }, {});
  },
};

export const actions = {
  fetchHSCards({ rootState, commit }) {
    if (process.env.VUE_APP_IS_LOCAL) {
      return api
        .get(rootState.application.hsPaths.cards)
        .then((data) => commit('UPDATE_CARDS', data));
    }

    const a = document.createElement('a');
    a.href = rootState.application.hsPaths.cards;

    return api
      .cdn(a.pathname)
      .then((data) => commit('UPDATE_CARDS', data));
  },

  fetchHSHeroes({ rootState, commit }) {
    if (process.env.VUE_APP_IS_LOCAL) {
      return api
        .get(rootState.application?.hsPaths?.heroes)
        .then((data) => commit('UPDATE_HEROES', Object.values(data)));
    }

    const a = document.createElement('a');
    a.href = rootState.application?.hsPaths?.heroes;

    return api
      .cdn(a.pathname)
      .then((data) => commit('UPDATE_HEROES', Object.values(data)));
  },
};
