<template>
  <div v-if="hasMailAccount" class="game-account-linker">
    <div class="btns-group">
      <Button
        v-if="!isHideButton && (!hasGameAccount || canRelink)"
        size="regular"
        type="primary"
        long
        :text="bindButtonText"
        @click="startLinking"
      />
      <Button
        v-if="canRelink"
        size="regular"
        type="tertiary"
        long
        :text="$t('account.unbind')"
        @click="unlinkGame"
      />
    </div>

    <pvp-modal
      :value="linkModalOpened"
      width="500"
      @input="showLinkModal"
    >
      <template slot="modal-title">
        {{ modalTitle }}
        <span class="nowrap">{{ game.name }}</span>
      </template>

      <div v-if="isLinking" class="game-linker-modal">
        <loader v-if="loadingState.modal" class="modal-loader" />
        <template v-if="!hasGameAccount">
          <div v-if="linkTypeAccount" class="modal-section">
            <div class="modal-section-title">
              {{ linkTypeAccount.title }}
            </div>

            <component
              :is="linkTypeAccount.component"
              :id="id"
              :is-open-on-mount="isOpenOnMount"
              @loading="onLoading"
              @error="setError"
              @success="reloadGamesList"
            >
            </component>
          </div>
        </template>
        <InfoBox v-else variant="success">
          <i18n
            :path="`profile.binding_games_${
              gameAccount.isMultiAccount ? 'bindedMulti' : 'binded'
            }`"
          >
            <b slot="game">{{ game.name }}</b>
          </i18n>
        </InfoBox>
      </div>

      <div v-if="error" class="error-section">
        <InfoBox v-if="errorData" variant="error"
          >{{ error }}
          <router-link :to="`/player/${profileHash}`">{{
            profileName
          }}</router-link>
        </InfoBox>
        <InfoBox v-else variant="error">{{ error }}</InfoBox>
        <div v-if="instructions.length" class="error-links">
          <div class="error-links-title">
            {{ $t('global.instructions') }}:
          </div>
          <ul class="error-links-list">
            <li v-for="(info, key) in instructions" :key="key">
              <a target="_blank" :href="info.url">{{ info.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </pvp-modal>

    <Modal
      v-if="isVisibleModal"
      type="regular"
      :title="modalWithChoiseTitle"
      class="modal"
      @close="closeModal"
    >
      <loader v-if="isLoading" class="modal__loader" />

      <div v-if="error || isErrorBinding || isError">
        <InfoBox class="modal__error" variant="error">
          {{ error || errorBinding }}
        </InfoBox>
        <div v-if="instructions.length" class="error-links">
          <div class="error-links-title">
            {{ $t('global.instructions') }}:
          </div>
          <ul class="error-links-list">
            <li v-for="(info, key) in instructions" :key="key">
              <a target="_blank" :href="info.url">{{ info.name }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="modal__buttons">
        <Button
          size="regular"
          type="secondary"
          long
          :text="$t('info.continue_with_vkplay')"
          @click="showEnterNicknameForm"
        />
        <div
          v-show="isVisbleEnterNickname"
          class="modal__nickname-form"
        >
          <Input
            v-model="userNickname"
            class="modal__input"
            :placeholder="$t('info.enter_your_nickname')"
            :error-text="validationErrorText"
            @input="validateNickname"
          />
          <Button
            :disabled="!isValidForm"
            size="regular"
            type="primary"
            long
            :text="$t('actions.save')"
            @click="bindGame"
          />
        </div>
        <Button
          v-if="isVisbleSteamButton"
          size="regular"
          type="tertiary"
          long
          :text="$t('info.sign_in_steam')"
          @click="redirectToSteam"
        ></Button>
        <Button
          v-else
          size="regular"
          type="tertiary"
          long
          :text="$t('info.sign_in_lesta')"
          @click="redirectToLesta"
        >
          <template #slotBefore>
            <div class="modal__icon">
              <icon name="lesta_icon" />
            </div>
          </template>
        </Button>
      </div>
      <InfoBox
        v-if="
          hasGameAccount && (!error || !isError || !isErrorBinding)
        "
        variant="success"
        class="modal__success"
      >
        <i18n
          :path="`profile.binding_games_${
            gameAccount.isMultiAccount ? 'bindedMulti' : 'binded'
          }`"
        >
          <b slot="game">{{ game.name }}</b>
        </i18n>
      </InfoBox>
    </Modal>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import { pushEvents } from '@utils/metricEvents.js';
import Button from '@components/v2/ui/Button.vue';
import storage from '@utils/storage';
import MultiAccount from '@components/ProfileComponents/GameAccount/MultiAccount.vue';
import SimpleAccount from '@components/ProfileComponents/GameAccount/SimpleAccount.vue';
import BattlenetAccount from '@components/ProfileComponents/GameAccount/BattlenetAccount.vue';
import FortniteAccount from '@components/ProfileComponents/GameAccount/FortniteAccount.vue';
import PubgAccount from '@components/ProfileComponents/GameAccount/PubgAccount.vue';
import SteamAccount from '@components/ProfileComponents/GameAccount/SteamAccount.vue';
import LestaAccount from '@components/ProfileComponents/GameAccount/LestaAccount.vue';
import Modal from '@components/v2/Modal.vue';
import Input from '@components/v2/ui/Input.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'GameAccountLinker',
  components: {
    Icon,
    Button,
    SteamAccount,
    LestaAccount,
    PubgAccount,
    FortniteAccount,
    BattlenetAccount,
    SimpleAccount,
    MultiAccount,
    Modal,
    Input,
    InfoBox,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    variant: {
      type: String,
      default: null,
    },
    hash: {
      type: String,
      default: '',
    },
    isMountedShow: {
      type: Boolean,
      default: false,
    },
    isHideButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // State
      loadingState: {
        ...this.loadingState,
        modal: false,
        unlink: false,
      },

      success: false,
      error: null,
      errorData: null,
      profileName: '',
      profileHash: '',
      isLinking: true,
      isOpenOnMount: false,
      gameNickname: '',

      // Modal
      linkModalOpened: false,
      isVisibleModal: false,
      isLoading: false,
      isError: false,
      errorText: null,
      isErrorBinding: false,
      errorBinding: null,
      personages: [],
      isValidForm: false,
      isVisbleEnterNickname: false,
      validationErrorText: '',
      userNickname: '',
      isVisbleSteamButton: false,
    };
  },
  computed: {
    ...mapGetters('application', ['getGame']),
    ...mapGetters('profile', [
      'hasMailAccount',
      'getLinkedGameAccount',
    ]),

    game() {
      return this.getGame(this.id);
    },

    bindButtonText() {
      return this.canRelink
        ? this.$t('profile.binding_games_change')
        : this.$t('account.bind');
    },

    gameAccount() {
      return this.getLinkedGameAccount(this.id);
    },

    hasGameAccount() {
      if (this.gameAccount?.hasAccount && !this.success) {
        return false;
      }

      return this.gameAccount?.hasAccount;
    },

    isMLBBGame() {
      // для прода сменить ID === 72
      const mlbbGameid = 72;
      return Number(this.id) === mlbbGameid;
    },

    linkTypeAccount() {
      return {
        simple: {
          component: 'simple-account',
          // ВНИМАНИЕ КОСТЫЛЬ!!!
          // Если игра Mobile Legends: Bang Bang то показать информацию о том что вводить следует ID
          title: this.isMLBBGame
            ? this.$t('players.enter_game_id')
            : this.$t('players.nickname_game_input'),
        },
        battlenet: {
          component: 'battlenet-account',
          title: this.$t('players.region_select'),
        },
        fortnite: {
          component: 'fortnite-account',
          title: this.$t('games.platform_select'),
        },
        pubg: {
          component: 'pubg-account',
          title: this.$t('players.nickname_game_input'),
        },
        steam: {
          component: 'steam-account',
        },
        lesta: {
          component: 'lesta-account',
        },
        link: {
          component: 'multi-account',
        },
        lestaOrLink: {},
        steamOrVkPlay: {},
      }[this.game?.linkType];
    },

    canRelink() {
      return this.gameAccount?.hasAccount;
    },

    isWorldOfWarshipsLinkType() {
      return this.game?.linkType === 'lestaOrLink';
    },

    isSteamOrVkPlayLinkType() {
      return this.game?.linkType === 'steamOrVkPlay';
    },

    modalTitle() {
      return this.$t(
        `profile.${
          this.isLinking ? 'binding' : 'unbinding'
        }_gameAccount`,
      );
    },

    modalWithChoiseTitle() {
      return this.isSteamOrVkPlayLinkType
        ? this.$t('info.message_for_calibr')
        : this.$t('info.message_for_link_world_of_ships');
    },

    instructions() {
      if (!this.game?.instructions) {
        return null;
      }

      const instructions = {
        link: ['new_game'],
        steam: ['steam_public'],
        steamOrVkPlay: ['steam_public'],
      }[this.game.linkType];

      return this.game.instructions.filter(({ code }) =>
        instructions?.includes(code),
      );
    },
  },
  watch: {
    isVisibleModal: function () {
      this.userNickname = this.gameAccount?.accountsList?.[0]?.login;
    },
  },
  async mounted() {
    const lastGameId = storage.getItem('gameLink.gameId');

    if (lastGameId === this.id) {
      await this.reloadGamesList();
      this.showLinkModal();
      if (this.gameAccount?.hasAccount === false) {
        this.isOpenOnMount = true;
        const { linkType } = this.game;

        let error = '';

        if (linkType === 'steam') {
          error = storage.getItem('gameLink.steam.error');
          this.errorData = storage.getItem(
            'gameLink.steam.errorData',
          );
          this.profileName = this.errorData?.name || '';
          this.profileHash = this.errorData?.hash || '';
        }

        if (linkType === 'battlenet') {
          error = storage.getItem('gameLink.battlenet.error');
        }

        this.setError(
          error || this.$t('profile.binding_errors_account'),
        );
      }

      const timerId = setTimeout(() => {
        this.isOpenOnMount = false;
        storage.remove('gameLink.gameId');
        storage.remove('gameLink.steam.error');
        storage.remove('gameLink.battlenet.error');
      }, 500);

      if (this.isSteamOrVkPlayLinkType) {
        const errorFromLS = storage.getItem('gameLink.steam.error');
        this.error = errorFromLS;
        clearTimeout(timerId);
      }
    }

    if (this.isMountedShow) this.startLinking();

    this.$emit('mounted', { game: this.game });
  },
  methods: {
    ...mapActions('profile', ['fetchProfileGames']),

    showEnterNicknameForm() {
      this.isVisbleEnterNickname = !this.isVisbleEnterNickname;
    },

    setError(error = null) {
      this.error = error;
    },

    onLoading(isLoading) {
      this.loadingState.modal = isLoading;
    },

    showLinkModal(isOpen = true) {
      if (this.isWorldOfWarshipsLinkType) {
        this.isVisibleModal = true;
      } else if (this.isSteamOrVkPlayLinkType) {
        this.isVisbleSteamButton = true;
        this.isVisibleModal = true;
      } else {
        this.linkModalOpened = isOpen;
        if (isOpen === false) {
          this.$emit('closed');
        }
      }
    },

    closeModal() {
      this.isVisibleModal = false;
      this.isError = false;
      this.isErrorBinding = false;
      this.isVisbleSteamButton = false;
      this.validationErrorText = false;
      this.errorText = '';
      this.errorBinding = '';
      this.isValidForm = false;
      this.isVisbleEnterNickname = false;
      storage.remove('gameLink.steam.error');
      this.setError(null);
      this.$emit('closeModal');
    },

    validateNickname(value) {
      const regExp = /^[\w]*$/;
      this.isValidForm = regExp.test(value);
      if (value.length < 3) {
        this.validationErrorText = this.$t('errors.min_length_3');
        this.isValidForm = false;
      } else if (value.length > 24) {
        this.validationErrorText = this.$t('errors.max_length_24');
        this.isValidForm = false;
      } else if (!this.isValidForm) {
        this.validationErrorText = this.$t(
          'errors.only_latin_numbers_underscore',
        );
      } else {
        this.validationErrorText = '';
      }
    },

    async bindGame() {
      const url = 'profile/game/save';
      const params = {
        id: this.id,
        value: this.userNickname,
      };
      this.isErrorBinding = false;
      try {
        this.isLoading = true;
        await api.post(url, params);
      } catch (err) {
        this.isErrorBinding = true;
        this.errorBinding = err.error;
      } finally {
        this.isLoading = false;
        this.reloadGamesList();
      }
    },

    redirectToLesta() {
      this.isErrorBinding = false;
      return api
        .get('/profile/game/lesta', {
          params: {
            id: this.id,
            extra: this.$router.currentRoute.path,
            needTransform: false,
          },
        })
        .then((params) => {
          storage.setItem('gameLink.gameId', this.id);
          window.location = params.url;
        })
        .catch((error) => {
          this.isErrorBinding = true;
          this.errorBinding =
            error || this.$t('profile.binding_errors_Lesta');
        });
    },

    redirectToSteam() {
      this.isErrorBinding = false;
      this.isLoading = true;

      return api
        .get('/profile/game/steam', {
          params: {
            id: this.id,
            extra: this.$router.currentRoute.path,
            needTransform: false,
          },
        })
        .then((params) => {
          storage.setItem('gameLink.gameId', this.id);
          window.location = params.url;
        })
        .catch((error) => {
          this.isErrorBinding = true;
          this.errorBinding =
            error || this.$t('profile.binding_errors_steam');
          this.onLoading(false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    startLinking() {
      this.success = false;

      this.setError(null);

      this.loadingState = {
        ...this.loadingState,
        modal: false,
        unlink: false,
      };

      this.isLinking = true;
      this.showLinkModal();
    },

    unlinkGame() {
      this.isLinking = false;
      this.loadingState.unlink = true;

      api
        .post('/profile/game/unlink', {
          game: this.game.id,
        })
        .then((data) => {
          if (data.success) {
            this.$emit('unlinked');
          }
        })
        .catch(({ error }) => {
          this.isError = true;
          if (error === 'game_profile_unlink_error') {
            this.setError(this.$t('profile.binding_errors_team'));
          } else if (error.code === 403) {
            this.setError(
              this.$t('profile.binding_errors_tournament'),
            );
          } else {
            this.setError(error?.message || error);
          }

          this.showLinkModal();
        })
        .finally(() => {
          this.reloadGamesList();
        });
    },

    reloadGamesList() {
      this.loadingState.modal = true;

      // ===========================
      // METRICS события привязки игры
      const tmrParams = {
        category: 'pvp',
        action: 'game_connect',
        label: this.game.name,
        url: window.location.href,
      };
      pushEvents('pvp_game_connect', tmrParams);
      // ===========================

      return this.fetchProfileGames().finally(() => {
        this.success = true;

        this.loadingState.modal = false;
        this.loadingState.unlink = false;

        if (this.hasGameAccount) {
          this.$emit('linked');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-account-linker {
  flex-grow: 1;

  .game-linker-modal {
    position: relative;

    .modal-section {
      width: 100%;

      &-title {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 12px;
      }
    }
  }
}

.pvp-modal {
  .button + .button {
    margin-left: 1em;
  }
}

.btns-group {
  min-width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.error {
  &-section {
    margin: 12px 0;
  }

  &-links {
    margin-top: 12px;
    text-align: left;
    font-size: 14px;

    &-title {
      font-weight: 500;
    }

    &-list {
      margin-top: 6px;
    }
  }
}

.modal-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  align-items: center;

  & + .modal-section {
    opacity: 0.1;
  }
}

.nowrap {
  white-space: nowrap;
}

.modal__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.modal-button__text {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.modal__nickname {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 16px;
}

.modal__info {
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
}

.modal__loader {
  margin-bottom: 16px;
}

.modal__error,
.modal__success {
  margin-top: 16px;
  margin-bottom: 16px;
}

.modal__nickname-form {
  width: 100%;
}
.modal__input {
  margin-bottom: 16px;
}

.modal__icon {
  color: #197dec;
}

.error-links {
  margin-bottom: 12px;
}
</style>
