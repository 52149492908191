<template>
  <component
    :is="$attrs.href ? 'a' : to ? 'BaseLink' : 'button'"
    :to="to"
    :disabled="disabled || isLoading"
    :class="classes"
    :type="buttonType"
    v-on="listeners"
  >
    <loader v-if="isLoading" :size="14" />
    <template v-else>
      <Icon
        v-if="iconLeft"
        class="icon-left"
        :name="iconLeft"
        inline
      />
      <slot />
      <Icon
        v-if="iconRight"
        class="icon-right"
        :name="iconRight"
        inline
      />
    </template>
  </component>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'PvpButton',
  components: {
    Icon,
    BaseLink,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: '',
    },
    block: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    buttonType() {
      return this.to || this.$attrs.href ? null : this.type;
    },
    classes() {
      return [
        'button',
        {
          disabled: this.disabled || this.isLoading,
          [`button-${this.variant}`]: !!this.variant,
          [`button-${this.size}`]: !!this.size,
          'button-block': this.block,
        },
      ];
    },
    listeners() {
      return this.disabled || this.isLoading
        ? undefined
        : this.$listeners;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/_variables.scss';
@import '~@assets/scss/_mixins.scss';

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 24px;
  border-radius: 6px;

  &:not(.button-clear) {
    border-radius: 6px;
    border: 1px solid transparent;
    line-height: 18px;
  }

  &:not(.button-link):not(.button-clear) {
    padding: 8px 24px;
  }

  @media (hover: hover) {
    transition: $pvp-control-transition;
  }

  &,
  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
    outline: 0;
    border: 1px solid rgba(white, 0.1);
    box-shadow: inset 0 0 1px white;
  }

  &-clear {
    &,
    &:focus {
      background-color: transparent;
      padding: 0;
      margin: 0;
      line-height: 1;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }
  }

  &-primary {
    background-color: var(--button-bg-primary);
    color: white;

    @media (hover: hover) {
      &:hover {
        color: inherit;
        background-color: var(--button-bg-primary-hover);
      }
    }

    &:active {
      background-color: var(--button-bg-primary-active);
    }
  }

  &-secondary {
    color: #000000;
    background: #e2e3e7;

    @media (hover: hover) {
      &:hover {
        background: #dbdcdf;
        color: #000000;
      }
    }

    &:active {
      background: #c7c7cc;
    }
  }

  &-tertiary {
    color: #d8d8d8;
    background: #454549;

    @media (hover: hover) {
      &:hover {
        background: #57575b;
        color: #d8d8d8;
      }
    }

    &:active {
      background: #4e4e52;
    }
  }

  &-warning {
    background-color: $gold-two;
    color: white;

    @media (hover: hover) {
      &:hover {
        background-color: $pumpkin;
      }
    }

    &:active {
      background-color: $golden-brown;
    }
  }

  &-boost {
    background-color: #f5c93c;
    color: #000000;
  }

  &-boostSecondary {
    background-color: #e2e3e7;
    color: #000000;
    &:hover {
      color: #000000;
      opacity: 0.8;
    }
  }

  &:not(.button-clear) {
    .icon {
      width: 1em;
      height: 1em;

      &-left {
        margin-right: 0.375rem;
      }

      &-right {
        margin-left: 0.375rem;
      }
    }
  }

  &:disabled {
    &,
    &:hover,
    &:focus {
      &:not(.button-clear) {
        background-color: $slate-grey-two;
      }

      border-color: transparent;
      cursor: not-allowed;

      &:not(.button-loading) {
        color: rgba(white, 0.4);
      }
    }
  }

  &-link {
    color: $azure;
    background-color: transparent;
    transition: none;
    padding: 0;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      border-color: transparent;
      box-shadow: none;
    }

    &:hover {
      color: $dodger-blue;
    }

    &:disabled,
    &.disabled {
      color: rgba(white, 0.6);
      pointer-events: none;
      background: none;
    }
  }

  &.v-centered {
    margin-left: auto;
    margin-right: auto;
  }

  &.button-sm {
    font-size: 14px;
  }

  &.button-lg {
    font-size: 1rem;
  }

  &.button-block {
    width: 100%;

    + .button-block {
      margin-top: 0.5rem;
    }
  }
}

.loader {
  height: 1.3em;
}
</style>
