<template>
  <div class="dot-pulse" :class="modifyClass">
    <div class="dot-pulse__item" />
    <div class="dot-pulse__item" />
    <div class="dot-pulse__item" />
  </div>
</template>

<script>
export default {
  name: 'DotsPulse',
  props: {
    theme: {
      type: String,
      required: true,
    },
  },
  computed: {
    modifyClass() {
      return 'dot-pulse--' + this.theme;
    },
  },
};
</script>

<style lang="scss" scoped>
.dot-pulse {
  display: flex;
  column-gap: 6px;
}

.dot-pulse__item {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  transform: scale(0);

  .dot-pulse--dark & {
    background: #000000;
  }
  .dot-pulse--light & {
    background: #d8d8d8;
  }
}

.dot-pulse__item {
  animation: dot-pulse 3s infinite ease-in-out;
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:last-child {
    animation-delay: 0.4s;
  }
}

@keyframes dot-pulse {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>
