<template>
  <div class="lesta">
    <slot name="error" />
  </div>
</template>

<script>
import storage from '@utils/storage';

export default {
  name: 'LestaAccount',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    isOpenOnMount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('profile', ['getLinkedGameAccount']),

    gameAccount() {
      return this.getLinkedGameAccount(this.id);
    },
  },
  created() {
    this.redirectToLesta();
  },
  methods: {
    redirectToLesta() {
      if (this.isOpenOnMount) {
        return false;
      }

      this.$emit('loading', true);

      return api
        .get('/profile/game/lesta', {
          params: {
            id: this.id,
            extra: this.$router.currentRoute.path,
            needTransform: false,
          },
        })
        .then((params) => {
          storage.setItem('gameLink.gameId', this.id);
          window.location = params.url;
        })
        .catch((error) => {
          this.$emit(
            'error',
            error || this.$t('profile.binding_errors_Lesta'),
          );
          this.$emit('loading', false);
        });
    },
  },
};
</script>
