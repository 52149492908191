/* eslint-disable */

const registeredHandlers = [];
let domListener;

function on(el, event, callback) {
  el.addEventListener(event, callback, false);
  return {
    destroy: () => el.removeEventListener(event, callback, false),
  };
}

function dynamicStrategy(el, callback) {
  const anchors = Object.values(el.children).filter(
    (child) => child.id,
  );

  const offset = Number(el.getAttribute('scroll-spy-offset') || 0);

  let lastAnchor;
  let currentAnchor;

  const listener = on(document, 'scroll', (event) => {
    const scrollTop =
      window.pageYOffset ||
      document.scrollTop ||
      document.body.scrollTop;

    anchors.forEach((el) => {
      if (
        scrollTop >=
        el.offsetTop +
          offset +
          Number(el.getAttribute('scroll-spy-offset'))
      ) {
        currentAnchor = el.id;
      }
    });

    if (currentAnchor) {
      if (currentAnchor !== lastAnchor) {
        lastAnchor = currentAnchor;

        callback(currentAnchor);
      }
    }
  });

  return {
    el,
    destroy() {
      listener.destroy();
    },
  };
}

function bind(el, binding) {
  const { value: callback, modifiers } = binding;

  // unbind any existing listeners first
  unbind(el);

  setTimeout(() => {
    registeredHandlers.push(dynamicStrategy(el, callback));
  }, 0);
}

function update(el, binding) {
  if (binding.value !== binding.oldValue) {
    bind(el, binding);
  }
}

function unbind(el) {
  let index = registeredHandlers.length - 1;

  while (index >= 0) {
    if (registeredHandlers[index].el === el) {
      registeredHandlers[index].destroy();
      registeredHandlers.splice(index, 1);
    }

    index -= 1;
  }

  if (registeredHandlers.length === 0 && domListener) {
    domListener.destroy();
    domListener = null;
  }
}

export default {
  bind,
  unbind,
  update,
};
