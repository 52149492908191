<template>
  <div v-if="isOpen" class="modal">
    <div class="modal__window" :style="modalWindowStyle">
      <div
        ref="swipeContainer"
        class="modal__swipe-zone"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
      />
      <Window :type="type" :title="title" @close="modalCloseEmmiter">
        <slot />
      </Window>
    </div>
  </div>
</template>

<script>
import Window from '@components/v2/ui/Window.vue';

export default {
  name: 'Modal',
  components: {
    Window,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      startY: null,
      windowBottomPosition: 0,
    };
  },

  computed: {
    modalWindowStyle() {
      return `bottom: ${this.windowBottomPosition}px`;
    },
  },

  methods: {
    openModal() {
      let resolve;
      let reject;
      const modalPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      });

      this.$options.modalController = { resolve, reject };

      return modalPromise;
    },

    modalCloseEmmiter() {
      this.$emit('close');
    },

    invertNumberSign(number) {
      return number * -1;
    },

    handleTouchStart(event) {
      this.startY = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      const currentY = event.touches[0].clientY;
      const offset = currentY - this.startY;

      if (offset > 0) {
        this.windowBottomPosition =
          this.invertNumberSign(offset).toFixed(0);
      }

      if (offset > 50) {
        this.$emit('close');
      }
    },
    handleTouchEnd() {
      this.startY = null;
      this.windowBottomPosition = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.modal {
  color: white;
  position: fixed;
  inset: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12px);
  overflow: hidden;

  @include min-tablet() {
    overflow: auto;
  }
}

.modal__swipe-zone {
  display: flex;
  align-items: flex-end;
  padding: 12px;
  height: 50px;

  @include min-tablet() {
    display: none;
  }

  &::before {
    content: '';
    display: block;
    width: 50px;
    height: 5px;
    border-radius: 100px;
    background: #888;
    margin: 0 auto;
  }
}

.modal__window {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-height: calc(100% - 120px);
  transition: 10ms ease-in-out;

  @include min-tablet() {
    position: static;
    display: grid;
    align-items: center;
    justify-content: center;
    max-height: unset;
    height: 100%;
  }
}
</style>
