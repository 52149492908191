<template>
  <div class="loader">
    <div :style="loaderSize" class="spinner">
      <div
        v-for="item in count"
        :key="item"
        class="spinner-item"
        :style="roundSize"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 32,
      validator(value) {
        return value % 8 === 0;
      },
    },
  },
  computed: {
    loaderSize() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
    roundSize() {
      const size = (this.size / 4).toFixed(0);
      const halfSize = (size / 2).toFixed(0);
      return {
        width: `${size}px`,
        height: `${size}px`,
        marginTop: `-${halfSize}px`,
        marginLeft: `-${halfSize}px`,
      };
    },
    count() {
      return _.range(0, 8);
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
}

.spinner {
  display: block;
  position: relative;
  margin: 0 auto;

  &-item {
    position: absolute;
    border-radius: 50%;
    animation: ball-spin-clockwise 1s infinite ease-in-out;
    background-color: white;

    &:nth-child(1) {
      top: 15%;
      left: 50%;
      animation-delay: -0.875s;
    }

    &:nth-child(2) {
      top: 22%;
      left: 82%;
      animation-delay: -0.75s;
    }

    &:nth-child(3) {
      top: 50%;
      left: 95%;
      animation-delay: -0.625s;
    }

    &:nth-child(4) {
      top: 82%;
      left: 82%;
      animation-delay: -0.5s;
    }

    &:nth-child(5) {
      top: 95%;
      left: 50%;
      animation-delay: -0.375s;
    }

    &:nth-child(6) {
      top: 82%;
      left: 18%;
      animation-delay: -0.25s;
    }

    &:nth-child(7) {
      top: 50%;
      left: 5%;
      animation-delay: -0.125s;
    }

    &:nth-child(8) {
      top: 22%;
      left: 18%;
      animation-delay: 0s;
    }
  }
}

@keyframes ball-spin-clockwise {
  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: scale(0);
  }
}
</style>
