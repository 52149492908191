<template>
  <div class="input" :class="inputModifyClasses">
    <div class="input__container" :class="containerModifyClasses">
      <div v-if="isShowBeforeIcon" class="input__icon">
        <Icon :name="beforeIcon" inline />
      </div>
      <div class="input__field">
        <label
          v-if="stickyPlaceholder"
          class="input__label"
          :class="{
            'input__label--active': isStickyPlaceholderActive,
          }"
        >
          {{ placeholder }}
        </label>
        <input
          ref="inputField"
          v-model="inputValue"
          :type="type"
          class="input__value"
          :placeholder="stickyPlaceholder ? '' : placeholder"
          :disabled="disabled"
          :maxlength="maxLength"
          @input="onInputHandler"
          @change="onChangeHandler"
        />
      </div>
      <div v-if="isShowAfterIcon" class="input__icon">
        <Icon :name="afterIcon" inline />
      </div>
      <button v-if="clear" class="input__clear" @click="clearInput">
        <Icon name="x" inline />
      </button>
    </div>
    <span v-if="errorText" class="input__error">{{ errorText }}</span>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'Input',
  components: {
    Icon,
  },
  props: {
    size: {
      type: String,
      default: 'regular',
    },
    placeholder: {
      type: String,
      default: '',
    },
    stickyPlaceholder: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: '',
    },
    beforeIcon: {
      type: String,
      default: '',
    },
    afterIcon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    maxLength: {
      type: [String, Number],
      default: 100,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  computed: {
    isShowBeforeIcon() {
      return this.beforeIcon.length;
    },
    isShowAfterIcon() {
      return this.afterIcon.length;
    },
    isStickyPlaceholderActive() {
      return this.inputValue.length;
    },
    inputModifyClasses() {
      return [`input--${this.size}`];
    },
    containerModifyClasses() {
      return [
        this.disabled ? 'input__container--disabled' : '',
        this.errorText.length ? 'input__container--error' : '',
        this.stickyPlaceholder
          ? 'input__container--sticky-placeholder'
          : '',
      ];
    },
  },
  methods: {
    onInputHandler() {
      this.$emit('input', this.inputValue);
    },
    onChangeHandler() {
      this.$emit('change', this.inputValue);
    },
    clearInput() {
      this.$refs.inputField.focus();
      if (!this.inputValue) return;
      this.inputValue = '';
      this.onInputHandler();
      this.onChangeHandler();
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  color: #f5f5f7;
  isolation: isolate;

  &--small {
    font-size: 12px;
  }
  &--regular {
    font-size: 14px;
  }
  &--big {
    font-size: 16px;
  }
  &--large {
    font-size: 18px;
  }
}

.input__container {
  display: flex;
  position: relative;
  background: #454549;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 6px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &:focus-within {
    box-shadow: 0 0 0 2px #d8d8d8;
  }
  &--error {
    box-shadow: 0 0 0 2px #c64040;
  }
  &--disabled {
    color: #5f5d5d;
    background: #373739;
  }

  .input--small & {
    column-gap: 4px;
    padding: 0 10px;
    height: 30px;
  }
  .input--regular & {
    column-gap: 6px;
    padding: 0 12px;
    height: 36px;
  }
  .input--big & {
    column-gap: 8px;
    padding: 0 16px;
    height: 44px;
  }
  .input--large & {
    column-gap: 12px;
    padding: 0 20px;
    height: 56px;
  }
}
.input__field {
  width: 100%;
  position: relative;
  background: inherit;
}

.input__label {
  display: flex;
  align-items: center;
  position: absolute;
  padding-top: 0;
  inset: 0;
  font-weight: 400;
  font-size: inherit;
  line-height: 1em;
  color: #979797;
  background: inherit;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-property: inset, font-size;
  pointer-events: none;
  z-index: 1;

  .input__container--disabled & {
    color: inherit;
  }
}

.input__container:focus-within .input__label,
.input__label--active {
  font-size: calc(1em - 1px);
  bottom: 50%;

  & + .input__value {
    position: relative;
    top: 40%;
    height: 50%;
  }
}

.input__value {
  width: 100%;
  height: 100%;
  color: inherit;
  line-height: 1em;
  background: inherit;
  border: none;

  &:hover,
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #979797;
  }
}

.input__icon {
  display: flex;
  align-items: center;

  svg {
    stroke: currentColor;
  }

  .input__container:not(.input__container--disabled) & {
    color: #979797;
  }
  .input__container:focus-within & {
    color: inherit;
  }
}

.input__clear {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: inherit;
  background: none;
  border: none;
}

.input__icon,
.input__clear {
  .input--small & {
    font-size: 14px;
  }
  .input--regular & {
    font-size: 16px;
  }
  .input--big & {
    font-size: 24px;
  }
  .input--large & {
    font-size: 24px;
  }
}

.input__error {
  color: #c64040;
}
</style>
