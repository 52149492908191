<template>
  <div class="pvp-datepicker select">
    <BaseInput
      v-mask="mask"
      :value="maskedValue"
      v-bind="$attrs"
      @focus="onInputFocused"
      @blur="onInputBlurred"
      @input.native="onDateChanged"
    />
    <Icon name="calendar" :size="16" />
    <div
      v-click-outside="onCalendarBlurred"
      class="pvp-calendar-container"
      @focusin="onCalendarFocused"
      @mousedown="onCalendarFocused"
    >
      <pvp-calendar
        v-if="calendarVisible"
        :min="min"
        :max="max"
        :value="value"
        class="fade-up-animation"
        :class="position"
        :has-time="hasTime"
        @close="onCalendarBlurred"
        @input="onDatePicked"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import PvpCalendar from '@components/BaseComponents/Datepicker/Calendar.vue';
import { leadingZero, isValidDate } from '@utils/dates';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'Datepicker',
  components: { PvpCalendar, Icon, BaseInput },
  props: {
    value: {
      type: [String, Date, Number],
      default: null,
    },
    min: {
      type: Date,
      default: () => new Date(),
    },
    max: {
      type: Date,
      default: () => new Date(new Date().getFullYear() + 2, 0, 0),
    },
    position: {
      type: String,
      default: 'top',
    },
    hasTime: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputFocused: false,
      calendarFocused: false,
    };
  },
  computed: {
    calendarVisible() {
      return this.calendarFocused || this.inputFocused;
    },
    mask() {
      return {
        mask: this.hasTime ? '99.99.9999   99:99' : '99.99.9999',
      };
    },
    maskedValue() {
      if (isValidDate(this.value)) {
        const dt = new Date(this.value * 1000);
        const date = leadingZero(dt.getDate());
        const month = leadingZero(dt.getMonth() + 1);
        const year = dt.getFullYear();

        if (this.hasTime) {
          const hours = leadingZero(dt.getHours());
          const minutes = leadingZero(dt.getMinutes());
          return `${date}.${month}.${year} ${hours}:${minutes}`;
        }

        return `${date}.${month}.${year}`;
      }
      return null;
    },
  },
  methods: {
    onInputFocused() {
      if (this.$attrs?.['error-message']) {
        this.$emit('update:error-message', '');
      }
      this.inputFocused = true;
    },

    onInputBlurred() {
      setTimeout(() => {
        this.inputFocused = false;
      }, 50);
    },

    onCalendarFocused() {
      this.calendarFocused = true;
    },

    onCalendarBlurred() {
      this.calendarFocused = false;
    },

    onDatePicked(date) {
      this.onCalendarBlurred();
      this.emitDate(date);
    },

    maskToTs(mask) {
      const [date, month, oth] = mask.split('.') || [];
      const [year, time] = oth?.split('   ') || [];
      const dt = new Date(year, month - 1, date);

      if (this.hasTime) {
        const [hours = 0, minutes = 0] = time?.split(':') || [];
        dt.setHours(hours);
        dt.setMinutes(minutes);
      }

      return dt.getTime();
    },

    onDateChanged({ target }) {
      if (target?.inputmask?.isComplete()) {
        const ts = this.maskToTs(target.value);
        this.emitDate(Math.trunc(ts / 1000));
      } else if (target.value === '' && this.required === false) {
        this.emitDate();
      }
    },

    emitDate(date) {
      this.$emit('input', isValidDate(date) ? date : '');
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  .label + &,
  & + & {
    margin: em(16px) 0 0;
  }

  .label ::v-deep input {
    padding-right: 30px;
  }
}

.pvp-datepicker {
  position: relative;

  .icon {
    position: absolute;
    right: 0.5em;
    top: 2.25em;
    margin-bottom: 0.6em;
  }
}

.pvp-calendar {
  position: absolute;
  z-index: 2;

  &.top {
    top: 0;
    right: 0;
  }

  &.bottom {
    bottom: em(35px);
    right: 0;
  }

  &.fixed {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: fixed;
  }
}
</style>
