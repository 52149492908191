<template>
  <div class="pvp-info-box" :class="`box-${variant}`">
    <Icon v-if="icon" :name="icon" class="box-icon" />
    <div class="box-content">
      <slot />
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'InfoBox',
  components: {
    Icon,
  },
  props: {
    variant: {
      type: String,
      default: 'info',
    },
  },
  computed: {
    icon() {
      return {
        empty: '',
        info: 'info',
        error: 'cross-circle',
        success: 'check-circle',
        warning: 'info-triangle',
      }[this.variant];
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-info-box {
  display: flex;
  padding: 12px;
  background-color: rgba($dark, 0.75);
  border-left: 4px solid $slate-grey-three;
  border-radius: 5px;
  color: rgba(white, 0.7);
  text-align: left;

  &.box {
    &-error {
      background-color: rgba($burnt-red, 0.25);
      border-color: $orangey-red;
    }

    &-success {
      background-color: rgba($tree-green, 0.25);
      border-color: $dark-pastel-green;
    }

    &-warning {
      background-color: rgba($gold-two, 0.15);
      border-color: $gold;
    }
  }
}

.box {
  &-icon {
    flex-shrink: 0;
    width: 20px;
    font-size: 20px;
    line-height: 1;
  }

  &-content {
    margin-left: 10px;
    font-size: 15px;
  }
}
</style>
