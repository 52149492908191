Vue.use(Vuex);

function getAllModules() {
  const requireModule = require.context('./modules', false, /\.js$/);

  const extractName = (path) => path.replace(/(^\.\/)|(\.js$)/g, '');

  return requireModule.keys().reduce(
    (result, path) => ({
      ...result,
      [extractName(path)]: {
        namespaced: true,
        ...requireModule(path),
      },
    }),
    {},
  );
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: getAllModules(),
});
