<template>
  <div v-if="code" class="flag" :class="code" />
</template>

<script>
export default {
  name: 'CountryFlag',
  props: {
    code: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.flag {
  background-image: url(~@components/BaseComponents/Country/countries.png);
  font-size: em(4px);
  @import './countries.scss';
}
</style>
