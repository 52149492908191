import { defineStore } from 'pinia';
import { reactive } from 'vue';

export const useDebugStore = defineStore('debug', () => {
  const responsesLimit = 50;
  const responses = reactive([]);

  function addResponse(newResponse) {
    if (responses.length === responsesLimit) {
      responses.shift();
      responses.push(newResponse);
    } else {
      responses.push(newResponse);
    }
  }

  return { responses, addResponse };
});
