<template>
  <div class="headline">
    <div class="headline-title">
      <slot name="title"></slot>
    </div>
    <div v-if="$slots.addon" class="headline-addon">
      <slot name="addon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadLine',
};
</script>

<style lang="scss" scoped>
.headline {
  display: flex;

  @include min-tablet() {
    align-items: center;

    .headline-addon {
      margin-left: 20px;
    }
  }

  @include max-tablet() {
    &:not(.spaced) {
      flex-direction: column;
    }

    &.spaced {
      .headline-addon {
        margin-left: auto;
      }
    }
  }

  @include max-tablet() {
    h2,
    h3 {
      font-size: 18px;
    }
  }

  .headline-addon {
    display: flex;
    align-items: center;

    .addon + .addon {
      margin-left: 20px;
    }
  }
}
</style>
