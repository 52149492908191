<template>
  <div class="list">
    <button
      v-for="(team, key) in list"
      :key="key"
      :class="{ active: hash === team.hash }"
      class="team-btn"
      @click="selectTeam(team.hash)"
    >
      <team-avatar :src="team.imgLogo" />
      <div class="team-name">{{ team.name }}</div>
      <div class="team-status">
        <template v-if="team.status === 'formed'">
          <icon name="check" class="check" inline />
          {{ $t('teams.formed') }}
        </template>
        <template v-else>
          <icon name="clock" class="clock" inline />
          {{ $t('teams.building') }}
        </template>
      </div>
    </button>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'TeamSelect',
  components: {
    Icon,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    hash: null,
  }),
  methods: {
    selectTeam(hash) {
      this.hash = this.hash === hash ? null : hash;
      this.$emit('select', this.hash);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
}

.team {
  &-btn {
    padding: 0;
    outline: none;
    background-color: rgba(white, 0);
    border: none;
    color: white;

    @include min-tablet() {
      width: 25%;
      &:nth-child(n + 5) {
        margin-top: 30px;
      }
    }

    @include max-tablet() {
      width: 50%;
      &:nth-child(n + 3) {
        margin-top: 30px;
      }
    }

    &.active {
      color: $azure;

      .team-avatar {
        border-color: $azure;
      }
    }
  }

  &-avatar {
    font-size: 100px;
    width: 100px;
    height: 100px;
    border: 4px solid rgba(white, 0);
  }

  &-name {
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
    padding-right: 4px;
  }

  &-status {
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    color: rgba(white, 0.5);

    .icon {
      margin-right: 4px;
    }
  }
}

.clock {
  color: $gold;
}

.check {
  color: $azure;
}
</style>
