import api from '@utils/api';

export const fetchTerm = async ({ code, version, params = {} } = {}) => {
  let path = code ? `/${code}` : '';
  if (code && version) {
    path = `${path}/${version}`;
  }
  const term = await api.get(`/term${path}`, { params });
  return term;
};

export const fetchTermByCode = async (termCode) => {
  const url = `/term/${termCode}`;
  const term = await api.get(url);
  return term;
};

export const fetchAllTerms = async () => {
  const url = '/term?action=list';
  const terms = await api.get(url);
  return terms;
}
