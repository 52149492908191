<template>
  <img
    v-if="!error && src"
    class="organization-avatar"
    :src="src"
    @error="onError"
  />
  <Icon v-else class="organization-avatar" name="team" />
</template>

<script>
import { isNull, isString } from 'lodash';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    Icon,
  },

  props: {
    src: {
      required: true,
      validator: (value) => {
        return (
          isNull(value) || isString(value) || value === undefined
        );
      },
    },
  },

  data: () => ({
    error: false,
  }),

  methods: {
    onError() {
      this.error = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.organization-avatar {
  border-radius: 3px;

  &.icon {
    background-color: $slate-grey-three;
    color: $slate-grey-four;
  }
}
</style>
