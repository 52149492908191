import debounce from 'lodash/debounce';
import range from 'lodash/range';
import upperFirst from 'lodash/upperFirst';
import merge from 'lodash/merge';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import isNull from 'lodash/isNull';
import isNaN from 'lodash/isNaN';
import difference from 'lodash/difference';
import get from 'lodash/get';
import head from 'lodash/head';
import last from 'lodash/last';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import capitalize from 'lodash/capitalize';
import reduce from 'lodash/reduce';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import uniqueId from 'lodash/uniqueId';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import toLower from 'lodash/toLower';
import camelCase from 'lodash/camelCase';
import chunk from 'lodash/chunk';
import sortBy from 'lodash/sortBy';
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import escapeRegExp from 'lodash/escapeRegExp';
import padStart from 'lodash/padStart';
import isBoolean from 'lodash/isBoolean';

export default {
  debounce,
  range,
  upperFirst,
  merge,
  keyBy,
  map,
  isNull,
  isNaN,
  isObject,
  isArray,
  isString,
  isNumber,
  difference,
  get,
  head,
  last,
  capitalize,
  reduce,
  compact,
  uniq,
  uniqueId,
  keys,
  forEach,
  filter,
  flatten,
  toLower,
  camelCase,
  chunk,
  sortBy,
  cloneDeep,
  isEqual,
  escapeRegExp,
  padStart,
  isBoolean,
};
