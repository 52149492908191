<template>
  <div class="pd-personal-team">
    <InfoBox variant="warning">
      {{ $t('tournaments.pd-team-required-info') }}
    </InfoBox>

    <p>{{ $t('tournaments.pd-team-required-label') }}</p>

    <BaseInput
      ref="input"
      class="copy-input"
      :value="invitationLink"
      :label="$t('teams.invite_linkTitle')"
      :status="copied ? 'success' : ''"
      :message="copied ? $t('teams.invite_copied') : ''"
      :readonly="true"
      @click="onInputClick"
    >
      <template #after-input>
        <pvp-btn
          slot="append"
          variant="secondary"
          :block="appIsMobile"
          @click="copy"
          >{{ $t('global.copy') }}
        </pvp-btn>
      </template>
    </BaseInput>

    <InfoBox v-if="error" variant="error">{{ error }}</InfoBox>
  </div>
</template>

<script>
import { copyToClipboard } from '@utils/copyToClipboard';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'PersonalDataTeamRequired',
  components: {
    BaseInput,
    InfoBox,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      invitationLink: null,
      copied: false,
    };
  },

  computed: {
    ...mapGetters('application', ['appIsMobile']),
  },

  mounted() {
    const path = this.$router.resolve({
      name: 'profile-edit',
      hash: '#fcs',
    })?.href;
    this.invitationLink = `${window.location.origin}${path}`;
  },
  methods: {
    copy() {
      copyToClipboard(this.invitationLink);
      this.copied = true;
    },

    onInputClick(event) {
      return event?.target?.select();
    },
  },
};
</script>

<style lang="scss" scoped>
.pd-personal-team {
  display: inline-block;
  text-align: center;
}

.pd-label {
  margin-top: 10px;
}

.copy-input {
  margin-top: 20px;
}

.copy-input ::v-deep .input-wrapper {
  @include min-tablet() {
    display: flex;
  }
  @include max-tablet() {
    .button {
      width: 100%;
    }
  }
}
</style>
