<template>
  <div class="pagination pvp-pagination">
    <div class="pagination-pages">
      <button
        class="pagination-block pagination-button prev"
        :disabled="disabled || currentPage <= 1"
        @click="changePage(currentPage - 1)"
      >
        <Icon name="play" :size="12" />
      </button>
      <template v-for="(page, index) in pages">
        <div
          v-if="page.isDivider"
          :key="index + 'dots'"
          class="pagination-block pagination-divider"
        >
          <Icon name="paginator-more" :size="12" />
        </div>
        <button
          v-else
          :key="index"
          :disabled="disabled"
          class="pagination-block pagination-button"
          :class="{ active: page.isCurrent }"
          @click="changePage(page.num)"
        >
          {{ page.num }}
        </button>
      </template>
      <button
        :disabled="disabled || currentPage >= totalPages"
        class="pagination-block pagination-button next"
        @click="changePage(currentPage + 1)"
      >
        <Icon name="play" :size="12" />
      </button>
    </div>
    <div v-if="hasPerpage" class="pagination-perpage">
      <div class="perpage-text">{{ $t('global.showBy') }}:</div>
      <button
        v-for="(num, index) in perPageSizes"
        :key="index"
        :disabled="disabled"
        class="pagination-block pagination-button"
        :class="{ active: num === perPage }"
        @click="changePerPage(num)"
      >
        {{ num }}
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'PvpPagination',
  components: {
    Icon,
  },
  props: {
    params: {
      type: Object,
      required: true,
      validator(params) {
        const { total, page, perPage } = params;
        if (
          page === undefined ||
          total === undefined ||
          perPage === undefined
        ) {
          throw Error(
            'Обязательные параметры {page, total, perPage}',
          );
        }
        return true;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasPerpage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentPage: 0,
      perPage: 0,
      perPageSizeDefault: [],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.params.total / this.perPage);
    },
    pages() {
      const { currentPage, totalPages } = this;

      const pages = [
        {
          isCurrent: currentPage === 1,
          num: 1,
        },
      ];

      if (currentPage - 2 > 1) {
        pages.push({
          isDivider: true,
        });
      }

      for (let i = currentPage - 1; i <= currentPage + 1; i += 1) {
        if (i > 1 && i < totalPages) {
          pages.push({
            isCurrent: currentPage === i,
            num: i,
          });
        }
      }

      if (currentPage + 2 < totalPages) {
        pages.push({
          isDivider: true,
        });
      }

      if (totalPages && totalPages !== 1) {
        pages.push({
          isCurrent: currentPage === totalPages,
          num: totalPages,
        });
      }

      return pages;
    },
    perPageSizes() {
      const { perPageSizes } = this.params;
      return _.isArray(perPageSizes)
        ? perPageSizes
        : this.perPageSizeDefault;
    },
  },
  watch: {
    params: {
      handler() {
        this.currentPage = Number(this.params.page);
        this.perPage = Number(this.params.perPage) || 10;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const { perPage } = this.params;
    this.perPageSizeDefault = [perPage, 3 * perPage, 6 * perPage];
  },
  methods: {
    changePage(num) {
      if (this.disabled) {
        return;
      }
      this.currentPage = num;
      this.submitChanges();
    },
    changePerPage(num) {
      if (this.disabled) {
        return;
      }
      this.currentPage = 1;
      this.perPage = num;
      this.submitChanges();
    },
    submitChanges() {
      this.$emit('onchange', {
        page: this.currentPage,
        perPage: this.perPage,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  padding: 0;
  background: none;
  text-align: center;
}

.pagination {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  background: #2d2d30;
  border-radius: 4px;

  @include max-tablet() {
    justify-content: center;
  }

  @include min-laptop() {
    padding-left: 24px;
    padding-right: 24px;
  }

  .pagination-pages {
    display: flex;
    align-items: center;
  }

  .pagination-block {
    min-width: 32px;
    height: 32px;
    line-height: 28px;
    text-align: center;
    color: white;
    font-size: 13px;
  }

  .pagination-divider {
    color: rgba(white, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-button {
    border-radius: 4px;
    border: 2px solid transparent;

    outline: none;
    cursor: pointer;
    line-height: 100%;

    &:hover {
      color: white;
      border-color: rgba(white, 0.4);
    }

    &:active {
      color: white;
      background: linear-gradient(#2d2d30, #2d2d30) padding-box,
        linear-gradient(to bottom, #f87d28, #facc59) border-box;
    }

    &.active:disabled,
    &.active:disabled:hover,
    &.active,
    &.active:hover {
      color: white;
      background: linear-gradient(#2d2d30, #2d2d30) padding-box,
        linear-gradient(to bottom, #f87d28, #facc59) border-box;
    }

    &:disabled,
    &:disabled:hover {
      cursor: default;
      color: rgba(white, 0.2);
      border-color: transparent;
    }

    &.prev,
    &.next {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.prev {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .pagination-button + .pagination-button {
    margin-left: 6px;
  }

  .pagination-perpage {
    display: flex;
    align-items: center;

    @include max-tablet() {
      display: none;
    }

    .perpage-text {
      font-size: 13px;
      color: rgba(white, 0.5);
      margin-right: 12px;
    }
  }
}
</style>
