import { i18n } from '@src/localization/config';

export const spareCheck = (spareValue) => {
  const spareObject = {
    isSet: false,
    value: spareValue,
    message: '',
  };

  if (spareValue === 0) {
    spareObject.isSet = true;
    spareObject.message = i18n.t('registration.spare_not_allowed');
  }

  if (spareValue > 0) {
    spareObject.isSet = true;
    spareObject.message = i18n.t('registration.spareNum_text', {
      spare: i18n.tc('registration.spareNum_players', spareValue),
    });
  }

  return spareObject;
};
