<template>
  <div class="hs-pick-container">
    <template v-if="stage === 'pick'">
      <InfoBox>
        {{
          $t('pick.select_title', {
            classes: $tc(
              'pick.select_classes',
              hsSettings.classesQuantity,
            ),
          })
        }}
      </InfoBox>

      <div class="hs-heroes-container">
        <div
          v-for="(hero, key) in classes"
          :key="key"
          class="hero-picker"
          :class="{ active: pickedHeroes.includes(hero.name) }"
          @click="togglePick(hero.name)"
        >
          <hearthstone-hero :name="hero.name" />
          <div class="hero-title">{{ hero.title }}</div>
        </div>
      </div>
      <div class="controls">
        <pvp-btn :disabled="!canConfirmPicks" @click="confirmPick"
          >{{ $t('pick.chooseFew') }}
        </pvp-btn>
      </div>
    </template>

    <template v-if="stage === 'decks'">
      <InfoBox>
        {{ $t('pick.select_decks') }}
      </InfoBox>
      <div
        v-for="(deck, index) in classesDecks"
        :key="index"
        :class="{ indexed: classesDecks.length > 1 }"
        class="hs-pick-row"
      >
        <div class="hero-title">{{ deck.title }}</div>
        <div class="hs-pick-form">
          <div
            v-if="hsPickRequired || hsDecksRequired"
            class="hs-pick-hero"
          >
            <hearthstone-hero
              v-if="deck.class"
              :name="deck.class"
              :width="60"
            />
            <div v-else class="placeholder">?</div>
          </div>

          <BaseInput
            v-if="hsDecksRequired"
            v-model="deckstrings[index].code"
            rows="3"
            :is-area="true"
            :error-message.sync="errors[index]"
          />
        </div>
      </div>

      <InfoBox v-if="classesNotUniq" variant="error">
        {{ $t('pick.errors_decks') }}
      </InfoBox>

      <div class="controls">
        <pvp-btn
          v-if="hsPickRequired && !hsDecksRequired"
          variant="secondary"
          @click="resetPick"
          >{{ $t('global.back') }}
        </pvp-btn>
        <pvp-btn :disabled="!canFinish" @click="confirmDecks"
          >{{ $t('pick.downloadButton') }}
        </pvp-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { decode } from 'deckstrings';
import HearthstoneHero from '@components/GameComponents/HearthstoneHero/HearthstoneHero.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'HearthstonePick',
  components: {
    HearthstoneHero,
    BaseInput,
    InfoBox,
  },
  data() {
    return {
      classes: [
        {
          name: 'druid',
          title: this.$t('pick.hs_druid'),
          uid: 274,
        },
        {
          name: 'hunter',
          title: this.$t('pick.hs_hunter'),
          uid: 31,
        },
        {
          name: 'mage',
          title: this.$t('pick.hs_mage'),
          uid: 637,
        },
        {
          name: 'paladin',
          title: this.$t('pick.hs_paladin'),
          uid: 671,
        },
        {
          name: 'priest',
          title: this.$t('pick.hs_priest'),
          uid: 813,
        },
        {
          name: 'rogue',
          title: this.$t('pick.hs_rogue'),
          uid: 930,
        },
        {
          name: 'shaman',
          title: this.$t('pick.hs_shaman'),
          uid: 1066,
        },
        {
          name: 'warlock',
          title: this.$t('pick.hs_warlock'),
          uid: 893,
        },
        {
          name: 'warrior',
          title: this.$t('pick.hs_warrior'),
          uid: 7,
        },
        {
          name: 'demonhunter',
          title: this.$t('pick.hs_demonhunter'),
          uid: 56550,
        },
        {
          name: 'deathknight',
          title: this.$t('pick.hs_deathknight'),
          uid: 78065,
        },
      ],

      deckstrings: [],
      decksImages: {},

      decks: [],

      errors: {},

      pickedHeroes: [],

      stage: 'pick',
    };
  },
  computed: {
    ...mapState('tournamentRegistration', ['settings']),

    hsSettings() {
      return this.settings?.hearthstone;
    },

    hsPickRequired() {
      return this.hsSettings?.pickBanSystem;
    },

    hsDecksRequired() {
      return this.hsSettings?.hsLoadDeck;
    },

    classesDecks() {
      if (this.hsDecksRequired) {
        return this.deckstrings.map((deck, index) => {
          let hero;

          if (deck.code) {
            try {
              const [code] = deck.code.match(/^[^#].*$/gim);

              if (code) {
                const { heroes } = decode(code);
                const hsClassUid = heroes.pop();

                hero = this.classes.find(
                  (hsClass) => hsClass.uid === hsClassUid,
                );

                this.$delete(this.errors, index);
              }
            } catch (err) {
              this.$set(
                this.errors,
                index,
                this.$t('pick.errors_deckFormat'),
              );
            }
          }

          return {
            ...deck,
            title: hero ? hero.title : null,
            class: hero ? hero.name : null,
            hero,
          };
        });
      }

      return this.pickedHeroes.map((name, index) => {
        const hero = this.classes.find(
          (hsClass) => hsClass.name === name,
        );

        return {
          imageId: this.decksImages[index],
          title: hero ? hero.title : null,
          class: hero ? hero.name : null,
          hero,
        };
      });
    },

    canFinish() {
      if (this.hsDecksRequired) {
        return (
          !this.classesNotUniq &&
          this.classesDecks.filter((deck) => deck.hero).length ===
            this.classesQuantity
        );
      }

      return (
        Object.values(this.decksImages).length ===
        this.classesQuantity
      );
    },

    classesNotUniq() {
      const heroes = _.compact(
        this.classesDecks.map((deck) => deck.hero),
      );
      const uniqueHeroes = _.uniq(heroes);
      return uniqueHeroes.length < heroes.length;
    },

    // NEW
    classesQuantity() {
      return this.settings?.hearthstone?.classesQuantity;
    },

    canConfirmPicks() {
      return this.pickedHeroes.length === this.classesQuantity;
    },
  },
  created() {
    if (this.hsPickRequired && !this.hsDecksRequired) {
      this.stage = 'pick';
    } else {
      this.stage = 'decks';

      this.pickedHeroes = [
        {
          name: null,
        },
      ];
    }

    if (this.hsDecksRequired) {
      this.deckstrings = _.range(0, this.classesQuantity).map(() => ({
        code: null,
        hero: null,
      }));
    }
  },
  methods: {
    ...mapActions('tournamentRegistration', ['setHSPick']),

    save() {
      this.$emit('done', this.classesDecks);
    },

    resetPick() {
      this.pickedHeroes = [];
      this.setHSPick(this.pickedHeroes);
      this.stage = 'pick';
    },

    confirmPick() {
      this.setHSPick(this.pickedHeroes);

      if (this.hsDecksRequired) {
        this.stage = 'decks';
      } else {
        this.$emit('done');
      }
    },

    confirmDecks() {
      this.setHSPick(this.deckstrings.map((ds) => ds.code));
      const decks = this.deckstrings.map((ds) => ds.code);
      this.$emit('done', decks);
    },

    toggleImage(file, index) {
      const src = file?.[0]?.file;
      this[src ? '$set' : '$delete'](this.decksImages, index, src);
    },

    togglePick(name) {
      if (this.pickedHeroes.includes(name)) {
        this.$delete(
          this.pickedHeroes,
          this.pickedHeroes.indexOf(name),
        );
      } else if (this.pickedHeroes.length < this.classesQuantity) {
        this.pickedHeroes = _.compact([
          ...this.pickedHeroes.slice(),
          name,
        ]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hs-pick-container {
  counter-reset: list;
}

.hs-heroes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include min-tablet() {
    margin-left: -32px;
    margin-right: -32px;
  }
  @include max-tablet() {
    margin-left: -8px;
    margin-right: -8px;
  }
}

.hero-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  @include min-tablet() {
    margin: 0 32px 20px;
  }
  @include max-tablet() {
    margin: 16px;
  }

  &.active {
    .hero {
      box-shadow: inset 0 0 0 4px $azure;
      opacity: 1;
    }

    .hero-title {
      color: $azure;
      opacity: 1;
    }
  }

  @include min-laptop() {
    &:hover {
      .hero {
        opacity: 1;
      }

      .hero-title {
        opacity: 1;
      }
    }
  }
}

.hero {
  opacity: 0.5;
  @include min-laptop() {
    transition: all ease-in-out 0.2s;
  }
  @include max-tablet() {
    max-width: 92px;
    max-height: 92px;
  }
}

.hero-title {
  padding-top: 10px;
  color: #fff;
  opacity: 0.5;
  text-align: center;
  font-size: 14px;
  @include min-laptop() {
    transition: all ease-in-out 0.2s;
  }
  @include max-tablet() {
    font-size: 13px;
  }
}

.hs-pick-row {
  display: flex;
  flex-direction: column;
  margin: 0 0 30px;
  position: relative;

  &.indexed {
    margin-left: 35px;

    &:before {
      content: counter(list);
      counter-increment: list;
      position: absolute;
      left: -30px;
      top: 24px;
      font-size: 24px;
      font-weight: bold;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .hs-pick-headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .hero-title {
      padding: 0;
      text-align: left;
    }

    .hs-pick-notice {
      position: relative;
      color: rgba(#fff, 0.5);
      font-size: 14px;
      cursor: pointer;
      @include max-tablet() {
        font-size: 13px;
      }

      &:hover {
        .hs-helper {
          display: block;
          z-index: 5;
        }
      }

      .hs-notice-icon {
        margin-left: 6px;
        opacity: 0.5;
      }
    }
  }

  .hs-pick-form {
    @include min-tablet() {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .files-uploader {
    width: 100%;
    @include max-tablet() {
      margin-top: 12px;
    }
  }

  .hs-pick-hero {
    margin-right: 12px;
    flex-shrink: 0;

    .placeholder,
    .hero {
      border-radius: 3px;
    }

    .placeholder {
      width: 60px;
      border: 1px solid rgba(white, 0.1);
      background: rgba(black, 0.1);
      height: 60px;
      line-height: 58px;
      text-align: center;
      font-weight: bold;
      font-size: 33px;
    }
  }

  .hs-pick-index {
    margin-right: 23px;
    align-self: center;
    font-size: 24px;
    font-weight: bold;
  }

  .hero {
    opacity: 1;
  }
}

.pvp-info-box {
  margin-bottom: 20px;
}

.controls {
  text-align: center;

  .button + .button {
    margin-left: 12px;
  }

  @include max-tablet() {
    .button {
      width: 100%;
    }
    .button + .button {
      margin-top: 12px;
      margin-left: 0;
    }
  }
}
</style>
