export const dateFormats = {
  /** 00 января, 00:00 */
  dd_MMMM_HH_mm: {
    day: '2-digit',
    month: 'long',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  },
  /** 00 января 0000 г., 00:00 */
  dd_MMMM_YYYY_HH_mm: {
    day: '2-digit',
    month: 'long',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
  },
  /** 0 января, 00:00 */
  d_MMMM_HH_mm: {
    day: 'numeric',
    month: 'long',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  },
  /** 0 января 0000 г., 00:00 */
  d_MMMM_YYYY_HH_mm: {
    day: 'numeric',
    month: 'long',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
  },
  /** Пн, 00 янв., 00:00 */
  ddd_dd_MMM_HH_mm: {
    hour12: false,
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
  },
  /** 00 янв., 00:00 */
  dd_MMM_HH_mm: {
    hour12: false,
    day: '2-digit',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
  },
  /** Пн, 00 января, 00:00 */
  ddd_dd_MMMM_HH_mm: {
    hour12: false,
    weekday: 'short',
    day: '2-digit',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
  },
  /** 00.00.0000, 00:00 */
  dd_MM_YYYY_HH_mm: {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  /** 00.00.0000 */
  dd_MM_YYYY: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  /** 00.00.00 */
  dd_MM_YY: {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  },
  /* 00.00.00, 00:00 */ dd_MM_YY_HH_mm: {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  },
  /** 00.00, 00:00 */
  dd_MM_HH_mm: {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    day: '2-digit',
    month: '2-digit',
  },
  /** 00:00 */
  HH_mm: {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  },
  /** 00.00.0000 г., 00:00:00)) */
  dd_MM_YYYY_HH_mm_ss: {
    day: '2-digit',
    month: '2-digit',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    year: 'numeric',
  },
};
