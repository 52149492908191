<template>
  <div
    ref="wrapper"
    v-click-outside="() => onHover(false)"
    class="tt-wrapper"
    @mouseenter="onHover(true)"
    @mouseleave="onHover(false)"
    @touchstart="onHover(!isVisible)"
  >
    <slot />
    <div v-if="$slots.tooltip && isVisible" ref="tt" class="tt">
      <slot name="tooltip" />
    </div>
  </div>
</template>

<script>
function offset(el) {
  if (!el) {
    return {};
  }
  const rect = el.getBoundingClientRect();
  const scrollLeft =
    window.scrollX || document.documentElement.scrollLeft;
  const scrollTop =
    window.scrollY || document.documentElement.scrollTop;
  const top = rect.top + scrollTop;
  return {
    top,
    bottom: top + rect.height,
    left: rect.left + rect.width / 2 + scrollLeft,
  };
}

export default {
  data() {
    return {
      isVisible: false,
    };
  },
  beforeDestroy() {
    const { tt } = this.$refs;
    if (tt) {
      tt.remove();
    }
  },
  methods: {
    onHover(isVisible) {
      this.isVisible = isVisible;
      this.$nextTick(() => {
        if (isVisible && this.$slots.tooltip) {
          const { tt, wrapper } = this.$refs;
          document.body.appendChild(tt);
          tt.removeAttribute('style');

          const wrapperPos = offset(wrapper);

          const ttPos = tt.getBoundingClientRect();
          const pageWidth = document.body.clientWidth;

          const top = wrapperPos.bottom;
          const left = ((pos) => {
            if (pos < 0) {
              pos = 5;
            } else if (pageWidth <= pos + ttPos.width) {
              const calcPos = pageWidth - ttPos.width - 5;
              pos = pos > calcPos ? calcPos : pos;
            }
            return pos;
          })(wrapperPos.left - ttPos.width / 2);

          tt.style.top = `${top}px`;
          tt.style.left = `${left}px`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tt {
  position: absolute;
  margin-top: 5px;
  border-radius: 6px;
  background: #3c3c40;
  box-shadow: 0 2px 12px 0 rgba(black, 0.1);
  z-index: 2500;
  padding: 10px 16px;

  font-size: 12px;
  line-height: 16px;
  color: #f5f5f7;
  max-width: 310px;
}

.tt-triangle {
  width: 20px;
  height: 20px;
  position: absolute;
  background: red;
  bottom: 100%;
}

.tt-wrapper {
  display: inline-block;
}
</style>
