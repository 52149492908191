import VueMeta from 'vue-meta';
import Router from 'vue-router';
import store from '@state/store';
import routes from './routes';

Vue.use(Router);
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
});

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE_URL || '/',
  routes,
});

router.beforeEach(async (to, from, next) => {
  // if (/[/\\;(){}<>]/.test(to.query.backUrl)) {
  //   return next({ name: 'index' });
  // }

  if (to.query.backUrl) {
    const encodedQuery = encodeURIComponent(to.query.backUrl);
    to.query.backUrl = encodedQuery;
  }

  const destination = `${window.location.origin}${to.fullPath}`;

  if (to.query.loginOrigin) {
    await store.dispatch(
      'profile/setLoginOrigin',
      to.query.loginOrigin,
    );

    const query = Object.assign({}, to.query);

    delete query['loginOrigin'];

    return next({
      ...to,
      query,
    });
  }

  if (to.params.ignoreAuth || to.meta.ignoreAuth) {
    next();
  } else {
    if (to.meta.profileRequired) {
      await store.dispatch('profile/setLoginOrigin', 'pvpgg');
    }

    const hasMailAccount = store.getters['profile/hasMailAccount'];
    const isLoggedIn = store.getters['profile/isLoggedIn'];
    const profileHash =
      store.getters['profile/getCurrentProfileHash'];

    if (to.meta.authRequired && !hasMailAccount) {
      // Redirect to login page
      return next({
        name: 'login',
        query: { backUrl: destination },
      });
    }

    if (to.meta.authProhibited && hasMailAccount) {
      if (to.query.backUrl) {
        // eslint-disable-next-line require-atomic-updates
        window.location.href = to.query.backUrl;
      }

      // Redirect to home page
      return next({ name: 'index' });
    }

    if (to.meta.profileRequired && !isLoggedIn) {
      return next({
        name: 'registration',
        query: { backUrl: destination },
      });
    }

    if (to.meta.userProfileRoute) {
      // Redirect to current user profile page
      return next({
        name: to.meta.userProfileRoute,
        params: { hash: profileHash },
      });
    }

    if (
      to.meta.onlyForCurrentUser &&
      to.params.hash !== profileHash
    ) {
      // Redirect to target user profile page
      return next({
        name: 'profile',
        params: { hash: to.params.hash },
      });
    }

    if (to.meta.profileProhibited && isLoggedIn) {
      if (to.query.backUrl) {
        // eslint-disable-next-line require-atomic-updates
        window.location.href = to.query.backUrl;
      }

      // Redirect to home page
      return next({ name: 'index' });
    }

    next();
  }
});

export default router;
