/* eslint-disable */

import axios from 'axios';
import store from '@state/store';
import cookies from '@utils/cookies';
import { CustomError } from '@utils/CustomError';
import jsonp from 'jsonp';
import { pinia } from '@src/main';
import { useDebugStore } from '@src/shared/store/useDebugStore';

const mergeOptions = (options) => {
  const token = store.state?.profile?.profile?.token || null;

  options = {
    crossdomain: true,
    baseURL: process.env.VUE_APP_DOMAIN_URL,
    headers: {
      ...options.headers,
      'Accept-Language': cookies.getItem('language'),
    },
    withCredentials: true,
    ...options,
  };

  if (token) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return options;
};

const saveResponseLog = (data) => {
  const debugStore = useDebugStore(pinia);
  debugStore.addResponse(data);
};

const transform = (res) => {
  const data = res.data || res?.response?.data || res || {};
  const error = data?.errors || data?.error;
  const errorData = data?.errorData;

  if (error && Object.keys(error).length) {
    saveResponseLog({ error, errorData });
    throw new CustomError({ error, errorData });
  }

  return data;
};

const flatParams = (obj) => {
  const parseObject = (obj, path = []) => {
    let flattened = {};

    Object.keys(obj).forEach((key) => {
      const curPath = path.length ? [...path, `[${key}]`] : [key];

      if (obj[key] && typeof obj[key] === 'object') {
        flattened = {
          ...flattened,
          ...parseObject(obj[key], curPath),
        };
      } else {
        flattened[curPath.join('')] = obj[key];
      }
    });

    return flattened;
  };

  return parseObject(obj);
};

export default {
  get(url, params = {}) {
    const { needTransform = true, ...options } = params;
    if (options.params) {
      options.params = flatParams(options.params);
    }
    return axios
      .get(url, mergeOptions(options))
      .then((data) => {
        saveResponseLog(data);
        return data;
      })
      .then((data) => (needTransform ? transform(data) : data))
      .catch(transform);
  },

  post(url, data = {}, options = {}, config = {}) {
    const { needTransform = true } = config;
    return axios
      .post(url, data, mergeOptions(options))
      .then((data) => {
        saveResponseLog(data);
        return data;
      })
      .then((data) => (needTransform ? transform(data) : data))
      .catch(transform);
  },

  // Post without catch in macros
  postSimple(url, data = {}, options = {}) {
    return axios
      .post(url, data, mergeOptions(options))
      .then((data) => {
        saveResponseLog(data);
        return data;
      })
      .then(transform);
  },

  put(url, data = {}, options = {}) {
    return axios
      .put(url, data, mergeOptions(options))
      .then((data) => {
        saveResponseLog(data);
        return data;
      })
      .then(transform)
      .catch(transform);
  },

  patch(url, data = {}, options = {}) {
    return axios
      .patch(url, data, mergeOptions(options))
      .then((data) => {
        saveResponseLog(data);
        return data;
      })
      .then(transform)
      .catch(transform);
  },

  update(url, data = {}, options = {}) {
    let method = 'UPDATE';

    return axios({
      method,
      url,
      data,
      ...mergeOptions(options),
    })
      .then((data) => {
        saveResponseLog(data);
        return data;
      })
      .then(transform)
      .catch(transform);
  },

  delete(url, data, options = {}) {
    return axios({
      method: 'DELETE',
      url,
      data,
      ...mergeOptions(options),
    })
      .then((data) => {
        saveResponseLog(data);
        return data;
      })
      .then(transform)
      .catch(transform);
  },

  cdn(url, { needTransform = true } = {}) {
    return axios
      .get(url)
      .then((data) => (needTransform ? transform(data) : data))
      .catch(transform);
  },

  checkSdc(sdc_url, params = {}) {
    return new Promise((resolve) => {
      jsonp(sdc_url, params, (err, sdc_response) => {
        resolve(sdc_response);
      });
    });
  },
};
