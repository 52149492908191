<template>
  <div class="gems-header" />
</template>

<script>
export default {
  name: 'GemsHeader',
  data() {
    return {
      profileMenu: [
        {
          text: 'menu.profilevk',
          path: '/profile',
        },
        {
          text: 'profile.edit_single',
          path: '/profile/edit',
        },
        {
          text: 'tournaments.few',
          path: '/profile',
        },
        {
          text: 'games.few',
          path: '/profile/games',
        },
        {
          text: 'jobfair.profile_few',
          path: '/profile/teamfinder',
        },
        {
          text: 'teams.few',
          path: '/profile/teams',
        },
        {
          text: 'matches.few',
          path: '/profile/matches',
        },
        {
          text: 'ratings.few',
          path: '/profile/ratings',
        },
        {
          text: 'organizations.few',
          path: '/profile/organizations',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('profile', ['getCurrentProfile']),

    profile() {
      // глобаыльный метод, переписать когда откажемся от глобальных обьектов
      return this.getCurrentProfile;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.mountEmbeddedHeader();
      this.refreshProfile();
    },
    mountEmbeddedHeader() {
      const scriptHeader = document.createElement('script');
      window.__GEMS.elem = '.gems-header';
      window.__GEMS.profilemenu = this.profileMenu.map((item) => {
        return this.getProfileMenuItem(item.text, item.path);
      });
      scriptHeader.type = 'text/javascript';
      scriptHeader.src =
        'https://vkplay.ru/hotbox/gem_static/leela/header.js';
      document.querySelector('body').append(scriptHeader);
    },
    refreshProfile() {
      if (!this.profile.nick) return;
      window.__GEMS.custom_nickname = this.profile.nick;
      window.__GEMS.custom_avatar =
        this.profile.avatarUrl ||
        `https://avatar.my.games/avatar/${this.profile.ac_id}.jpeg`;
      window.__GEM?.renderHeader();
    },
    getProfileMenuItem(text, path) {
      return {
        text: this.$t(text),
        events: {
          click: () => {
            this.$router.push(path);
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
.gems-header {
  min-height: 66px;
}
.gems-header .ph-menu {
  position: static;
}
</style>
