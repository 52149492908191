<template>
  <div class="files-uploader">
    <div
      ref="uploader"
      :class="{
        dragover,
        hidden: !canUpload,
      }"
      class="drop-zone"
      @click="openAttachment"
    >
      <Icon name="photo-camera" class="camera" />
      <input
        ref="files"
        type="file"
        :multiple="multiple"
        @change="onFileSelect"
      />
      <i18n
        v-if="canDrop"
        tag="div"
        class="drop-zone-text"
        path="uploader.title"
      >
        <br slot="br" />
      </i18n>
      <pvp-btn class="button-upload" variant="secondary">
        {{ $t('uploader.button') }}
      </pvp-btn>
      <div class="drop-zone-helper">
        <div class="helper-text">
          {{ $t('uploader.formats', { formats: helperFormats }) }}
        </div>
        <div v-if="maxFileSize" class="helper-text">
          {{ $t('uploader.maxFileSize', { count: maxFileSize }) }}
        </div>
        <slot name="helper-text" />
      </div>
    </div>

    <div v-if="attachments.length" class="attachments-list">
      <div
        v-for="(attachment, key) in attachments"
        :key="key"
        class="list-item"
      >
        <div class="attachment-box">
          <div class="attachment-view">
            <div v-if="attachment.src" class="image">
              <img :src="attachment.src" />
              <Icon
                v-if="attachment.success"
                class="success"
                name="check-circle"
              />
            </div>
            <Icon
              v-else-if="attachment.success"
              class="success"
              name="check-circle"
            />
            <Icon
              v-else
              :name="attachment.loading ? 'time-circle' : 'info'"
            />
          </div>
          <div class="attachment-info">
            <div class="attachment-name">
              {{ attachment.file.name }}
            </div>
            <div
              v-if="attachment.error"
              class="attachment-status error"
            >
              {{ attachment.error }}
            </div>
            <div
              v-else-if="attachment.success"
              class="attachment-status success"
            >
              {{
                $t('uploader.fileSize', {
                  count: attachment.fileSize,
                })
              }}
            </div>
          </div>
          <button
            v-if="!attachment.loading && !attachment.success"
            class="button-delete"
            type="button"
            @click="removeAttachment(key)"
          >
            <Icon name="cross" :size="16" />
          </button>
          <div
            v-if="attachment.loading"
            class="attachment-status loading"
            :style="`width: ${attachment.loadedPercent}%`"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'FilesUploader',
  components: {
    Icon,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowedFormats: {
      type: Array,
      default: () => ['jpg', 'jpeg', 'png'],
    },
    maxFileSize: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    dragover: false,
    attachments: [],
  }),
  computed: {
    canUpload() {
      return (
        this.multiple || (!this.multiple && !this.attachments.length)
      );
    },

    helperFormats() {
      return this.allowedFormats.join(', ').toUpperCase();
    },

    canDrop() {
      const div = document.createElement('div');

      return (
        ('draggable' in div ||
          ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },
  },
  watch: {
    value: {
      handler(value) {
        if (this.canDrop) {
          this.attachments = value;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (this.canDrop) {
      this.toggleListeners('add');
    }
  },
  beforeDestroy() {
    if (this.canDrop) {
      this.toggleListeners('remove');
    }
  },
  methods: {
    toggleListeners(state) {
      ['drag', 'dragover', 'drop'].forEach((event) => {
        this.$refs.uploader[`${state}EventListener`](
          event,
          this.preventFileOpening,
          false,
        );
        window[`${state}EventListener`](
          event,
          this.preventFileOpening,
          false,
        );
      });

      [
        ['dragenter', this.onDragHover],
        ['dragleave', this.onDragHover],
        ['drop', this.onDrop],
      ].forEach(([event, callback]) => {
        this.$refs.uploader[`${state}EventListener`](event, callback);
      });
    },

    preventFileOpening(e) {
      e.preventDefault();
      e.stopPropagation();
    },

    onDragHover() {
      this.dragover = !this.dragover;
    },

    onDrop(e) {
      this.dragover = false;
      const files = this.multiple
        ? [...e.dataTransfer.files]
        : [_.head(e.dataTransfer.files)];
      this.mapFiles(files);
    },

    onFileSelect(event) {
      const files = [...event.target.files];
      this.mapFiles(files);
    },

    openAttachment() {
      if (this.canUpload) {
        this.$refs.files.click();
      }
    },

    mapFiles(files) {
      if (!this.canUpload) {
        return;
      }

      const validate = (file) => {
        const errors = [];

        if (
          !this.allowedFormats.includes(_.last(file.name.split('.')))
        ) {
          errors.push(this.$t('uploader.invalidExt'));
        }

        if (
          this.maxFileSize &&
          file.size / 1024 ** 2 > this.maxFileSize
        ) {
          errors.push(
            this.$t('uploader.maxFileSize', {
              count: this.maxFileSize,
            }),
          );
        }

        return errors.join(', ');
      };

      files.forEach(async (file) => {
        const src = await (async () => {
          if (/image/.test(file.type)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            const event = await new Promise((resolve) => {
              reader.onload = resolve;
            });

            return event.target.result;
          }
          return '';
        })();

        const error = validate(file);

        this.attachments.push({
          file,
          src,
          error,
          loading: false,
          success: false,
          loadedPercent: 0,
          fileSize: (file.size / 1024 / 1024).toFixed(2),
        });
        this.emitData();
      });
    },

    removeAttachment(index) {
      this.$delete(this.attachments, index);
      this.emitData();
    },

    emitData() {
      this.$emit('input', this.attachments);
    },
  },
};
</script>

<style lang="scss" scoped>
.files-uploader {
  color: rgba(white, 0.6);
  text-align: left;
}

.camera {
  pointer-events: none;
  @include min-laptop() {
    display: block;
    width: 34px;
    height: 34px;
    margin: 0 auto 8px;
    color: rgba(white, 0.1);
  }
  @include max-laptop() {
    display: none;
  }
}

.drop-zone {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px dashed rgba(white, 0.1);
  border-radius: 4px;
  transition: 200ms;
  cursor: pointer;

  &:hover {
    box-shadow: $default-box-shadow;
  }

  &.hidden {
    display: none;
  }

  &.dragover {
    background: rgba(white, 0.05);
  }

  &-text {
    pointer-events: none;
    font-size: 13px;
    @include max-laptop() {
      display: none;
    }
  }

  &-helper {
    pointer-events: none;
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &:not(.hidden) + .attachments-list {
    margin-top: 12px;
  }
}

input {
  display: none;
}

.button {
  &-upload {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &-delete {
    margin: 0 0 0 12px;
    cursor: pointer;
    color: rgba(white, 0.3);
    outline: none;
    border: none;
    background-color: rgba(white, 0);

    &:hover {
      color: rgba(white, 0.5);
    }
  }
}

.list-item {
  position: relative;
  background-color: rgba(black, 0.1);
  border-radius: 4px;
  overflow: hidden;

  & + .list-item {
    margin-top: 12px;
  }

  .upload-progress {
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: rgba(black, 0.2);
    width: 0;
    transition: 300ms;
  }
}

.attachment {
  &-box {
    display: flex;
    align-items: center;
    padding: 12px;
    position: relative;
    z-index: 2;
  }

  &-view {
    padding-right: 12px;

    .image {
      position: relative;

      img {
        width: 60px;
      }

      .icon {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .success {
      color: $dark-pastel-green;
    }
  }

  &-info {
    flex: 0 1 100%;
  }

  &-name {
    font-weight: 600;
    word-break: break-all;
  }

  &-status {
    position: relative;
    font-size: 12px;

    &.error {
      color: $orangey-red;
    }

    &.loading {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: rgba(white, 0.2);
    }
  }
}
</style>
