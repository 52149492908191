<template>
  <form class="email-form">
    <BaseForm class="inline" @submit="save">
      <BaseInput
        v-model="email"
        label="Email"
        :disabled="saveButtonDisabled"
        :error-message.sync="errors.email"
        :message="description"
        placeholder="example@mail.ru"
      >
        <template #after-input>
          <pvp-btn
            v-if="canChangeEmail"
            :is-loading="loadingState.update"
            :disabled="saveButtonDisabled"
            type="submit"
          >
            {{ $t('save.single') }}
          </pvp-btn>
        </template>
      </BaseInput>
    </BaseForm>

    <div
      v-if="canChangeEmail && getCurrentProfile.email"
      class="confirmation-box"
    >
      <i18n
        v-if="!getCurrentProfile.confirm"
        path="players.email_notConfirmed"
        tag="div"
        class="confirmation-text"
      >
        <b slot="email">{{ getCurrentProfile.email }}</b>
      </i18n>
      <pvp-btn
        v-if="canSendConfirmToken"
        :is-loading="loadingState.confirmation"
        @click="sendConfirmation"
      >
        {{ $t('players.email_sendConfirm') }}
      </pvp-btn>
      <div
        v-if="!getCurrentProfile.confirm"
        class="confirmation-text"
      >
        {{ $t('players.email_sended') }}
      </div>
    </div>
  </form>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';

export default {
  name: 'EmailForm',
  components: {
    BaseInput,
    BaseForm,
  },
  props: {},
  data: () => ({
    // State
    loadingState: {
      update: false,
      confirmation: false,
    },

    email: null,

    // Errors
    errors: {},
    success: false,
  }),
  computed: {
    ...mapGetters('profile', [
      'getCurrentProfileSettings',
      'getCurrentProfile',
    ]),

    canChangeEmail() {
      return this.getCurrentProfileSettings.canChangeEmail;
    },

    saveButtonDisabled() {
      return (
        !this.getCurrentProfileSettings.canChangeEmail ||
        !this.getCurrentProfileSettings.canEditProfile
      );
    },

    canSendConfirmToken() {
      return this.getCurrentProfileSettings.canSendConfirmToken;
    },

    description() {
      if (this.success) {
        return this.$t('players.email_success');
      }
      return this.canChangeEmail
        ? null
        : this.$t('players.email_cantEdit');
    },
  },
  created() {
    const profile = this.getCurrentProfile;

    this.email = profile.email;
  },
  methods: {
    ...mapActions('profile', ['fetchCurrentProfile']),
    save() {
      this.success = false;
      this.errors = {};
      this.loadingState.update = true;

      api
        .post('/profile/email', {
          email: this.email,
        })
        .then(() => {
          this.success = true;
        })
        .catch(({ error }) => {
          this.errors.email = error;
        })
        .finally(() => {
          this.fetchCurrentProfile().finally(() => {
            this.loadingState.update = false;
          });
        });
    },

    sendConfirmation() {
      this.loadingState.confirmation = true;

      api
        .post('/profile/sendtoken')
        .catch(({ error }) => {
          this.errors = _.reduce(
            error,
            (acc, err, field) => ({
              ...acc,
              [field]: err.replace(/[&]quot[;]/giu, '"'),
            }),
            {},
          );
        })
        .finally(() => {
          this.fetchCurrentProfile().finally(() => {
            this.loadingState.confirmation = false;
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.email-form {
  font-size: 14px;

  .confirmation-box {
    .confirmation-text {
      font-size: 12px;
      color: rgba(white, 0.8);
    }

    .button + .confirmation-text,
    .confirmation-text + .button {
      margin-top: 16px;
    }
  }
}

::v-deep .input-wrapper {
  @include min-tablet() {
    display: flex;
  }
  @include max-tablet() {
    .button {
      width: 100%;
    }
  }
}
</style>
