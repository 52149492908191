export default {
  getItem(key) {
    const data = window.localStorage.getItem(key);
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  },
  setItem(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data));
  },
  remove(key) {
    window.localStorage.removeItem(key);
  },
};
