import api from '@utils/api';
import { i18n } from '@src/localization/config';
import cookies from '@utils/cookies';
import scssContext from '@assets/scss/scss.context';

export const state = {
  // Games
  games: {},
  gamesByCode: {},
  gamesOrder: [],
  gamesDictionary: [],

  // Catalogue
  regions: {},
  countries: {},
  prizeTypes: {},
  countryRussiaId: {},

  wfShards: {},
  pubgShards: {},
  laShards: {},

  // Application
  appWidth: window.innerWidth,

  // Localization
  currentLanguage: 'ru',
  supportedLanguages: i18n.supportedLanguages,

  // Header
  scssContext,

  hsPaths: {},
  mrgAdv: {},

  localesUrl: {},
  pickbanTimeoutsList: [],

  // Personal Data PD
  fksPersonalDataList: [],

  tournamentMatchTeamPriorityStrategies: [],
};

export const getters = {
  // Games
  getGames: (state) => state.gamesOrder.map((id) => state.games[id]),
  getGame: (state) => (id) => state.games[id],
  getGameCode: (_, getters) => (id) => getters.getGame(id)?.code,
  getGameByCode: (state) => (code) => state.gamesByCode[code],
  getGameMessageById: (state) => (id) => {
    const code = state.gamesDictionary.find((g) => +g.id === +id);
    const [module, param] = Object.entries(
      code?.translation?.pattern,
    )?.[0];
    return `["${module}"]["${param}"]`;
  },

  // Countries & Regions
  getCountry: (state) => (id) => state.countries[id],
  getCountries: (state) => Object.values(state.countries),
  getRegions: (state) => Object.values(state.regions),

  getWfShards: (state) => Object.values(state.wfShards),
  getWfShard: (state, getters) => (code) =>
    getters.getWfShards.find((wf) => wf.code === +code),

  getPubgShards: (state) => Object.values(state.pubgShards),
  getPubgShard: (state, getters) => (code) =>
    getters.getPubgShards.find((pubg) => pubg.code === code),

  getLaShards: (state) => Object.values(state.laShards),
  getLaShard: (state, getters) => (code) =>
    getters.getLaShards.find((la) => la.code === code),

  getPrizeByType: (state) => (id) => {
    if (id === 'box') {
      return i18n.t('global.valuablePrizes');
    }
    return state.prizeTypes[id];
  },

  // Locale
  getLocaleRouteName: (state) => (name) => {
    if (!name) {
      return undefined;
    }
    name = name.replace('locale-', '');
    return state.currentLanguage === 'ru' ? name : `locale-${name}`;
  },

  // App size
  getAppSize: (state) =>
    Object.keys(scssContext.breakpoints).reduce((acc, size) => {
      if (state.appWidth > scssContext.breakpoints[size]) {
        acc = size;
      }
      return acc;
    }, 'sm'),

  appIsLarge: (state, getters) =>
    ['xl', 'lg'].includes(getters.getAppSize),
  appIsSmall: (state, getters) =>
    ['xs', 'sm', 'md'].includes(getters.getAppSize),
  appIsExtraLarge: (state, getters) => getters.getAppSize === 'xl',
  appIsMobile: (state, getters) =>
    ['xs', 'sm'].includes(getters.getAppSize),
  appIsTablet: (state, getters) => getters.getAppSize === 'md',

  // FKS Personal Data List
  getFksPersonalDataList: (state) =>
    state.fksPersonalDataList.map((name) => ({
      label: i18n.t(`fksapi.${name}`),
      value: name,
    })),
  getTournamentMatchTeamPriorityStrategies: (state) =>
    state.tournamentMatchTeamPriorityStrategies,
};

export const mutations = {
  SET_REGIONS(state, newValue) {
    state.regions = _.keyBy(
      _.map(newValue, (region) => ({
        ...region,
        id: Number(region.id),
      })),
      'id',
    );
  },

  SET_COUNTRY_RUSSIA_ID(state, id) {
    state.countryRussiaId = id;
  },

  SET_COUNTRIES(state, newValue) {
    state.countries = _.keyBy(
      _.map(newValue, (country) => ({
        ...country,
        id: Number(country.id),
      })),
      'id',
    );
  },

  SET_GAMES(state, games) {
    state.games = _.keyBy(games, 'id');
    state.gamesByCode = _.keyBy(games, 'code');
  },

  SET_GAMES_ORDER(state, newValue) {
    state.gamesOrder = newValue;
  },

  SET_GAMES_DICTIONARY(state, newValue) {
    state.gamesDictionary = newValue;
  },

  SET_PRIZE_TYPES(state, newValue) {
    state.prizeTypes = newValue;
  },

  SET_WF_SHARDS(state, value) {
    state.wfShards = value;
  },

  SET_PUBG_SHARDS(state, value) {
    state.pubgShards = value;
  },

  SET_LA_SHARDS(state, value) {
    state.laShards = value;
  },

  SET_CURRENT_LANGUAGE(state, lang) {
    state.currentLanguage = lang;
    i18n.locale = lang;
    cookies.setItem('language', lang, 365 * 24 * 3600, '/');
  },

  SET_HS_PATHS(state, paths) {
    state.hsPaths = paths;
  },

  SET_MRG_ADVERTISE(state, params) {
    state.mrgAdv = params;
  },

  SET_LOCALES_URL(state, urls) {
    state.localesUrl = urls;
  },

  SET_PICKBAN_TIMEOUTS(state, list) {
    state.pickbanTimeoutsList = list;
  },

  SET_FKS_PERSONAL_DATA_LIST(state, list) {
    state.fksPersonalDataList = list;
  },

  SET_TOURNAMENT_MATCH_TEAM_PRIORITY_STRATEGIES(state, list) {
    state.tournamentMatchTeamPriorityStrategies = list;
  },
};

export const actions = {
  fetchMainPageData({ dispatch }) {
    return api.get('/main/list').then(async (data) => {
      const slider = await Promise.all(
        data?.slider?.map(({ type, item }) => {
          // используем данные слайда видео минуя стор videos
          if (
            type === 'video' ||
            type === 'banner' ||
            type === 'news'
          )
            return { type, data: item };
          // TODO убрать вообще все из стора и работать с данными нормально
          return dispatch(
            {
              tournament: 'tournaments/storeTournament',
            }[type],
            item,
            { root: true },
          ).then((id) => ({ id, type }));
        }) || [],
      );
      const tournamentsIds = Promise.all(
        data?.tournaments?.list?.map(({ item }) =>
          dispatch('tournaments/storeTournament', item, {
            root: true,
          }),
        ) || [],
      );
      return Promise.all([slider, tournamentsIds]).then((result) => ({
        slider: result?.[0],
        tournamentsIds: result?.[1],
        news: data?.news,
        video: data?.video,
        ratings: data.ratings || [],
      }));
    });
  },

  fetchCatalogue({ commit, dispatch }, lang) {
    const parseCatalogue = (catalogue) => {
      // Game image urls
      const games = catalogue.games.map((game) => ({
        ...game,
        shortName: game.short_name || game.name,
      }));

      // Application data
      commit('SET_GAMES', games);
      commit(
        'SET_GAMES_ORDER',
        games.map((game) => game.id),
      );
      commit('SET_COUNTRIES', catalogue.countries);
      commit('SET_PRIZE_TYPES', catalogue.prizeTypes);
      commit('SET_COUNTRY_RUSSIA_ID', catalogue.countryRussiaId);
      commit(
        'SET_REGIONS',
        catalogue.regions[catalogue.countryRussiaId],
      );
      commit('SET_WF_SHARDS', catalogue.wfShardCodes);
      commit('SET_PUBG_SHARDS', catalogue.pubgShardCodes);
      commit('SET_LA_SHARDS', catalogue.laShardCodes);
      commit('SET_GAMES_DICTIONARY', catalogue.gameMessageDictionary);
      commit('SET_HS_PATHS', catalogue.hs);
      commit('SET_LOCALES_URL', catalogue.translations);
      commit('SET_PICKBAN_TIMEOUTS', catalogue.pickBanTimeoutsList);
      commit(
        'SET_FKS_PERSONAL_DATA_LIST',
        catalogue.fksPersonalDataDocs,
      );
      commit(
        'SET_TOURNAMENT_MATCH_TEAM_PRIORITY_STRATEGIES',
        catalogue.tournamentMatchTeamPriorityStrategies,
      );

      [
        // Matches data
        [
          'matches/SET_MATCH_PROTEST_STATUSES',
          catalogue.statuses.matchResults,
        ],
        [
          'matches/SET_MATCH_GAME_STATUSES',
          catalogue.statuses.matchGames,
        ],

        // Tournaments data
        [
          'tournaments/SET_TEAMS_IN_TOURNAMENTS_STATUSES',
          catalogue.statuses.teamInTn,
        ],
        ['tournaments/SET_STATUSES', catalogue.statuses.tournament],
        ['tournaments/SET_SYSTEMS', catalogue.statuses.systems],
        [
          'tournaments/SET_AVAILABLE_SYSTEMS',
          catalogue.statuses.availableSystems,
        ],

        // Players data
        ['users/SET_REQUIRED_FIELDS', catalogue.requiredPlayerFields],

        // Teams data
        ['teams/SET_STATUSES', catalogue.statuses.team],

        // Organization data
        [
          'organizations/SET_INVITE_ROLES',
          catalogue.organizationInviteRoles,
        ],
      ].forEach(([mutation, data]) =>
        commit(mutation, data, { root: true }),
      );

      return dispatch('changeLanguage', lang);
    };

    return api.get('/catalogue/list').then(parseCatalogue);
  },

  // TODO просмотреть метод по коду, подозрения на мертвый код
  changeLanguage({ state, commit, dispatch }, lang) {
    if (!Object.keys(state.supportedLanguages).includes(lang)) {
      return state.currentLanguage;
    }

    if (i18n.availableLocales.includes(lang)) {
      commit('SET_CURRENT_LANGUAGE', lang);
      return lang;
    }

    if (lang !== 'ru' && !i18n.availableLocales.includes('ru')) {
      return Promise.all([
        dispatch('fetchLocale', {
          lang: 'ru',
          updateLanguage: false,
        }),
        dispatch('fetchLocale', { lang }),
      ]).then(() => lang);
    }

    return dispatch('fetchLocale', { lang });
  },

  fetchLocale({ state, commit }, { lang, updateLanguage = true }) {
    const url = state.localesUrl?.[lang];

    const setLocale = ({ data }) => {
      i18n.setLocaleMessage(lang, data);
      if (updateLanguage) {
        commit('SET_CURRENT_LANGUAGE', lang);
      }
      return lang;
    };

    return api.cdn(url, { needTransform: false }).then(setLocale);
  },
};
