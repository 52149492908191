<template>
  <BaseSelect
    v-model="countryId"
    v-bind="$attrs"
    :readonly="appIsMobile"
    label-key="name"
    value-key="id"
    :options="countries"
    :placeholder="$t('players.country_select')"
  >
    <template v-slot:option="{ option }">
      <country-tag
        :id="option.id"
        :class="{ highlight: option.hasHighlight }"
      />
    </template>
    <template v-slot:selected-option="{ option }">
      <country-tag :id="option.id" />
    </template>
  </BaseSelect>
</template>

<script>
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  components: {
    BaseSelect,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters('application', ['getCountries', 'appIsMobile']),

    countryId: {
      get() {
        return this.value;
      },
      set(id) {
        this.$emit('input', id);
      },
    },
    countries() {
      const countries = [...this.getCountries].sort((a, b) => {
        const aLower = a.name.toLowerCase();
        const bLower = b.name.toLowerCase();
        return aLower > bLower ? 1 : -1;
      });
      const { first, russia, other } = countries.reduce(
        (obj, country) => {
          switch (country.code) {
            case 'cis':
            case 'naa':
            case 'eu':
              obj.first.push(country);
              break;
            case 'ru':
              obj.russia.push({
                ...country,
                hasHighlight: true,
              });
              break;
            default:
              obj.other.push(country);
          }
          return obj;
        },
        {
          first: [],
          russia: [],
          other: [],
        },
      );
      first.sort((a, b) => (a.code > b.code ? 1 : -1));
      other[0].hasHighlight = true;

      return [...first, ...russia, ...other];
    },
  },
};
</script>

<style lang="scss" scoped>
.highlight {
  width: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    border-top: 1px solid rgba(white, 0.2);
    position: absolute;
    left: em(-12px);
    right: em(-12px);
  }

  &:before {
    top: em(-6px);
  }
}
</style>
