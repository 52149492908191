import api from '@utils/api.js';

export const fetchTeam = (teamHash) => {
  const url = `/team/${teamHash}`;
  return api.get(url);
}

export const fetchTeamDetails = (teamHash) => {
  const url = `/team/${teamHash}/complex`;
  return api.get(url);
}

export const fetchTeamRatings = async ({ teamHash, pageNum = 1, pageSize = 8 }) => {
  const url = `/team/${teamHash}/ratings`;
  const params = {
    pageSize,
    pageNum: pageNum - 1,
  }
  return api.get(url, params);
}

export const fetchTeamMatches = ({ teamHash, pageNumber = 1, itemsPerPage = 8 }) => {
  const url = `/team/matches/list`;
  const params = {
    teamHash,
    pageNumber,
    itemsPerPage,
  };
  return api.post(url, params);
}

export const fetchTeamInvitation = (invitationHash) => {
  const url = `/team/invitation/${invitationHash}`;
  return api.get(url);
};

export const createTeam = ({ name, numMembers, idGame, idCountry }) => {
  const url = `/team/create`;
  return api
      .post(url, {
        form: {
          name,
          numMembers,
          idGame,
          idCountry,
        },
      });
};

export const updateTeamInfo = ({ teamHash, name, idCountry, website, imgLogo }) => {
  const url = `/team/${teamHash}/update`;
  return api
      .post(url, {
        form: {
          name,
          idCountry,
          website,
          imgLogo,
        },
      });
};

export const changeCaptainRole = ({ teamHash, userHash }) => {
  const url = `/team/${teamHash}/player/captain`;
  return api.update(url, {
    hash: userHash,
  });
};

export const changeMemberRole = ({ teamHash, userHash }) => {
  const url = `/team/${teamHash}/player/swap`;
  return api.update(url, { hash: userHash });
};

export const deleteTeamMember = ({ teamHash, userHash }) => {
  const url = `/team/${teamHash}/player/delete`;
  return api.delete(url, { hash: userHash });
};

export const getInvitationLink = (teamHash) => {
  const url = `team/${teamHash}/invitation`;
  return api.get(url);
};

export const renewInvitationLink = (teamHash) => {
  const url = `team/${teamHash}/invitation/renew`;
  return api.get(url);
};

export const deleteTeam = (teamHash) => {
  const url = `team/${teamHash}/delete`;
  return api.post(url);
};

export const leaveTeam = (teamHash) => {
  const url = `/team/${teamHash}/player/leave`;
  return api.update(url);
}

