<template>
  <label class="label">
    <span
      v-if="label"
      class="input-label"
      :class="{ required: $attrs.required !== undefined }"
    >
      {{ label }}
    </span>
    <textarea
      v-if="isArea"
      v-bind="$attrs"
      :class="classNames"
      class="input"
      v-on="listeners"
    />
    <span v-else class="input-wrapper">
      <slot name="before-input" />
      <input
        v-bind="$attrs"
        :class="classNames"
        class="input"
        v-on="listeners"
      />
      <slot name="after-input" />
    </span>
    <div v-if="msg" class="message" :class="classNames">
      {{ msg }}
    </div>
  </label>
</template>

<script>
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    isArea: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    classNames() {
      if (this.errorMessage) {
        return 'error';
      }
      return this.status;
    },
    msg() {
      return this.errorMessage || this.message;
    },
    listeners() {
      return this.disabled
        ? undefined
        : {
            ...this.$listeners,
            input: (event) => {
              if (this.errorMessage) {
                this.$emit('update:error-message', '');
              }
              if (this.status) {
                this.$emit('update:status', '');
              }
              this.$emit('input', event.target.value);
            },
          };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables.scss';
@import '../../../assets/scss/_mixins.scss';

.label {
  width: 100%;
  text-align: left;
  margin-bottom: 0;

  .select + &,
  & + & {
    margin: em(16px) 0 0;
  }
}

textarea.input {
  min-height: 4em;
}

.clickable .input {
  cursor: pointer;
}

.input {
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid rgba(white, 0.1);
  background-color: rgba(black, 0.1);
  font-size: 14px;
  padding: 8px 10px;
  color: white;
  resize: vertical;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  color: white;

  &[disabled] {
    cursor: not-allowed;
    background-color: rgba(white, 0);
    color: rgba(white, 0.3);
  }

  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &:focus {
    outline: none;
    border-color: rgba(white, 0.3);
  }

  &.success {
    border-color: $dark-pastel-green;
  }

  &.error {
    border-color: $orangey-red;
  }

  &-label {
    display: block;
    color: rgba(white, 0.5);
    font-size: em(14px);

    &.required:after {
      content: '*';
    }

    & + .input-wrapper,
    & + .input {
      margin-top: em(6px);
    }
  }
}

.message {
  font-size: em(13px);
  color: rgba(white, 0.4);
  margin-top: em(8px);
  line-height: 1.25;

  &.success {
    color: $dark-pastel-green;
  }

  &.error {
    color: $orangey-red;
  }
}

.input-wrapper {
  position: relative;
  display: block;
}
</style>
