import { render, staticRenderFns } from "./SimpleAccount.vue?vue&type=template&id=25546ed8&scoped=true"
import script from "./SimpleAccount.vue?vue&type=script&lang=js"
export * from "./SimpleAccount.vue?vue&type=script&lang=js"
import style0 from "./SimpleAccount.vue?vue&type=style&index=0&id=25546ed8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25546ed8",
  null
  
)

export default component.exports