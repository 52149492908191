class FSM {
  constructor({ state, transitions }, context) {
    if (!state) {
      throw new Error('fsm initial state required');
    }
    if (!transitions) {
      throw new Error('fsm transitions required');
    }

    this.state = state;
    this.transitions = transitions;
    this.scope = context;

    this.dispatch = this.dispatch.bind(this);
    this.setState = this.setState.bind(this);

    return {
      dispatch: this.dispatch,
    };
  }

  dispatch(transition, params) {
    const action = this.transitions[this.state]?.[transition];
    if (typeof action === 'function') {
      action.apply(this.scope, [{ setState: this.setState }, params]);
    }
  }

  setState(state, params) {
    this.state = state;
    this.dispatch('enter', params);
  }
}

export const Fsm = {
  install(Vue) {
    if (this.installed) {
      return;
    }
    this.installed = true;

    Vue.prototype.fsmTransition = function (transition, params) {
      const { fsm } = this.$options;
      if (fsm === undefined) {
        return false;
      }
      if (this.fsmInstance === undefined) {
        this.fsmInstance = new FSM(fsm, this);
      }

      return this.fsmInstance.dispatch(transition, params);
    };
  },
};
