<template>
  <Icon :name="code" group="games" :inline="inline" />
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

const map = {
  fixb: 'fi',
  fipn: 'fi',
};

export default {
  components: {
    Icon,
  },
  props: {
    name: {
      type: String,
      default: 'oth',
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isError: false,
  }),
  computed: {
    code() {
      if (this.isError) {
        return 'oth';
      }
      return map[this.name] || this.name;
    },
  },
};
</script>
