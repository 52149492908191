var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"select",staticClass:"select"},[(_vm.label)?_c('span',{staticClass:"select-label",class:{ required: _vm.required }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.readonly && _vm.appIsMobile)?_c('div',{staticClass:"select-real select-wrapper"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedIndex),expression:"selectedIndex"}],class:[_vm.statusData, { placeholder: _vm.selectedIndex === -1 }],attrs:{"required":_vm.required,"disabled":_vm.disabled},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedIndex=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.onSelectChange]}},[(_vm.placeholder)?_c('option',{staticClass:"placeholder",attrs:{"disabled":"","selected":""},domProps:{"value":-1}},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),_vm._l((_vm.filteredOptions),function(option,key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(" "+_vm._s(_vm.labelKey && option ? option[_vm.labelKey] : option)+" ")])})],2),_c('Icon',{staticClass:"nav-icon",attrs:{"name":"arrow-down"}})],1):_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.toggleList(false)),expression:"() => toggleList(false)"}],staticClass:"select-wrapper"},[(_vm.isLimit === false)?_c('BaseInput',_vm._g(_vm._b({class:{
        clickable: _vm.readonly,
        multiple: _vm.multiple && _vm.selected.length,
      },attrs:{"placeholder":_vm.placeholderInfo,"required":_vm.required,"disabled":_vm.disabled,"readonly":_vm.readonly,"status":_vm.statusData},on:{"focus":function($event){return _vm.toggleList(true)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.toggleList(false)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.onArrowClick(1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.onArrowClick(-1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnter.apply(null, arguments)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return ((event) => event.target.blur()).apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'BaseInput',_vm.$attrs,false),_vm.listeners),[(!_vm.search && _vm.selected.length && !_vm.multiple)?_c('span',{staticClass:"selected",class:{ disabled: _vm.disabled },attrs:{"slot":"before-input"},slot:"before-input"},[_vm._l((_vm.selected),function(option){return [_vm._t("selected-option",function(){return [_vm._v(" "+_vm._s(_vm.labelKey && option ? option[_vm.labelKey] : option)+" ")]},{"option":option})]})],2):_vm._e(),_c('div',{staticClass:"select-right",attrs:{"slot":"after-input"},slot:"after-input"},[(_vm.isLoading)?_c('loader',{staticClass:"loading",attrs:{"slot":"after-input","size":16},slot:"after-input"}):(
            !_vm.required && _vm.clearable && _vm.selected.length && !_vm.multiple
          )?_c('button',{staticClass:"clear",attrs:{"disabled":_vm.disabled,"type":"button"},on:{"click":_vm.clearSelected}},[_c('Icon',{staticClass:"nav-icon",attrs:{"name":"cross"}})],1):(_vm.filteredOptions.length)?_c('Icon',{staticClass:"nav-icon",attrs:{"name":_vm.isOpen ? 'arrow-up' : 'arrow-down'}}):_vm._e()],1)]):_vm._e(),(_vm.multiple && _vm.selected.length)?_c('div',{staticClass:"selected-multiple",class:[_vm.statusData, { disabled: _vm.disabled }]},[_vm._l((_vm.selected),function(option){return [_vm._t("selected-option",function(){return [_c('pvp-btn',{attrs:{"icon-right":"cross","disabled":_vm.disabled},on:{"click":function($event){return _vm.removeItem(option)}}},[_vm._v(" "+_vm._s(_vm.labelKey && option ? option[_vm.labelKey] : option)+" ")])]},{"option":option})]})],2):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen && !_vm.isLoading && !_vm.disabled),expression:"isOpen && !isLoading && !disabled"}],ref:"list",staticClass:"list",class:_vm.listPositionName,style:(_vm.listStyle)},_vm._l((_vm.filteredOptions),function(option,key){return _c('div',{key:key,staticClass:"list-item",class:{
          active: key === _vm.position,
          disabled: option && option.disabled,
        },on:{"click":(event) => _vm.selectOption(event, option)}},[_vm._t("option",function(){return [_vm._v(" "+_vm._s(_vm.labelKey && option ? option[_vm.labelKey] : option)+" ")]},{"option":option})],2)}),0)],1),(_vm.messageData)?_c('div',{staticClass:"message",class:_vm.statusData},[_vm._v(" "+_vm._s(_vm.messageData)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }