<template>
  <pvp-modal width="532" :value="true" @input="send('close')">
    <loader v-if="state.context.isLoading" />

    <template
      v-else-if="state.matches('binding') && termStore.currentTerm"
    >
      <div slot="modal-title">{{ termStore.currentTerm.title }}</div>

      <div class="transfer-box">
        <InfoBox variant="empty">
          <div class="profile">
            <user-avatar :src="profile.avatarUrl" />
            <div class="profile-nick">{{ profile.nick }}</div>
          </div>
        </InfoBox>
      </div>

      <div v-if="termStore.currentTerm.text" class="transfer-box">
        <InfoBox class="transfer-box">
          {{ toPlainText(termStore.currentTerm.text) }}
        </InfoBox>
      </div>

      <div class="btn-box">
        <pvp-btn variant="secondary" @click="send('decline')">
          {{ $t('global.refuse') }}
        </pvp-btn>
        <pvp-btn @click="send('approve')">
          {{ $t('global.confirm') }}
        </pvp-btn>
      </div>

      <div class="transfer-description">
        {{ $t('profile.transfer_warning') }}
      </div>
    </template>

    <template v-else-if="state.matches('refuseConfirmation')">
      <div slot="modal-title">
        {{ $t('profile.unbinding_account') }}
      </div>

      <InfoBox variant="warning">
        {{ $t('profile.transfer_last-warning') }}
      </InfoBox>

      <div class="btn-box">
        <pvp-btn variant="secondary" @click="send('cancelBlocking')">
          {{ $t('global.cancel') }}
        </pvp-btn>
        <pvp-btn @click="send('blocking')">
          {{ $t('global.confirm') }}
        </pvp-btn>
      </div>
    </template>

    <template
      v-else-if="
        state.matches('blocked') || state.matches('alreadyBlocked')
      "
    >
      <div slot="modal-title">
        {{ $t('profile.binding_account') }}
      </div>
      <InfoBox variant="error">
        {{ $t('profile.transfer_blocked') }}
      </InfoBox>
      <div class="btn-box">
        <pvp-btn
          href="https://support.vkplay.ru/pvp/questions/2655"
          target="_blank"
          variant="secondary"
        >
          {{ $t('organizations.info_support_link') }}
        </pvp-btn>
      </div>
    </template>

    <div v-else-if="state.matches('error')" class="error">
      <icon name="cross-circle" :inline="false" />
      <div class="error-title">
        {{ $t('organizations.errors_someError') }}
      </div>
      <div class="btn-box">
        <pvp-btn @click="send('close')">{{
          $t('global.done')
        }}</pvp-btn>
      </div>
    </div>

    <div v-else-if="state.matches('success')" class="success">
      <icon name="check-circle" :inline="false" />
      <div class="success-title">
        {{ $t('profile.binding_success_account') }}
      </div>
      <div class="btn-box">
        <pvp-btn @click="send('close')">
          {{ $t('global.done') }}
        </pvp-btn>
      </div>
    </div>
  </pvp-modal>
</template>

<script>
import { useMachine } from 'xstate-vue2';
import transferProfile from '@src/machines/transferProfile.js';
import Icon from '@components/v2/utils/Icon.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import { useTermStore } from '@src/shared/store/useTermStore';

export default {
  name: 'ProfileTransfer',

  components: {
    Icon,
    InfoBox,
  },

  props: {
    transferTerm: {
      type: [Number, String],
      required: true,
    },
  },

  setup(props) {
    const termStore = useTermStore();
    termStore.fetchTerms({ code: props.transferTerm });
    return { termStore };
  },

  data() {
    return {
      state: null,
      send: null,
    };
  },

  computed: {
    ...mapState('profile', ['profile']),
  },

  created() {
    this.runTransferStateMachine();
  },
  methods: {
    toPlainText(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.innerText;
    },

    runTransferStateMachine() {
      const { state, send } = useMachine(transferProfile, {
        context: {
          transferStatus: this.profile?.transferStatus,
        },
      });
      this.state = state;
      this.send = send;
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer {
  &-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    @include min-tablet() {
      font-size: 24px;
      padding-left: 30px;
      padding-right: 30px;
    }
    @include max-tablet() {
      font-size: 20px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &-box {
    & + & {
      margin-top: 20px;
    }
  }

  &-description {
    text-align: center;
    margin-top: 20px;
    color: rgba(white, 0.7);
  }
}

.user-avatar {
  &,
  &.icon {
    width: 32px;
    height: 32px;
  }

  flex-shrink: 0;
}

.profile {
  display: flex;
  align-items: center;

  &-title {
    margin-bottom: 10px;
  }

  &-nick {
    margin-left: 12px;
  }
}

.button {
  & + & {
    margin-left: 20px;
  }

  @include min-tablet() {
    min-width: 160px;
  }
  @include max-tablet() {
    min-width: 120px;
  }
}

.btn-box {
  margin-top: 28px;
  text-align: center;
}

.error,
.success {
  text-align: center;

  &-title {
    font-weight: bold;
    margin-top: 20px;
    @include min-tablet() {
      font-size: 24px;
    }
    @include max-tablet() {
      font-size: 20px;
    }
  }

  .icon {
    width: 32px;
    height: 32px;
  }
}

.success {
  .icon {
    color: $dark-pastel-green;
  }
}

.error {
  .icon {
    color: $orangey-red;
  }
}
</style>
