export const updateUserProfile = ({
  data,
  profile,
  profileFields,
  reload,
}) => {
  if (reload) {
    window.location.reload();
  }
  // eslint-disable-next-line no-underscore-dangle
  window.__GEMS.custom_nickname =
    profile?.nick ?? profileFields.nickname;
  window.__GEMS.custom_avatar =
    profile?.avatarUrl ??
    `https://avatar.my.games/avatar/${data.ac_id}.jpeg`;

  if (data.profile_fields.migrated) {
    // eslint-disable-next-line no-underscore-dangle
    window.__GEMS.current_user.profile_fields.migrated =
      data.profile_fields.migrated;
  }
  // eslint-disable-next-line no-underscore-dangle
  window.__GEM.renderHeader();
};
