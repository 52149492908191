<template>
  <transition name="slide-fade">
    <div v-if="value" :class="['pvp-modal', { opened: value }]">
      <div
        :class="['pvp-modal-overlay', { permanent }]"
        @click="hideModal"
      ></div>

      <div class="pvp-modal-box" :style="boxStyle">
        <button
          v-if="!permanent"
          type="button"
          class="pvp-modal-close-button"
          @click="hideModal"
        >
          <icon name="cross" :size="16" />
        </button>
        <div class="pvp-modal-title">
          <slot name="modal-title" />
        </div>
        <div class="pvp-modal-body">
          <slot />
        </div>
        <div v-if="$slots['modal-footer']" class="pvp-modal-footer">
          <slot name="modal-footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'PvpModal',
  components: {
    Icon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: '',
    },
    permanent: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    opened: false,
    scrollTop: null,
  }),
  computed: {
    ...mapGetters('application', ['appIsMobile']),

    ...mapGetters('application', ['appIsMobile']),

    boxStyle() {
      return (
        this.width &&
        !this.appIsMobile && {
          width: '100%',
          maxWidth: `${this.width}px`,
        }
      );
    },
  },
  watch: {
    value: {
      handler(value) {
        this.toggleModalCount(value ? 1 : -1);
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.toggleModalCount(-1);
  },
  methods: {
    hideModal() {
      if (this.permanent === false) {
        this.$emit('input', false);
      }
    },

    toggleModalCount(way) {
      const prevCounts = Number(
        document.body.getAttribute('data-modal-count') || 0,
      );
      const currentCounts = prevCounts + way;

      if (currentCounts > 0) {
        this.scrollTop = this.scrollTop || window.pageYOffset;
        document.body.setAttribute('data-modal-count', currentCounts);
      } else {
        document.body.removeAttribute('data-modal-count');
        this.$nextTick(() => {
          this.scrollTop = null;
        });
      }
      if (this.appIsMobile) {
        window.scrollTo({ top: this.scrollTop });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-modal {
  &.opened {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0;
    z-index: 10000;
    padding-top: 104px;
    @include min-tablet() {
      padding: 15px 0;
    }
  }

  &-title {
    text-align: center;
    font-weight: bold;
    display: block;
    padding-top: 30px;

    &:not(:empty) {
      @include min-tablet() {
        font-size: 24px;
        padding-left: 30px;
        padding-right: 30px;
      }
      @include max-tablet() {
        font-size: 20px;
        padding-left: 12px;
        padding-right: 12px;
      }

      & + .pvp-modal-body {
        margin-top: 20px;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  &-close-button {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    color: rgba(white, 0.4);
    line-height: 1;
    padding: 0;
    margin: 0;
    height: 45px;
    width: 45px;

    &:hover {
      color: white;
    }
  }

  &-body {
    overflow: auto;
    max-width: 100%;
    flex-grow: 1;
    padding-bottom: 30px;
  }

  &-footer {
    text-align: center;
    padding-bottom: 30px;
  }

  &:not(.full-width) {
    .pvp-modal-footer:not(:empty),
    .pvp-modal-body {
      @include min-tablet() {
        padding-left: 30px;
        padding-right: 30px;
      }
      @include max-tablet() {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  &-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(black, 0.4);
    cursor: pointer;

    &.permanent {
      cursor: default;
    }
  }

  &-box {
    position: relative;
    background: $dark-two;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    @include min-tablet() {
      border-radius: 8px;
    }
    @include max-tablet() {
      height: 100%;
      width: 100%;
    }
  }
}

.slide-fade {
  &-leave,
  &-enter {
    &-active {
      &,
      .pvp-modal-overlay,
      .pvp-modal-box {
        transition: all 0.2s linear;
      }
    }
  }

  &-enter {
    .pvp-modal {
      &-overlay {
        opacity: 0;
      }

      &-box {
        transform: translateY(-20px);
      }
    }
  }

  &-leave-to {
    .pvp-modal {
      &-overlay {
        opacity: 0;
      }

      &-box {
        opacity: 0;
        transform: translateY(20px);
      }
    }
  }
}
</style>
