<template>
  <pvp-modal
    :value="modalIsOpened"
    width="580"
    @input="fsmTransition('close')"
  >
    <div slot="modal-title">{{ modalTitle }}</div>

    <loader v-if="isLoading" />
    <div v-if="modalIsOpened" class="modal-body">
      <steps
        v-if="statesList.length > 2"
        :step="currentStepIndex + 1"
        :counts="statesList.length"
      />

      <InfoBox v-if="infobox.length" variant="warning">
        <!-- eslint-disable vue/no-v-html -->
        <p v-for="(msg, key) in infobox" :key="key" v-html="msg"></p>
      </InfoBox>

      <component
        :is="activeComponent.name"
        v-if="activeComponent.name"
        :class="activeComponent.cls"
        v-bind="activeComponent.props"
        class="state"
        v-on="activeComponent.methods"
      />
    </div>

    <div v-if="footerBtn.text" slot="modal-footer">
      <pvp-btn @click="footerBtn.action">
        {{ footerBtn.text }}</pvp-btn
      >
    </div>
  </pvp-modal>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import { spareCheck } from '@utils/spareChecker';
import TermsAcceptForm from '@components/ProfileComponents/TermsAcceptForm.vue';
import GameAccountLinker from '@components/ProfileComponents/GameAccount/GameAccountLinker.vue';
import Steps from '@components/BaseComponents/Steps.vue';
import PersonalForm from '@components/ProfileComponents/ProfileEdit/PersonalForm.vue';
import NewTeamForm from '@components/TeamComponents/TeamManagement/NewTeamForm.vue';
import RegistrationFinish from '@components/TournamentComponents/Registration/RegistrationFinish.vue';
import HearthstonePick from '@components/TournamentComponents/Registration/HearthstonePick.vue';
import PremiumBanner from '@components/TournamentComponents/Premium/PremiumBanner.vue';
import ProfileEditContacts from '@components/ProfileComponents/ProfileEdit/ProfileEditContacts.vue';
import FcsAccountLinker from '@components/ProfileComponents/FcsAccountLinker.vue';
import TeamSelect from '@components/TeamComponents/TeamManagement/TeamSelect.vue';
import PersonalDataSoloRequired from '@components/TournamentComponents/PersonalData/PersonalDataSoloRequired.vue';
import PersonalDataTeamRequired from '@components/TournamentComponents/PersonalData/PersonalDataTeamRequired.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'RegistrationModal',
  components: {
    TeamSelect,
    FcsAccountLinker,
    ProfileEditContacts,
    PremiumBanner,
    HearthstonePick,
    RegistrationFinish,
    NewTeamForm,
    PersonalForm,
    Steps,
    GameAccountLinker,
    TermsAcceptForm,
    PersonalDataSoloRequired,
    PersonalDataTeamRequired,
    InfoBox,
  },
  data: () => ({
    isLoading: false,
    footerBtn: {
      text: '',
      action: '',
    },
    modalTitle: '',
    statesList: [],
    currentStepIndex: 0,
    activeComponent: {
      name: '',
      props: {},
      cls: '',
    },
    infobox: [],
    hsDeckImages: [],
    team: null,
    error: null,
  }),

  fsm: {
    state: 'CLOSED',
    transitions: {
      CLOSED: {
        enter() {
          this.setModalState(false);
          this.skipAll();
          if (this.teamsList?.length > 1) {
            this.team = null;
          }
        },

        register: ({ setState }) => setState('OPEN'),
      },

      OPEN: {
        enter({ setState }) {
          this.modalTitle = this.$t('registration.title');

          const list = [];

          if (this.requirementsError) {
            return setState('REQUIREMENTS_ERROR');
          }

          if (this.isNeedFksPDFields && this.isSoloTournament) {
            return setState('FCS_PERSONAL_DATA_SOLO_REQURED');
          }

          if (this.isNeedFksPDFields && this.isTeamTournament) {
            return setState('FCS_PERSONAL_DATA_TEAM_REQURED');
          }

          if (this.settings?.terms?.length) {
            list.push('TERMS_CHECK');
          }

          if (this.isFcsRequired) {
            list.push('FCS_CHECK');
          }

          if (this.gameRequirements.needCheck) {
            list.push('GAME_ACCOUNT_CHECK');
          }

          if (this.requiredFields.profile.length) {
            list.push('PROFILE_FORM');
          }

          if (this.requiredFields.contacts.length) {
            list.push('CONTACTS_FORM');
          }

          if (this.needTeamCheck) {
            list.push('TEAM_CHECK');
          }

          list.push('FINISH');

          this.statesList = list;

          return setState(this.statesList[this.currentStepIndex]);
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      FCS_PERSONAL_DATA_SOLO_REQURED: {
        enter({ setState }) {
          this.activeComponent = {
            name: 'personal-data-solo-required',
            props: {
              error: this.error,
            },
          };
          this.footerBtn = {
            text: this.$t('global.fill'),
            action: () => {
              this.openPdPopup();
              setState('CLOSED');
            },
          };
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      FCS_PERSONAL_DATA_TEAM_REQURED: {
        enter({ setState }) {
          this.activeComponent = {
            name: 'personal-data-team-required',
            props: {
              error: this.error,
            },
          };
          this.footerBtn = {
            text: this.$t('global.next'),
            action: () => {
              setState('CLOSED');
            },
          };
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      REQUIREMENTS_ERROR: {
        enter({ setState }) {
          if (this.requirementsError?.code === 'mrg_premium') {
            this.activeComponent = {
              name: 'premium-banner',
              props: {
                needPremium: true,
              },
            };
          } else {
            this.infobox = [this.requirementsError?.text];
          }
          this.footerBtn = {
            text: this.$t('global.close'),
            action: () => setState('CLOSED'),
          };
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      TERMS_CHECK: {
        enter({ setState }) {
          const { terms } = this?.settings || {};

          this.modalTitle = this.$t('terms.agreements_user_few');
          this.infobox = [this.$t('registration.errors_terms')];

          this.activeComponent = {
            name: 'terms-accept-form',
            props: {
              terms,
              tournamentId: this.tournamentId,
            },
            methods: {
              accepted: () => setState(this.nextStep()),
            },
          };
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      FCS_CHECK: {
        enter({ setState }) {
          const termsOnly =
            this.tournament?.needFksTerms &&
            !this.tournament.needFksAccount;
          this.modalTitle = termsOnly
            ? this.$t('terms.agreements_user_few')
            : this.$t('registration.fcs_title');

          if (termsOnly) {
            this.infobox = [this.$t('registration.errors_terms')];
          } else if (this.tournament?.needFksAccount) {
            this.infobox = [this.$t('registration.errors_fcs')];
          }

          this.activeComponent = {
            name: 'fcs-account-linker',
            props: {
              forceAllowEdit: true,
              tournamentId: this.tournament?.id,
              isFksPdCompleted: this.isFksPdCompleted,
            },
            methods: {
              done: () => setState(this.nextStep()),
            },
          };
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      GAME_ACCOUNT_CHECK: {
        enter({ setState }) {
          if (this.gameRequirements.needAccount) {
            return setState('GAME_ACCOUNT_LINK');
          }

          if (this.gameRequirements.needCheck) {
            this.showGameWarnings();

            if (this.needHsCheck) {
              this.activeComponent = {
                name: 'hearthstone-pick',
                methods: {
                  done: (images) => {
                    this.hsDeckImages = images || [];
                    this.skipState();
                    this.fsmTransition('enter');
                  },
                },
              };
            } else {
              this.activeComponent = {
                name: 'game-account-linker',
                cls: 'center',
                props: { id: this.tournament?.idGame },
                methods: {
                  linked: () => {
                    this.skipState();
                    this.fsmTransition('enter');
                  },
                  unlinked: () => {
                    this.skipState();
                    setState('GAME_ACCOUNT_LINK');
                  },
                },
              };
            }
            return true;
          }

          return setState(this.nextStep());
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      GAME_ACCOUNT_LINK: {
        enter({ setState }) {
          this.modalTitle = this.$t(
            'profile.binding_games_need_account',
          );

          this.showGameAccountLinkWarnings();

          this.activeComponent = {
            name: 'game-account-linker',
            cls: 'center',
            props: { id: this.tournament?.idGame },
            methods: {
              linked: () => {
                this.skipState();
                setState('GAME_ACCOUNT_CHECK');

                // ======================================================
                // METRICS Пользователь привязал игру
                const tmrGameConnectParams = {
                  category: 'pvp',
                  action: 'game_connect',
                  label: this.getGame(this.tournament.idGame).name,
                  url: window.location.href,
                };
                pushEvents('pvp_game_connect', tmrGameConnectParams);
                // ======================================================
              },
            },
          };
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      PROFILE_FORM: {
        enter({ setState }) {
          this.infobox = [this.$t('registration.errors_info')];
          this.activeComponent = {
            name: 'personal-form',
            props: {
              forceAllowEdit: true,
              fields: this.requiredFields.profile,
            },
            methods: {
              done: () => setState(this.nextStep()),
            },
          };
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      CONTACTS_FORM: {
        enter({ setState }) {
          this.infobox = [this.$t('registration.contacts')];
          this.activeComponent = {
            name: 'profile-edit-contacts',
            props: {
              forceAllowEdit: true,
              isShowAllFields: false,
              fields: this.requiredFields.contacts,
            },
            methods: {
              done: () => setState(this.nextStep()),
            },
          };
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      TEAM_CHECK: {
        enter({ setState }) {
          const { hash, size } = this.team || {};
          const spares = spareCheck(
            this.tournament.maxReservePlayersCount,
          );
          const spareMessage = spares.isSet ? spares.message : '';

          if (hash) {
            this.footerBtn = {
              text: this.$t('global.next'),
              action: () => setState(this.nextStep()),
            };
          } else if (this.teamsList?.length > 1) {
            return this.fsmTransition('select');
          }

          this.modalTitle = this.$t(
            hash ? 'teams.management' : 'teams.create_title',
          );

          if (hash) {
            const message = this.$t('registration.teamNum_text', {
              players: this.$tc('registration.teamNum_players', size),
            });

            this.infobox = [`${message} ${spareMessage}`];
          } else {
            this.infobox = [this.$t('registration.team')];
          }

          this.activeComponent = {
            name: 'new-team-form',
            props: {
              hash,
              defaultGameId: this.game?.id,
              defaultSize: this.settings?.teamSize || null,
            },
            methods: {
              success: () => {
                this.fsmTransition('enter');
              },
            },
          };

          return true;
        },

        select({ setState }) {
          this.modalTitle = this.$t('teams.select');

          this.activeComponent = {
            name: 'team-select',
            props: {
              list: this.teamsList,
            },
            methods: {
              select: (hash) => {
                this.team = this.getTeam(hash);
                this.footerBtn = {
                  text: this.team?.hash
                    ? this.$t('global.next')
                    : null,
                  action: () => {
                    if (this.needTeamCheck) {
                      this.fsmTransition('enter');
                    } else {
                      setState(this.nextStep());
                    }
                  },
                };
              },
            },
          };
        },

        close: ({ setState }) => setState('CLOSED'),
      },

      FINISH: {
        enter({ setState }) {
          this.modalTitle = this.$t('registration.registering');
          this.activeComponent = {
            name: 'registration-finish',
            props: {
              tournamentId: this.tournamentId,
              teamHash: this.team?.hash,
            },
            methods: {
              success: () => {
                this.modalTitle = this.$t('registration.registered');
                this.footerBtn = {
                  text: this.$t('global.done'),
                  action: () => setState('CLOSED'),
                };

                // ======================================================
                // METRICS Турниры. Пользователь зарегистрировался на турнир
                const tmrRegistartionParams = {
                  label: `${
                    this.getGame(this.tournament.idGame).name
                  }_${
                    this.getTournamentCard(this.tournament.id).format
                  }`,
                  url: window.location.href,
                  category: 'pvp',
                  action: 'registration',
                };
                pushEvents(
                  'pvp_user_registration',
                  tmrRegistartionParams,
                );
                // ======================================================

                if (this.needHsFetch) {
                  this.fetchHSpickban();
                }
              },

              error: (error) => {
                this.modalTitle = this.$t('registration.title');
                if (error?.code === 'fks_personal_data_required') {
                  this.footerBtn = {
                    text: this.$t('global.fill'),
                    action: () => {
                      this.openPdPopup();
                      setState('CLOSED');
                    },
                  };
                } else {
                  this.footerBtn = {
                    text: this.$t('global.close'),
                    action: () => setState('CLOSED'),
                  };
                }
              },

              close: () => setState('CLOSED'),
            },
          };
        },

        close: ({ setState }) => setState('CLOSED'),
      },
    },
  },

  computed: {
    ...mapState('tournamentRegistration', [
      'settings',
      'tournamentId',
      'modalIsOpened',
      'hearthstonePick',
      'requirementsError',
    ]),
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('users', ['getUserTeams']),
    ...mapGetters('application', ['getWfShard', 'getGame']),
    ...mapGetters('tournaments', [
      'getTournament',
      'getTournamentCard',
    ]),
    ...mapGetters('tournamentRegistration', ['isNeedFksPDFields']),
    ...mapGetters('profile', [
      'getLinkedGameAccount',
      'getCurrentProfileDetails',
      'getCurrentProfile',
    ]),

    isFksPdCompleted() {
      return this.getCurrentProfileDetails?.fcs?.isFksPdCompleted;
    },

    isFcsRequired() {
      const { fcs } = this.getCurrentProfileDetails;
      return this.settings?.fcsRequired && !fcs?.isAccountLinked;
    },

    gameRequirements() {
      const game = this.getLinkedGameAccount(this.settings?.gameId);
      const needAccount = !this.getLinkedGameAccount(
        this.settings?.gameId,
      )?.hasAccount;
      const warnings = {};
      const needCheck = (() => {
        if (needAccount) {
          return true;
        }

        switch (game.code) {
          case 'wf': {
            const { profile, shardId } = this.settings?.warface || {};
            const tournamentShard = this.getWfShard(shardId)?.label;
            const profileShard = this.getWfShard(
              profile?.shardId,
            )?.label;

            if (profileShard && profileShard !== tournamentShard) {
              warnings.invalidShard = true;
            }

            warnings.invalidRank = profile?.isValidRank === false;

            return warnings.invalidShard || warnings.invalidRank;
          }
          case 'hs': {
            return this.needHsCheck;
          }
          default:
            return false;
        }
      })();

      return { warnings, needCheck, needAccount };
    },

    requiredFields() {
      const requiredFields = (
        this.settings?.requiredProfileFields || []
      ).filter((field) => {
        const hasMessangers = ['discord'].includes(field);
        return hasMessangers
          ? !this.profile?.messengers?.[field]
          : !this.profile?.[field];
      });

      const profileFields = [
        'gender',
        'firstName',
        'lastName',
        'middleName',
        'idCountry',
        'idRegion',
        'city',
        'birthdate',
      ];
      const contactsFields = [
        'mobile',
        'discord',
        'website',
        'extraNotes',
      ];

      return {
        contacts: requiredFields.filter((field) =>
          contactsFields.includes(field),
        ),
        profile: requiredFields.filter((field) =>
          profileFields.includes(field),
        ),
      };
    },

    teamsList() {
      const profile = this.getCurrentProfile;
      const teams = this.getUserTeams(profile?.hash);
      const { gameId, teamSize } = this.settings || {};
      const spareLimit = this.tournament?.maxReservePlayersCount;
      const checkForSpareCondition = (count) =>
        spareLimit !== null ? count <= spareLimit : true;

      return (
        teams?.filter(
          ({ idGame, numMembers, role, spareCount }) =>
            idGame === gameId &&
            numMembers === teamSize &&
            role === 'captain' &&
            checkForSpareCondition(spareCount),
        ) || []
      );
    },

    needTeamCheck() {
      const teamNotFull = (() => {
        if (!this.team?.status) {
          return true;
        }
        return !(
          this.team?.status === 'formed' ||
          this.team?.status?.id === 'formed'
        );
      })();

      if (
        (this.isSoloTournament &&
          !this.team &&
          this.teamsList.length < 2) ||
        (this.isSoloTournament &&
          this.maxReservePlayersCount === 0 &&
          this.team?.spareCount === 1)
      ) {
        return false;
      }

      return (
        this.settings?.teamSize >= 1 && (!this.team || teamNotFull)
      );
    },

    tournament() {
      return this.getTournament(this.tournamentId);
    },

    numTeamMembers() {
      return this.tournament?.numTeamMembers;
    },

    maxReservePlayersCount() {
      return this.tournament?.maxReservePlayersCount;
    },

    isSoloTournament() {
      return this.numTeamMembers === 1;
    },

    isTeamTournament() {
      return this.numTeamMembers > 1;
    },

    game() {
      return this.getGame(this.tournament.idGame);
    },

    needHsCheck() {
      if (this.game.code !== 'hs') {
        return false;
      }

      return (
        this.settings?.hearthstone?.hsLoadDeck &&
        this.hearthstonePick?.length === 0
      );
    },
    needHsFetch() {
      if (this.game.code !== 'hs') {
        return false;
      }
      return this.hearthstonePick.length || this.hsDeckImages.length;
    },
  },

  watch: {
    teamsList: {
      handler(list) {
        if (list?.length === 1) {
          this.team = list?.[0];
        }
      },
      immediate: true,
    },

    modalIsOpened: {
      handler(isOpen) {
        if (isOpen) {
          this.fsmTransition('register');
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('tournamentRegistration', [
      'fetchTournamentRegister',
      'setModalState',
    ]),

    showGameAccountLinkWarnings() {
      switch (this.game?.linkType) {
        case 'steam': {
          const link = {
            text: this.$t('registration.binding_steam_link'),
            href: this.game.instructions.find(
              (inst) => inst.code === 'steam_public',
            )?.url,
          };
          this.infobox.push(
            this.$t('registration.errors_game_account', {
              acc: '<b>Steam</b>',
              game: `<b>${this.game.name}</b>`,
            }),
            this.$t('registration.binding_management'),
            this.$t('registration.binding_steam_text', {
              acc: '<b>Steam</b>',
              pvp: '<b>VKPlay</b>',
              link: `<a target="_blank" href="${link.href}">${link.text}</a>`,
            }),
          );
          break;
        }
        case 'battlenet':
          this.infobox.push(
            this.$t('registration.errors_game_account', {
              acc: '<b>Battle.net</b>',
              game: `<b>${this.game.name}</b>`,
            }),
          );
          break;
        case 'link':
          this.infobox.push(
            this.$t('registration.errors_game_auto', {
              game: `<b>${this.game.name}</b>`,
            }),
          );
          break;
        default:
          this.infobox.push(
            this.$t('registration.errors_game_name', {
              game: `<b>${this.game.name}</b>`,
            }),
          );
      }
    },

    showGameWarnings() {
      const { profile, shardId, wfMinRank } =
        this.settings?.warface || {};
      const tournamentShard = this.getWfShard(shardId)?.label;
      const profileShard = this.getWfShard(profile?.shardId)?.label;
      const { warnings } = this.gameRequirements || {};

      if (warnings?.invalidShard) {
        this.infobox.push(
          this.$t('registration.errors_wfShard', {
            neededShard: `<b>${tournamentShard}</b>`,
            userShard: `<b>${profileShard}</b>`,
          }),
        );
      }

      if (warnings?.invalidRank) {
        this.infobox.push(
          this.$t('registration.errors_wfRank', {
            rank: wfMinRank,
          }),
        );
      }
    },

    skipState() {
      this.infobox = [];
      this.isLoading = false;
      this.activeComponent = {};
      this.footerBtn = {};
      this.modalTitle = this.$t('registration.title');
    },

    skipAll() {
      this.currentStepIndex = 0;
      this.skipState();
    },

    nextStep() {
      this.skipState();
      this.currentStepIndex += 1;
      return this.statesList[this.currentStepIndex];
    },

    fetchHSpickban() {
      const uploadPick = () =>
        api
          .post(`/tournament/${this.tournamentId}/deck`, {
            pick: this.hearthstonePick,
          })
          .catch(() => false);

      const { pickBanSystem } = this.settings?.hearthstone || {};

      if (pickBanSystem) {
        uploadPick();
      }
    },

    setError(error = null) {
      this.error = error;
    },

    async openPdPopup() {
      // TODO вынести в экшн стора
      this.isLoading = true;
      try {
        const newWindow = window.open();
        const { url } = await api.get('/profile/resf/pd-link');
        newWindow.location = url;
      } catch (err) {
        this.setError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.state {
  margin-top: 12px;

  &.center {
    text-align: center;
  }

  &.personal-form ::v-deep {
    .form-controls {
      text-align: center;
    }
  }
}

.steps {
  padding-bottom: 15px;
}

.modal-body {
  padding-left: 30px;
  padding-right: 30px;
}
</style>
